<template>
  <div id="empty-objects-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <p>
      {{ t('description') }}
    </p>
    <TheButton
      is-active
      @click="$router.push({ name: 'create-object-request' })"
    >
      {{ t('fill-out-an-application-button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import locales from './locales.json';
import { useI18n } from 'vue-i18n';
import TheButton from '@/components/atoms/TheButton/index.vue';

const { t } = useI18n({
  useScope: 'global',
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheEmptyObject',
};
</script>

<style scoped lang="scss">
#empty-objects-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 90px);

  & > * {
    width: 365px;
    text-align: center;
  }

  h2 {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 10px;
    color: #161e57;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #161e57;
    margin-bottom: 25px;
  }
}
</style>
