<template>
  <component
    :is="showComponent"
    v-bind="{
      ...(isImage && { image: isImage }),
      ...(isModel && { model: isModel }),
      ...(!isImage && { text: tabData.text }),
      ...(isElements && { elements: tabData.elements })
    }"
  />
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import TheElementsViewer from '@templates/TheElementsViewer/index.vue';
import TheEmptyPlan from '@templates/TheEmptyPlan/index.vue';
import { IElementObject, ITabObject } from '@/models/Object';
import { FileTypeEnum } from '@/enums/FileTypeEnum';
import The3DViewer from '@templates/The3DViewer/index.vue';

const props = defineProps<{
  tabData: ITabObject
}>();

const isImage = computed<false | string>(() =>
  props.tabData?.file?.type == FileTypeEnum.IMAGE && props.tabData?.file?.url || false
);
const isModel = computed<false | string>(() =>
  props.tabData?.file?.type == FileTypeEnum.MODEL && props.tabData?.file?.url || false
);
const isElements = computed<false | IElementObject[]>(
  () => props.tabData?.elements || false
);

const showComponent = computed(() => {
  if (isImage.value) {
    return TheElementsViewer;
  }
  if (isModel.value) {
    return The3DViewer;
  }
  return TheEmptyPlan;
});
</script>

<script lang="ts">
export default {
  name: 'TheFurnitureTab',
};
</script>

<style lang="scss" scoped>

</style>
