import {
    INotificationService,
    INotification
} from '@/services/NotificationsService.d';

import store from '@/store';

export default class NotificationsService implements INotificationService {
    /**
     * Method by add notification
     * @param data
     */
    add(data: INotification):void {
        const notifications = Object.values<INotification[]>(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            store.state.notifications.notifications
        );

        if (!notifications.length) data.id = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        else data.id = (notifications[0].id + 1);

        // If error notification, then off autoClose
        if (data.type === 'error') data.autoClose = false;

        // Default auto close notification
        if (data.autoClose === undefined) data.autoClose = true;

        // Show notification
        store.commit('notifications/addNotifications', data);
    }

    /**
     * Method by remove notification
     * @param id
     */
    remove(id: number):void {
        store.commit('notifications/deleteNotification', id);
    }

    /**
     * Method by showed notification if in progress development
     */
    notWorked():void {
        this.add({
            title: 'Hey!',
            type: 'info',
            text: 'This feature is not working right now. ' +
              'But it is in development and will be available soon!'
        });
    }
}
