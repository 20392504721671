<template>
  <div
    class="input-wrapper"
    :class="{
      'has-error': isOutFocus && (!!errorMessage || isErrors || customErrors),
    }"
  >
    <select
      :id="name"
      :name="name"
      :value="inputValue"
      @blur="handleBlur"
    />

    <label :for="name" :class="[!!inputValue && 'has-value']">
      {{ label }}{{ required ? '*' : '' }}
    </label>

    <label v-if="inputValue" :class="'selected-value'">
      {{ newValue }}
    </label>

    <template v-if="!hideMessage && inputValue">
      <p
        v-if="
          ((!isRequiredMessage && errorMessage) || customErrors) && isOutFocus
        "
        class="help-message"
      >
        {{ customErrors || (errorMessage && t(`${errorMessage}`)) || '' }}
      </p>

      <p v-else-if="hint && !isOutFocus" class="help-message">
        {{ hint }}
      </p>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  onMounted,
  ref,
  toRef,
  defineEmits,
  watch,
  nextTick,
} from 'vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  label: string;
  name: string;
  locales: object;
  successMessage?: string;
  placeholder?: string;
  type?: string;
  defaultValue?: string;
  newValue?: string | number;
  hideMessage?: boolean;
  isErrors?: boolean;
  required?: boolean;
  hint?: string;
  mask?: string;
  customErrors?: string;
  isLikeSelect?: boolean;
}>();

const emits = defineEmits(['input', 'getIsFocus']);
const isOutFocus = ref<boolean>(true);

type locales = typeof props.locales;

const { t } = useI18n<locales>({
  useScope: 'local',
  allowComposition: true,
  messages: props.locales,
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialValue: props.value,
});

const isRequiredMessage = computed(() => {
  if (!errorMessage.value) return false;
  return errorMessage.value?.indexOf('required') > -1;
});

const isShowPassword = ref<boolean>(false);
const isShowMessage = ref<boolean>(false);

function checkRequired(name: string | undefined) {
  return (name && name.indexOf('required') > -1) || true;
}

onMounted(() => {
  if (props.defaultValue) handleChange(props.defaultValue);
  nextTick(() => {
    window.addEventListener('click', (event) => {
      const target = event.target as HTMLInputElement;
      isShowMessage.value = target?.name === `${props.name}`;
      isOutFocus.value = target?.name !== `${props.name}`;
      emits('getIsFocus', target?.name === `${props.name}`);
    });
  });
});

watch(
  () => props.newValue,
  (newValue) => {
    if (newValue != undefined) {
      handleChange(newValue);
    }
  }
);
</script>

<script lang="ts">
export default {
  name: 'TheSelect',
};
</script>

<style src="./styles.scss" lang="scss" scoped></style>
