<template>
  <label :class="[model === value && 'active']">
    <span>
      {{ title }}
    </span>
    <input v-model="model" type="radio" name="radio" :value="value" />
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  value: string;
  select: string;
  title: string;
}>();
const emits = defineEmits(['update:select']);
const model = computed<string>({
  get: () => props.select,
  set: (newValue) => emits('update:select', newValue),
});
</script>

<script lang="ts">
export default {
  name: 'TheRadioButton',
};
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
  padding: 11px 16px;
  border-radius: 3px;
  width: 100%;
  background: #f9f9f9;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.active {
    background: #f1f1f1;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
  }

  input[type='radio'] {
    margin: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: black;
    position: relative;
    cursor: pointer;

    &:checked::before {
      content: '';
      width: 16px;
      height: 16px;
      background: #161e57;
      min-height: 16px;
      min-width: 16px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
    }

    &:checked::after {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      background: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}
</style>
