<template>
  <div v-if="!isSuccessForm" class="change-password-form-wrapper">
    <h3>
      {{ t('title') }}
    </h3>
    <Form v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit">
      <TheInput
        class="mb-[51px]"
        type="password"
        :label="t('form.fields.currentPassword.label')"
        :locales="locales"
        name="currentPassword"
        :custom-errors="invalidCurrentPassword ? t(invalidCurrentPassword) : ''"
        @input="invalidCurrentPassword = ''"
      />
      <TheInput
        class="mb-[51px]"
        type="password"
        :hint="t('form.fields.newPassword.isValidPass')"
        :label="t('form.fields.newPassword.label')"
        :locales="locales"
        name="newPassword"
      />
      <TheInput
        class="mb-[58px]"
        type="password"
        :label="t('form.fields.repeatNewPassword.label')"
        :locales="locales"
        name="repeatNewPassword"
      />
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
      >
        {{ t('form.submit-button') }}
      </TheButton>
    </Form>
  </div>
  <div v-else class="success-change-password-wrapper">
    <h3 v-html="t('success.title')" />
    <TheButton is-active @click="$router.push({ name: 'profile-edit' })">
      {{ t('success.submit') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import locales from '@templates/TheChangePasswordForm/locales.json';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { ref } from 'vue';
import { Form } from 'vee-validate';
import TheInput from '@molecules/TheInput/index.vue';
import * as Yup from 'yup';
import UserService from '@/services/UserService';
import { IUpdatePasswordRequest } from '@/repositories/UserRepository.d';

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const userService = new UserService();

const isSuccessForm = ref<boolean>(false);
const isLoadingForm = ref<boolean>(false);
const invalidCurrentPassword = ref<string>('');

const schema = Yup.object().shape({
  currentPassword: Yup.string().required(
    'form.fields.currentPassword.required'
  ),
  newPassword: Yup.string()
    .min(6, 'form.fields.newPassword.min')
    .test('isValidPass', 'form.fields.newPassword.isValidPass', (value) => {
      const hasUpperCase = /[A-Z]/.test(value || '');
      const hasLowerCase = /[a-z]/.test(value || '');
      const hasNumber = /[0-9]/.test(value || '');
      // const hasSymbole = /[!@#%&]/.test(value);
      return hasUpperCase && hasLowerCase && hasNumber;
    }),
  repeatNewPassword: Yup.string()
    .required('fields.repeatNewPassword.required')
    .oneOf(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [Yup.ref('newPassword'), null],
      'form.fields.repeatNewPassword.repeat'
    ),
});

async function onSubmit(values: IUpdatePasswordRequest) {
  try {
    invalidCurrentPassword.value = '';
    isLoadingForm.value = true;
    await userService.editPassword({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    });
    isSuccessForm.value = true;
  } catch (e: any) {
    if (e.response.data.message === 'Invalid password') {
      invalidCurrentPassword.value = 'form.fields.currentPassword.invalid';
    }
  } finally {
    isLoadingForm.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheChangePasswordForm',
};
</script>

<style lang="scss">
.change-password-form-wrapper,
.success-change-password-wrapper {
  width: 343px;

  h3 {
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    font-family: 'Circe Th', sans-serif;
    color: #161e57;
    margin-bottom: 40px;
  }

  .input-wrapper {
    min-height: 60px;
    
    .help-message {
      height: 0;
    }
  }
}
</style>
