<template>
  <div id="sign-in-form-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <Form
      v-slot="{ errors }"
      :validation-schema="schema"
      class="sign-in-form"
      @submit="onSubmit"
    >
      <TheInput
        :label="t('form.fields.email.label')"
        name="email"
        placeholder="example@email.ru"
        :locales="locales"
        :is-errors="!!serverErrorMessage"
      />
      <TheInput
        :label="t('form.fields.password.label')"
        name="password"
        type="password"
        :locales="locales"
        :custom-errors="serverErrorMessage ? t(serverErrorMessage) : undefined"
      />
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
        @click="isShowFormErrors = true"
      >
        {{ t('form.submit-button') }}
      </TheButton>
      <p
        v-if="isShowFormErrors && Object.keys(errors).length"
        class="form-errors"
      >
        {{ t('form.error') }}
      </p>
    </Form>
    <TheModal v-model:show="userNotConfirmed">
      <template #default="{ close }">
        <TheRetryRegisterMail
          @close="close"
          @sendMail="resendConfirmation"
        />
      </template>
    </TheModal>
  </div>
</template>

<script lang="ts" setup>
import { ISignInRequest } from '@/repositories/AuthRepository.d';
import { Form } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';
import TheInput from '@/components/molecules/TheInput/index.vue';
import locales from '@/components/templates/TheSignInForm/locales.json';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { ref, defineEmits } from 'vue';
import AuthService from '@/services/AuthService';
import NotificationsService from '@/services/NotificationsService';
import { isEmail } from '@/config/regex';
import TheModal from "@/components/organisms/TheModal/index.vue";
import TheRetryRegisterMail from "@templates/TheRetryRegisterMail/index.vue";
import {getHash} from "@/encrypt";

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});

const emits = defineEmits(['submit']);
const notificationsService = new NotificationsService();
const isShowFormErrors = ref<boolean>(false);
const schema = Yup.object().shape({
  email: Yup.string()
    .required('form.fields.email.required')
    .matches(isEmail, 'form.fields.email.email'),
  password: Yup.string().required('form.fields.password.required'),
});

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;
const isLoadingForm = ref<boolean>(false);
const serverErrorMessage = ref<string>('');
const authService = new AuthService();
let email = ref('');
let userNotConfirmed = ref<boolean>(false);

async function onSubmit(values: ISignInRequest) {
  try {
    serverErrorMessage.value = '';
    isLoadingForm.value = true;
    await authService.signIn(values);
    await emits('submit', values);
  } catch (e: any) {
    if (
      e.response.status === 422 &&
      e.response.data.message === 'User is banned'
    ) {
      serverErrorMessage.value = t('errors.409_banned', {
        email: supportEmail,
      });
    } else if (e.response.status === 400) {
      serverErrorMessage.value = 'errors.400';
      userNotConfirmed.value = true;
      email.value = values.email;
    }  else if (e.response.status === 409) {
      serverErrorMessage.value = 'errors.409_not_exist';
    } else {
      serverErrorMessage.value = 'errors.other';
    }
  } finally {
    isLoadingForm.value = false;
  }
}

async function resendConfirmation() {
  await authService.resendConfirmEmail(email.value);
}
</script>

<script lang="ts">
export default {
  name: 'TheSignInForm',
};
</script>

<style scoped lang="scss">
h2 {
  font-weight: 400;
  font-size: 40px;
  line-height: 51px;
  color: #161e57;
  margin-bottom: 32px;
  font-family: 'Circe Th', sans-serif;
}

.sign-in-form {
  & .input-wrapper {
    margin-bottom: 34px;
  }

  .form-errors {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ea5058;
    margin-top: 8px;
  }

  button {
    margin-top: 50px;
  }
}

.server-errors {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5058;
  padding: 0 16px;
  margin-bottom: 10px;
}
</style>
