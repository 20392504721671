<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4436 3.42888L15.9739 2.89855L14.9132 1.83789L14.3829 2.36822L12.2616 4.48954L12.1465 4.60467L10.6316 3.08982L8.5663 5.15513L9.19727 5.7861L8.54933 6.43404C6.88188 8.10149 6.63836 10.6537 7.81876 12.5795L4.72047 15.703C3.46784 16.9658 3.47196 19.0037 4.7297 20.2615C5.99105 21.5228 8.0361 21.5228 9.29745 20.2615L9.5439 20.015C10.3556 19.2033 11.6716 19.2034 12.4832 20.015L13.4832 21.015L14.5439 19.9544L13.5439 18.9544C12.1464 17.5569 9.88071 17.5569 8.48324 18.9544L8.23679 19.2008C7.56123 19.8764 6.46592 19.8764 5.79036 19.2008C5.11673 18.5272 5.11452 17.4357 5.78542 16.7594L8.78984 13.7305C10.7538 15.4546 13.746 15.3795 15.6204 13.5051L16.2683 12.8572L16.8991 13.4879L18.9644 11.4226L17.4497 9.90788L17.5647 9.79288L19.686 7.67156L20.2163 7.14123L19.1557 6.08057L18.6253 6.6109L16.504 8.73222L16.389 8.84722L13.2071 5.66533L13.3222 5.5502L15.4436 3.42888Z"
    />
  </svg>
</template>
