<template>
  <nav class="header-list-menu-wrapper">
    <router-link
      v-for="link in links"
      :key="link.name"
      :to="{ name: link.name }"
    >
      {{ t(link.title) }}
    </router-link>
  </nav>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
    useScope: 'global',
    allowComposition: true,
});

const links = ref<{ name: string, title: string }[]>([
    {
        name: 'profile-edit',
        title: 'pages.cabinet.cabinet.personal-area'
    },
    {
        name: 'Support',
        title: 'pages.support.support',
    },
    {
        name: 'about-service',
        title: 'pages.about-service.about-service',
    }
]);
</script>

<script lang="ts">
export default {
    name: 'TheHeaderListMenu'
};
</script>

<style scoped lang="scss">
.header-list-menu-wrapper {
  margin-left: 50px;

  display: flex;
  align-items: center;

  & > a:not(:last-child) {
    margin-right: 44px;
  }

  a {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161E57;
    position: relative;

    &.router-link-active {
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background: #14F046;

        position: absolute;
        bottom: -4px;
        left: 0;
      }
    }
  }
}
</style>
