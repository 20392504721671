export type ThemeT = {
  title: string
  value: string
}

export const themes:ThemeT[] = [
    {
        title: 'Неверно указаны характеристики объектов',
        value: 'invalid_characteristics'
    },
    {
        title: 'Дополнить состав вкладок еще одним видом/планом',
        value: 'add_another_plan'
    },
    {
        title: 'Не соблюдена геометрия объекта',
        value: 'invalid_object_geometry'
    },
    {
        title: 'Блокировка аккаунта',
        value: 'account_blocking'
    },
    {
        title: 'Ошибка в работе сайта',
        value: 'site_error'
    },
    {
        title: 'Другая тема',
        value: 'other'
    }
];
