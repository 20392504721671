<template>
  <div id="sign-in-page-wrapper">
    <TheSignInForm @submit="submitSignInForm" />
    <router-link :to="{ name: 'password-recovery' }" class="mt-[80px]">
      {{ t('forgot-your-password') }}
    </router-link>
    <p class="mt-[24px]">
      {{ t('dont-have-an-account') }}
      <router-link :to="{ name: 'sign-up' }">
        {{ t('register') }}
      </router-link>
    </p>
  </div>
</template>

<script lang="ts" setup>
import TheSignInForm from '@templates/TheSignInForm/index.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n({
  messages: {
    en: {
      'dont-have-an-account': "Don't have an account?",
      register: 'Sign Up',
      'forgot-your-password': 'Forgot your password?',
    },
    ru: {
      'dont-have-an-account': 'У вас нет аккаунта?',
      register: 'Зарегистрируйтесь',
      'forgot-your-password': 'Забыли пароль?',
    },
    es: {
      'dont-have-an-account': '¿No tiene cuenta?',
      register: 'Regístrese en',
      'forgot-your-password': '¿Ha olvidado su contraseña?',
    },
  },
});

function submitSignInForm() {
  router.push({ name: 'objects' });
}
</script>

<script lang="ts">
export default {
  name: 'TheSignInPage',
};
</script>

<style scoped lang="scss">
#sign-in-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
  }

  a {
    cursor: pointer;
    color: #8091dc;
  }

  #sign-in-form-wrapper {
    width: 342px;
  }
}
</style>
