import objects from '@/pages/cabinet/objects/index.vue';
import object from '@/pages/cabinet/objects/object.vue';
import profile from '@/pages/cabinet/profile/index.vue';
import profileChildren from './profile/routes';
import Logout from "@/pages/auth/logout/index.vue";

export default [
  {
    name: 'profile',
    path: 'profile',
    meta: {
      title: 'pages.cabinet.cabinet.profile',
    },
    component: profile,
    children: profileChildren,
    redirect: { name: 'profile-edit' },
  },
  {
    name: 'objects',
    path: 'objects',
    meta: {
      title: 'pages.cabinet.objects.objects',
    },
    component: objects,
  },
  {
    name: 'object',
    path: 'objects/:id',
    meta: {
      title: 'pages.cabinet.objects.object',
    },
    component: object,
  },
  {
    name: 'logout',
    path: 'logout',
    component: Logout,
  },
];
