import {
    ICreateObjectModel, ISupportModel,
    IUpdateObjectModel
} from '@/repositories/RequestsRepository.d';
import { IRequestsService } from '@/services/RequestsService.d';
import { IRequestsRepository } from '@/repositories/RequestsRepository.d';
import RequestsRepository from '@/repositories/RequestsRepository';
import { INotificationService } from '@/services/NotificationsService.d';
import NotificationsService from '@/services/NotificationsService';

export default class RequestsService implements IRequestsService {
    private requestsRepository: IRequestsRepository;
    private notifications: INotificationService;

    constructor() {
        this.requestsRepository = new RequestsRepository();
        this.notifications = new NotificationsService();
    }

    async sendCreateObject(model: ICreateObjectModel): Promise<void> {
        try {
            await this.requestsRepository.postCreateObject({
                type: 'create',
                ...model
            });
        } catch (e) {
            this.notifications.add({
                text: 'Мы не смогли сохранить вашу заявку. ' +
                  'Пожалуйста, попробуйте позже',
                title: 'Упс!',
                type: 'error'
            });
            throw e;
        }
    }

    async sendUpdateObject(model: IUpdateObjectModel): Promise<void> {
        try {
            await this.requestsRepository.postUpdateObject({
                type: 'update',
                ...model
            });
        } catch (e) {
            this.notifications.add({
                text: 'Мы не смогли сохранить вашу заявку. ' +
            'Пожалуйста, попробуйте позже',
                title: 'Упс!',
                type: 'error'
            });
            throw e;
        }
    }

    async sendSupport(model: ISupportModel): Promise<void> {
        try {
            await this.requestsRepository.postSupport(model);
        } catch (e) {
            this.notifications.add({
                text: 'Мы не смогли сохранить вашу заявку. ' +
            'Пожалуйста, попробуйте позже',
                title: 'Упс!',
                type: 'error'
            });
            throw e;
        }
    }
}
