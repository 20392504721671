<template>
  <div id="requests-layout-wrapper">
    <TheHeader logo-route-name="objects">
      <template #left-content>
        <TheHeaderListMenu />
      </template>
    </TheHeader>

    <perfect-scrollbar>
      <div class="wrapper-page">
        <div class="image-wrapper">
          <img
            :src="bannerLayout"
            alt=""
            class="banner"
          >
        </div>
        <div class="page">
          <div
            v-if="!isSuccessForm"
            id="support-form-wrapper"
          >
            <h1>
              {{ t('title') }}
            </h1>
            <h2>
              {{ t('fields.theme.title') }}
            </h2>
            <div class="max-w-[464px]">
              <TheRadioButton
                v-for="theme in themes"
                :key="theme.title"
                v-model:select="modelTitle"
                :title="t('fields.theme.' + theme.value)"
                :value="theme.value"
              />
              <div class="mt-[24px]" />
              <TheTextarea
                v-model:text="modelText"
                :label="t('fields.text.title')"
                :limit="limitText"
                :name="'support-text'"
              />
              <TheAttachingFiles
                v-model:files="modelFiles"
                :accepts="availableFileExtensions"
                :label="t('fields.files.title')"
                :limit="limitFilesCount"
                multiple
                @set-has-validation-errors="setHasValidationErrors"
              />
              <TheButton
                :is-active="isAccessForm"
                :loading="isLoadingForm"
                class="mt-[24px]"
                @click="onSubmit"
              >
                {{ t('submit.title') }}
              </TheButton>
            </div>
          </div>
          <div
            v-else
            id="support-form-success-wrapper"
          >
            <h1>
              {{ t('success.title') }}
            </h1>
            <TheButton
              is-active
              @click="$router.push({ name: 'objects' })"
            >
              {{ t('success.submit.title') }}
            </TheButton>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import TheRadioButton from '@/components/atoms/TheRadioButton/index.vue';
import { themes } from '@/config/support';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import locales from '@templates/TheSupportForm/locales.json';
import TheTextarea from '@/components/atoms/TheTextarea/index.vue';
import TheAttachingFiles from '@molecules/TheAttachingFiles/index.vue';
import TheButton from '@/components/atoms/TheButton/index.vue';
import RequestsService from '@/services/RequestsService';
import TheHeaderListMenu from '@molecules/TheHeaderListMenu/index.vue';
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import bannersAuth from '@/config/banners-auth';
import { useRoute } from 'vue-router';
import { availableFileExtensions } from '@/utils/consts';

const route = useRoute();
const bannerLayout = computed(() => bannersAuth.get(route.meta.banner));
const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const requestsService = new RequestsService();
const isSuccessForm = ref<boolean>(false);
const isLoadingForm = ref<boolean>(false);
const modelTitle = ref<string>('');
const modelText = ref<string>('');
const modelFiles = ref<File[]>([]);
const limitText = ref<number>(600);
const limitFilesCount = 10;
let hasValidationErrors = ref<boolean>(false);

const setHasValidationErrors = (value: boolean) => {
  hasValidationErrors.value = value;
};

const isAccessForm = computed<boolean>(
  () =>
    !!(
      modelTitle.value.length &&
      modelText.value.length &&
      modelText.value.length <= limitText.value &&
      limitFilesCount >= modelFiles.value.length &&
      !hasValidationErrors.value
    )
);

async function onSubmit() {
  if (!isAccessForm.value) return;
  try {
    isLoadingForm.value = true;
    await requestsService.sendSupport({
      title: modelTitle.value,
      text: modelText.value,
      files: modelFiles.value.map((file) => ({ file })),
    });
    isSuccessForm.value = true;
  } finally {
    isLoadingForm.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheSupportForm',
};
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />
<style lang="scss" scoped>
#support-form-wrapper {
  padding: 30px 100px;

  h1 {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 24px;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
    margin-bottom: 16px;
  }

  & > div > label:not(:last-child) {
    margin-bottom: 8px;
  }

  &::v-deep(.add-file-button) {
    max-width: 100%;
  }
}

#support-form-success-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    max-width: 342px;
  }

  h1 {
    margin-bottom: 40px;
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
  }
}

#requests-layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper-page {
  display: flex;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
  grid-template-areas: "banner page";
  height: 100%;

  #header-wrapper {
    grid-area: header;
    border-bottom: 1px solid #F1F1F1;
  }

  .banner {
    display: block;
    grid-area: banner;
    object-fit: cover;
    align-self: stretch;
    height: 100%;
  }

  .image-wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;

    img {
      object-position: 100% 0;
      max-width: 733px;
    }
  }

  .page {
    grid-area: page;
    overflow: auto;
    width: 60%;
  }
}

.ps {
  height: 100%;
}
</style>
