import { IObject } from '@/models/Object';
import router from '@/routes';

export type actionT = {
  title: string
  method(data: IObject, status?:any): void
}

const mediaUrl = import.meta.env.VITE_MEDIA_URL;

export const actions:actionT[] = [
    {
        title: 'share',
        method(object: IObject, status: any): void {
            status.value = true;
        }
    },
    {
        title: 'edit',
        method(object) {
            router.push(
                { name: 'edit-object-request', query: { object:  object.id } }
            ).then(r => r);
        }
    },
    {
        title: 'download-pdf',
        method(object: IObject) {
            const anchor = document.createElement('a');
            anchor.href = object.pdf?.url || '';
            anchor.target = '_blank';
            anchor.style.display = 'none';
            anchor.download =
              `${ object.city.name } ` +
              `${ object.street } ` +
              `${ object.buildingNumber } ` +
              `${ object.apartmentNumber }.pdf`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    },
    {
        title: 'download-3d-model',
        method(object: IObject) {
            const formatFile = object.model?.url.split('.').at(-1);
            const anchor = document.createElement('a');
            anchor.href = object.model?.url || '';
            anchor.style.display = 'none';
            anchor.download =
              `${object.city.name}` +
              `${object.street}` +
              `${object.buildingNumber}` +
              `${object.apartmentNumber} 3D Model.${formatFile}`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    }
];
