<template>
  <div class="region-selector-wrapper">
    <!-- Input by set id selected data. He always hidden -->
    <input style="display: none" :name="name" type="text" />
    <div
      class="input-wrapper"
      :class="{
        'has-error': !!errorMessage,
        success: meta.valid,
      }"
    >
      <img :src="LoopIcon" alt="" class="loop-icon" />
      <!-- Input by set text selected data. He always showed -->
      <input
        ref="inputSelectedText"
        type="text"
        :value="searchRegionText || selectedRegion.title"
        :name="`${name}-selected-text`"
        @input="updateInputText"
        autocomplete="off"
      />
      <label
        :for="`${name}-selected-text`"
        :class="[!!inputValue && 'has-value']"
      >
        {{ label }}{{ required ? '*' : '' }}
      </label>
      <!-- Messages by status Field -->
      <!--<p-->
      <!--  v-show="errorMessage || !!inputValue && meta.valid"-->
      <!--  class="help-message"-->
      <!--&gt;-->
      <!--  {{ inputMessage }}-->
      <!--</p>-->
    </div>

    <!-- List for select -->
    <div
      v-if="isShowRegions"
      class="regions"
      :style="{
        top: inputHeight + 'px',
        height: regionsHeight + 'px',
      }"
    >
      <perfect-scrollbar v-if="regions.length" tag="ul">
        <li
          v-for="region in regions"
          :key="region.city.id"
          @click="selectRegion(region)"
        >
          {{ region.name }}, {{ region.region }},
          {{ region?.city.name }}
        </li>
      </perfect-scrollbar>
      <ul v-else-if="isGetRegions && !regions.length">
        <li>
          {{ localT('not-data') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LoopIcon from '@/assets/icons/loop.svg';
import GeoRepository from '@/repositories/GeoRepository';
import { IHintByLocationResponse } from '@/repositories/GeoRepository.d';
import { debounce } from 'lodash';
import { useField } from 'vee-validate';
import {
  computed,
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
  ref,
  toRef,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const props = defineProps<{
  locales: object;
  label: string;
  name: string;
  successMessage?: string;
  defaultValue?: IHintByLocationResponse;
  required: boolean;
}>();
const emits = defineEmits(['input', 'getIsFocus', 'newPdServerCode']);

type locales = typeof props.locales;

const { t } = useI18n<locales>({
  useScope: 'global',
  allowComposition: true,
  messages: props.locales,
});

const { t: localT } = useI18n({
  useScope: 'local',
  messages: {
    ru: {
      'not-data': 'Нет результатов',
    },
    en: {
      'not-data': 'No results',
    },
    es: {
      'not-data': 'Sin resultados',
    },
  },
});

const regions = ref<IHintByLocationResponse[]>([]);
const inputSelectedText = ref<HTMLInputElement>();
const store = useStore();

const inputHeight = ref<number>(0);
const regionsHeight = ref<number>(0);
const isShowRegions = ref<boolean>(false);
const searchRegionText = ref<string>('');
const isGetRegions = ref<boolean>(false);
const selectedRegion = ref<{ id: number; title: string }>({
  id: 0,
  title: '',
});

const geoRepository = new GeoRepository();

// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  // handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialValue: props.value,
});

const inputMessage = computed(() => {
  if (errorMessage.value) return t(`${errorMessage.value}`);
  if (props.successMessage) return t(props.successMessage);
  return t('great') + '!';
});

const getHints = debounce(async () => {
  const lang = store.state.user.generalData?.lang || localStorage.getItem('lang');

  regions.value = await geoRepository.getHintsByLocation({
    request: searchRegionText.value,
    lang: lang ?? 'ru',
  });
  if (regions.value.length < 6) {
    regionsHeight.value = inputHeight.value * regions.value.length;
  } else {
    regionsHeight.value = inputHeight.value * 6;
  }

  isGetRegions.value = true;
}, 1000);

function selectRegion(region: IHintByLocationResponse | null) {
  selectedRegion.value = {
    id: region?.city?.id || 0,
    title: region ? `${region?.name}, ${region?.city?.name}` : '',
  };
  searchRegionText.value = '';
  handleChange(region?.city?.id || '');
  emits('input', region?.city?.id);
  emits('newPdServerCode', region?.pd_server_code);
}

function updateInputText(data: any) {
  if (!data.target.value) selectRegion(null);
  else {
    searchRegionText.value = data.target.value;
    getHints();
  }
}

onMounted(() => {
  // getHints();
  nextTick(() => {
    // Calculate input text data
    inputHeight.value =
      (inputSelectedText.value && inputSelectedText.value.clientHeight) || 0;

    // Toggle Regions
    window.addEventListener('click', (event) => {
      const target = event.target as HTMLInputElement;
      isShowRegions.value = target?.name === `${props.name}-selected-text`;
    });
    if (props.defaultValue) selectRegion(props.defaultValue);
  });
});
</script>

<script lang="ts">
export default {
  name: 'TheRegionSelector',
};
</script>

<style src="../../molecules/TheInput/styles.scss" lang="scss" scoped></style>

<style scoped lang="scss">
.region-selector-wrapper {
  position: relative;

  .regions {
    width: 100%;
    max-height: 250px;
    position: absolute;
    left: 0;
    background: white;
    z-index: 2;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
  }

  ul {
    width: 100%;
    height: 100%;

    & > li:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }

    li {
      padding: 13px 16px;
      cursor: pointer;
      background: white;
    }
  }

  .input-wrapper {
    position: relative;

    .loop-icon {
      width: 24px;
      height: 24px;

      position: absolute;
      top: 16px;
      left: 13px;
    }

    input {
      padding-left: 42px;
    }

    label {
      margin-left: 43px;
    }
  }
}
</style>
