import {
  ILinkVerificationRepository,
  IVerificationRequest,
} from '@/repositories/LinkVerificationRepository.d';

import axios from '@/axios';

export default class LinkVerificationRepository
  implements ILinkVerificationRepository
{
  async checkPasswordRecovery(
    queryParams: IVerificationRequest
  ): Promise<void> {
    await axios.get('/check-expiration/password-recovery', {
      params: queryParams,
    });
  }
  async checkEmailChange(queryParams: IVerificationRequest): Promise<void> {
    await axios.get('/check-expiration/email-change', {
      params: queryParams,
    });
  }
  async checkUserVerify(queryParams: IVerificationRequest): Promise<void> {
    await axios.get('/check-expiration/user-verify', {
      params: queryParams,
    });
  }
}
