<template>
  <div class="object-type-selector-wrapper">
    <div
      class="arrow-icon"
      :class="[isShowList && 'active']"
    >
      <img
        :src="BottomIcon"
        alt=""
      >
    </div>
    <TheSelect
      :label="label"
      :locales="locales"
      :name="name + '-title'"
      :new-value="t(`fields.objectType.values.${selectedObjectType.value}`)"
    />
    <p
      v-show="errorMessage || !!inputValue && meta.valid"
      class="help-message"
      :class="[
        !!inputValue && 'has-value',
        meta.valid && 'success',
        !!errorMessage && 'has-error'
      ]"
    >
      {{ inputMessage }}
    </p>
    <perfect-scrollbar
      v-if="isShowList && filteredObjectTypes.length"
      class="types"
    >
      <ul>
        <li
          v-for="type in filteredObjectTypes"
          :key="type.value"
          @click="selectType(type)"
        >
          {{ t(`fields.objectType.values.${type.value}`) }}
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, reactive, ref, toRef } from 'vue';
import { objectTypes } from '@/config/object-properties';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import BottomIcon from '@/assets/icons/bottom-arrow.svg';
import TheSelect from "@molecules/TheSelect/index.vue";

const props = defineProps<{
  locales: object
  label: string
  name: string
  successMessage?: string
}>();

type locales = typeof props.locales
const { t } = useI18n<locales>({
    messages: props.locales
});

const selectedObjectType = reactive({
    title: '',
    value: ''
});

const isShowList = ref<boolean>(false);
const inputText = ref<string>('');
const filteredObjectTypes = computed(() => {
    if (!inputText.value) return objectTypes;
    if (selectedObjectType.value) return objectTypes;
    return objectTypes.filter((type: any) => {
        return type.title.indexOf(inputText.value) > -1;
    });
});

const name = toRef(props, 'name');
const {
    value: inputValue,
    errorMessage,
    // handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    initialValue: props.value,
});

function selectType(type:{ title: string, value: string }) {
    selectedObjectType.title = type.title;
    selectedObjectType.value = type.value;
    inputText.value = '';
    handleChange(selectedObjectType.value);
}

const inputMessage = computed(() => {
    if (errorMessage.value) return t(`${ errorMessage.value }`);
    if (props.successMessage) return t(props.successMessage);
    return '';
});

onMounted(() => {
    window.addEventListener('click', event => {
        const target = event.target as HTMLInputElement;
        isShowList.value =
      target?.name === `${ props.name }-title`;
    });
});
</script>

<script lang="ts">
export default {
    name: 'TheObjectTypeSelector',
};
</script>

<style scoped lang="scss">
.object-type-selector-wrapper {
  position: relative;

  .arrow-icon {
    position: absolute;
    right: 12px;
    top: 14px;
    z-index: 1;

    img {
      transition: .2s;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .types {
    width: 100%;
    max-height: 250px;
    position: absolute;
    left: 0;
    top: 56px;
    background: white;
    z-index: 2;
    border-left: 1px solid #F1F1F1;
    border-right: 1px solid #F1F1F1;
    border-bottom: 1px solid #F1F1F1;
  }

  ul {
    width: 100%;

    & > li:not(:last-child) {
      border-bottom: 1px solid #F1F1F1;
    }

    li {
      padding: 13px 16px;
      cursor: pointer;
    }
  }

  .help-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0 16px;

    &.has-error {
      color: #EA5058;
    }

    &.success {
      color: #21a67a;
    }
  }
}
</style>
