import {
  IUserRepository,
  IUpdateProfileRequest,
  IUpdateSettingsRequest,
  IUpdatePasswordRequest,
  IUpdateLanguageRequest,
  IPrepareEditEmailRequest,
  IPrepareUpdatePdRequest, IPrepareMigrateRequest, IPrepareConfirmMigrateRequest,
} from '@/repositories/UserRepository.d';
import { IGeneralData } from '@/models/user';

import axios from '@/axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {IPreparedPdRequest} from "@/repositories/PersonalDataRepository.d";

export default class UserRepository implements IUserRepository {
  async getProfileData(): Promise<IGeneralData> {
    const response = await axios.get('/user');
    return camelcaseKeys(response.data, { deep: true });
  }

  async updateProfileData(data: IUpdateProfileRequest): Promise<IGeneralData> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    const response = await axios.patch('/user', newFormatData);

    return camelcaseKeys(response.data, { deep: true });
  }

  async updateSettings(data: IUpdateSettingsRequest): Promise<void> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    await axios.patch('/user/settings', newFormatData);
  }

  async changeEmailSendEmail(data: string): Promise<void> {
    await axios.post('/user/change-email', {
      email_hash: data,
    });
  }

  async updateLanguage(data: IUpdateLanguageRequest): Promise<void> {
    await axios.patch('/user/lang', data);
  }

  async updatePassword(data: IUpdatePasswordRequest): Promise<void> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    await axios.put('/user/password-change', newFormatData);
  }

  async prepareGetRequest(): Promise<IPreparedPdRequest> {
    const response = await axios.post('/user/pd/get');
    return camelcaseKeys(response.data, { deep: true });
  }

  async prepareUpdateRequest(data: IPrepareUpdatePdRequest): Promise<IPreparedPdRequest> {
    const response = await axios.post(`/user/pd/update`, data);
    return camelcaseKeys(response.data, { deep: true });
  }

  async prepareEditEmailRequest(data: IPrepareEditEmailRequest): Promise<IPreparedPdRequest> {
    const response = await axios.post('/user/pd/change-email', data);
    return camelcaseKeys(response.data, { deep: true });
  }

  async prepareConfirmEditPasswordRequest(data: IPrepareEditEmailRequest): Promise<IPreparedPdRequest> {
    const response = await axios.post('/user/pd/change-email/confirm', data);
    return camelcaseKeys(response.data, { deep: true });
  }

  async prepareMigrateRequest(data: IPrepareMigrateRequest): Promise<IPreparedPdRequest> {
    const response = await axios.post('/user/pd/migrate', data);
    return camelcaseKeys(response.data, { deep: true });
  }

  async confirmMigrate(data: IPrepareConfirmMigrateRequest): Promise<IPreparedPdRequest> {
    const response = await axios.post('/user/migrate-confirm', data);
    return camelcaseKeys(response.data, { deep: true });
  }
}
