<template>
  <perfect-scrollbar>
    <TheHeader logo-route-name="objects">
      <template #left-content v-if="isUserAuth">
        <TheHeaderListMenu />
      </template>
      <template #right-content v-if="isUserAuth">
        <TheButton
          is-active
          class="about-button create-object-request-button"
          @click="$router.push({ name: 'create-object-request' })"
        >
          {{ t('request') }}
        </TheButton>
      </template>
      <template #right-content v-else>
        <TheButton
          is-active
          class="about-button sign-in-button"
          @click="$router.push({ name: 'sign-in' })"
        >
          {{ t('sign-in') }}
        </TheButton>
        <TheButton
          is-active
          class="about-button sign-up-button"
          style-type="secondary"
          @click="$router.push({ name: 'sign-up' })"
        >
          {{ t('sign-up') }}
        </TheButton>
      </template>
    </TheHeader>
    <slot />
  </perfect-scrollbar>
</template>

<script setup lang="ts">
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import TheHeaderListMenu from '@molecules/TheHeaderListMenu/index.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();

const isUserAuth = computed<number | null>(() => store.state.user.generalData?.id);

const { t } = useI18n({
  messages: {
    en: {
      'sign-in': 'Login',
      'sign-up': 'Registration',
      request: 'Order a plan',
    },
    ru: {
      'sign-in': 'Вход',
      'sign-up': 'Регистрация',
      request: 'Заказать планировку',
    },
    es: {
      'sign-in': 'Inicio de sesión',
      'sign-up': 'Inscripción',
      request: 'Solicitar un plan',
    },
  },
});
</script>

<script lang="ts">
export default {
  name: 'TheCabinetLayout',
};
</script>

<style scoped lang="scss">
.about-button {
  margin-right: 38px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  color: #ffffff;
  width: 122px;

  &.create-object-request-button {
    width: 207px;
  }
  &.sign-in-button {
    margin-right: 24px;
  }
  &.sign-up-button {
    color: #161e57;
  }
}
</style>
