<template>
  <h6
    v-if="label"
    :class="{
      'has-error': isLimitError || (isOutFocus && !model.length),
    }"
  >
    {{ label }}
  </h6>
  <div
    class="textarea-wrapper"
    :class="{
      'has-error': isLimitError,
    }"
  >
    <span v-if="limit">{{ model.length }}/{{ limit }}</span>
    <textarea
      ref="textarea"
      v-model="model"
      style=" resize: none;"
      :name="name"
      @input="setHeightTextarea"
      @focusout="isOutFocus = true"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = defineProps<{
  text: string;
  limit?: number;
  label?: string;
  name?: string;
  height?: number;
}>();

const emits = defineEmits(['update:text']);

const textarea = ref<HTMLTextAreaElement | null>(null);
const isOutFocus = ref<boolean>(false);
const model = computed<string>({
  get: () => props.text,
  set: (newValue) => emits('update:text', newValue),
});
const isLimitError = computed<boolean>(() => {
  if (props.limit) {
    return model.value.length > props.limit;
  }
  return false;
});

function setHeightTextarea() {
  if (textarea.value) textarea.value.style.height = 'auto';
  if (textarea.value)
    textarea.value.style.height = textarea.value.scrollHeight + 'px';
}
</script>

<script lang="ts">
export default {
  name: 'TheTextarea',
};
</script>

<style scoped lang="scss">
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #848484;
  margin-bottom: 8px;

  &.has-error {
    color: #ea5058;
  }
}

.textarea-wrapper {
  position: relative;

  textarea {
    border: 1px solid #f1f1f1;
    width: 100%;
    min-height: 160px;
    padding: 20px 10px;
    border-radius: 3px;
    overflow: hidden;
  }

  span {
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #848484;
    margin-top: 2px;
    background-color: white;
    text-align: right;
    right: 7%;
    padding: 7px 0 2px 2px;
  }

  &.has-error {
    textarea {
      border: 1px solid #ea5058;
    }
    span {
      color: #ea5058;
    }
  }
}
</style>
