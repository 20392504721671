import { IAuthTokenService } from '@/services/AuthTokenService.d';

export default class AuthTokenService implements IAuthTokenService {
    tokenName = 'auth_token';

    /**
     * Method for get auth token
     */
    get(): string | null {
        return localStorage.getItem(this.tokenName)
          || sessionStorage.getItem(this.tokenName);
    }

    /**
     * Method for delete auth token
     */
    remove(): void {
        localStorage.removeItem(this.tokenName);
        sessionStorage.removeItem(this.tokenName);
    }

    /**
     * Method for set auth token by type remember
     * @param token
     * @param remember
     */
    set(token: string, remember?: boolean): void {
        if(remember) {
            // Set on localStorage
            localStorage.setItem(this.tokenName, token);
            return;
        }

        // Set on sessionStorage
        sessionStorage.setItem(this.tokenName, token);
    }
}
