<template>
  <div class="loader-wrapper">
    <div class="container">
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
      <div class="circle" />
    </div>
  </div>
</template>

<script>
export default {
    name: 'TheLoader'
};
</script>

<style scoped lang="scss">
$line-color: #3b629c;

.loader-wrapper {
  height: calc(100% - 91px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  display: block;
  width: 200px;
  height: 200px;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(45deg) rotateX(45deg);
}
.circle:nth-child(0) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  width: 200px;
  height: 200px;
  //-webkit-animation: spin Infinitys infinite linear;
  animation: spin Infinitys infinite linear;
}
.circle:nth-child(1) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 6.25px;
  top: 6.25px;
  width: 187.5px;
  height: 187.5px;
  -webkit-animation: spin 12s infinite linear;
  animation: spin 12s infinite linear;
}
.circle:nth-child(2) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 12.5px;
  top: 12.5px;
  width: 175px;
  height: 175px;
  -webkit-animation: spin 6s infinite linear;
  animation: spin 6s infinite linear;
}
.circle:nth-child(3) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 18.75px;
  top: 18.75px;
  width: 162.5px;
  height: 162.5px;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
}
.circle:nth-child(4) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 25px;
  top: 25px;
  width: 150px;
  height: 150px;
  -webkit-animation: spin 3s infinite linear;
  animation: spin 3s infinite linear;
}
.circle:nth-child(5) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 31.25px;
  top: 31.25px;
  width: 137.5px;
  height: 137.5px;
  -webkit-animation: spin 2.4s infinite linear;
  animation: spin 2.4s infinite linear;
}
.circle:nth-child(6) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 37.5px;
  top: 37.5px;
  width: 125px;
  height: 125px;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.circle:nth-child(7) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 43.75px;
  top: 43.75px;
  width: 112.5px;
  height: 112.5px;
  -webkit-animation: spin 1.714285714285714s infinite linear;
  animation: spin 1.714285714285714s infinite linear;
}
.circle:nth-child(8) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 50px;
  top: 50px;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}
.circle:nth-child(9) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 56.25px;
  top: 56.25px;
  width: 87.5px;
  height: 87.5px;
  -webkit-animation: spin 1.333333333333333s infinite linear;
  animation: spin 1.333333333333333s infinite linear;
}
.circle:nth-child(10) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 62.5px;
  top: 62.5px;
  width: 75px;
  height: 75px;
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear;
}
.circle:nth-child(11) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 68.75px;
  top: 68.75px;
  width: 62.5px;
  height: 62.5px;
  -webkit-animation: spin 1.090909090909091s infinite linear;
  animation: spin 1.090909090909091s infinite linear;
}
.circle:nth-child(12) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
.circle:nth-child(13) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 81.25px;
  top: 81.25px;
  width: 37.5px;
  height: 37.5px;
  -webkit-animation: spin 0.923076923076923s infinite linear;
  animation: spin 0.923076923076923s infinite linear;
}
.circle:nth-child(14) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 87.5px;
  top: 87.5px;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 0.857142857142857s infinite linear;
  animation: spin 0.857142857142857s infinite linear;
}
.circle:nth-child(15) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 93.75px;
  top: 93.75px;
  width: 12.5px;
  height: 12.5px;
  -webkit-animation: spin 0.8s infinite linear;
  animation: spin 0.8s infinite linear;
}
.circle:nth-child(16) {
  position: absolute;
  background: transparent;
  border: 2px solid $line-color;
  border-radius: 50%;
  left: 100px;
  top: 100px;
  width: 0px;
  height: 0px;
  -webkit-animation: spin 0.75s infinite linear;
  animation: spin 0.75s infinite linear;
}
.circle:nth-child(2n) {
  border: 2px dashed #87c0de;
}
.circle:last-child {
  display: none;
}
@-webkit-keyframes spin {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}


</style>
