<template>
  <perfect-scrollbar
    v-if="isShowContent"
    id="cabinet-layout-wrapper"
  >
    <TheHeader logo-route-name="objects">
      <template #left-content>
        <TheHeaderListMenu v-if="isUserAuth"/>
      </template>
    </TheHeader>
    <slot />
  </perfect-scrollbar>
</template>

<script setup lang="ts">
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import TheHeaderListMenu from '@molecules/TheHeaderListMenu/index.vue';
import { nextTick, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const isShowContent = ref<boolean>(true);
const isUserAuth = computed<number | null>(() => store.state.user.generalData?.id);

watch(
    () => route.name,
    () => {
        isShowContent.value = false;
        nextTick(() => isShowContent.value = true);
    }
);
</script>


<script lang="ts">
export default {
    name: 'TheCabinetLayout'
};
</script>

<style scoped lang="scss">
#cabinet-layout-wrapper {
  height: 100vh;
  overflow: hidden;

  #header-wrapper {
    border-bottom: 1px solid #F1F1F1;
  }
}
</style>
