<template>
  <div id="editor-personal-area-wrapper">
    <img
      class="banner"
      :src="bannerLayout"
      alt=""
    >
    <perfect-scrollbar class="wrapper-page">
      <slot />
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import bannersAuth from '@/config/banners-auth';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();
const bannerLayout = computed(() => bannersAuth.get(route.meta.banner));
</script>

<script lang="ts">
export default {
    name: 'EditorPersonalAreaLayout'
};
</script>

<style scoped lang="scss">
#editor-personal-area-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-areas: "banner page";
  height: calc(100% - 91px);

  .banner {
    display: block;
    grid-area: banner;
    object-fit: cover;
    align-self: stretch;
    overflow: hidden;
  }

  .wrapper-page {
    grid-area: page;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
