import {
    IRequestsRepository,
    ICreateObjectModel,
    IUpdateObjectModel, ISupportModel
} from '@/repositories/RequestsRepository.d';

import axios from '@/axios';
import snakecaseKeys from 'snakecase-keys';

export default class RequestsRepository implements IRequestsRepository {
    async postCreateObject(model: ICreateObjectModel): Promise<void> {
        const newFormattingData = snakecaseKeys(model, { deep: true });
        const formData = new FormData();
        Object.entries(newFormattingData).forEach(
            ([ key, value ]) => formData.append(key, String(value))
        );
        await axios.post('/objects/requests', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async postUpdateObject(model: IUpdateObjectModel): Promise<void> {
        const newFormattingData = snakecaseKeys(model, { deep: true });
        const formData = new FormData();
        Object.entries(newFormattingData).forEach(
            ([ key, value ]) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const data:any = model[key];
                if (key === 'files') {
                    const files = data
                        .map((fileData:{ file: File }) => fileData.file);
                    files.forEach(
                        (file:File) => {
                            formData.append('files[]', file);
                        }
                    );
                    return;
                }
                if (key === 'object_id') {
                    formData.append(key, String(model['objectId']));
                    return;
                }
                formData.append(key, data);
            }
        );
        await axios.post('/objects/requests', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async postSupport(model:ISupportModel): Promise<void> {
        const formData = new FormData();
        Object.entries(model).forEach(
            ([ key, value ]) => {
                if (key === 'files') {
                    const files = value
                        .map((fileData:{ file: File }) => fileData.file);
                    files.forEach(
                        (file:File) => formData.append('files[]', file)
                    );
                    return;
                }
                formData.append(key, value);
            }

        );
        await axios.post('/support', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
