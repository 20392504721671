<template>
  <div class="language-switcher-wrapper">
    <select id="locale" v-model="locale" @change="updateLanguage">
      <option v-for="l in $i18n.availableLocales" :key="l" :value="l">
        {{ l.toLocaleUpperCase() }}
      </option>
    </select>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import UserService from "@/services/UserService";
import { useI18n } from "vue-i18n";

const userService = new UserService();
const store = useStore();
const { locale } = useI18n({
  useScope: "global",
});
const lang = store.state.user.generalData?.lang || localStorage.getItem('lang');
locale.value = lang ?? "ru";

async function updateLanguage(value: Event) {
  localStorage.setItem('lang', locale.value);
  if (store.state.user.generalData) {
    await userService.editLanguage({
      lang: (value.target as HTMLInputElement).value,
    });
  }
}
</script>

<script lang="ts">
export default {
  name: "TheLanguageSwitcher",
};
</script>

<style scoped lang="scss">
.language-switcher-wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #161e57;

  select {
    background-color: inherit;
  }
}
</style>
