import bellSolid from '@/assets/icons/bell-solid.svg';
import check from '@/assets/icons/check-solid.svg';
import times from '@/assets/icons/times-solid.svg';
import exclamation from '@/assets/icons/exclamation-solid.svg';
import circleInfo from '@/assets/icons/circle-info-solid.svg';

const types = new Map();

types.set('default', {
    type: 'primary',
    class: 'primary',
    icon: bellSolid
});

types.set('primary', {
    type: 'success',
    class: 'success',
    icon: check
});

types.set('error', {
    type: 'error',
    class: 'error',
    icon: times
});

types.set('warning', {
    type: 'warning',
    class: 'warning',
    icon: exclamation
});

types.set('info', {
    type: 'info',
    class: 'info',
    icon: circleInfo
});

export default types;
