import {
  IAuthRepository,
  IEmailConfirmRequest,
  IEmailConfirmResponse,
  IEmailResetRequest,
  IRecoveryPasswordRequest,
  IResetPasswordRequest,
  IResetPasswordResponse,
  ISignInRequest,
  ISignInResponse, ISignUpConfirmRequest,
  ISignUpRequest,
} from '@/repositories/AuthRepository.d';

import axios from '@/axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { IPreparedPdRequest } from '@/repositories/PersonalDataRepository.d';

export default class AuthRepository implements IAuthRepository {
  async signIn(data: ISignInRequest): Promise<ISignInResponse> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    const response = await axios.post('/auth', newFormatData);
    return camelcaseKeys(response.data, { deep: true });
  }

  async signUp(data: ISignUpRequest): Promise<IPreparedPdRequest> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    const response = await axios.post('/auth/registration', newFormatData);
    return camelcaseKeys(response.data, { deep: true });
  }

  async signUpConfirm(data: ISignUpConfirmRequest): Promise<IPreparedPdRequest> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    const response = await axios.post('/auth/registration/confirm-create', newFormatData);
    return camelcaseKeys(response.data, { deep: true });
  }

  async emailConfirm(
    data: IEmailConfirmRequest
  ): Promise<IEmailConfirmResponse> {
    const response = await axios.post('/auth/registration/confirm', data);
    return camelcaseKeys(response.data, { deep: true });
  }

  async emailReset(data: IEmailResetRequest): Promise<void> {
    const newFormatData = await snakecaseKeys(data, { deep: true });
    return axios.post('/user/change-email/confirm', newFormatData);
  }

  async recoveryPassword(data: IRecoveryPasswordRequest): Promise<void> {
    await axios.post('/auth/password-recovery', data);
  }

  async resetPassword(
    data: IResetPasswordRequest
  ): Promise<IResetPasswordResponse> {
    const newFormatData = await snakecaseKeys(data, { deep: true });
    const response = await axios.post(
      '/auth/password-recovery/confirm',
      newFormatData
    );

    return camelcaseKeys(response.data, { deep: true });
  }

  async resendConfirm(data: string): Promise<void> {
    await axios.post('/auth/registration/retry-confirm', { email_hash: data });
  }

  async leave(): Promise<void> {
    await axios.delete('/auth');
  }
}
