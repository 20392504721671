<template>
  <button
    :type="type || 'button'"
    :class="[
      isActive && 'active',
      loading && 'loading',
      styleType && styleType,
      type || 'button',
    ]"
    class="button-wrapper"
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <span v-if="loading" class="loader" />
    <slot name="left-content" />
    <slot />
    <slot name="right-content" />
  </button>
</template>

<script lang="ts" setup>
defineProps<{
  type?: string;
  loading?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  styleType?: string;
}>();
defineEmits(['click']);
</script>

<script lang="ts">
export default {
  name: 'TheButton',
};
</script>

<style scoped lang="scss">
.button-wrapper {
  background: #7786c9;
  border-radius: 3px;
  padding: 14px;
  width: 100%;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f1f1f1;

  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: #1d2c6f;
  }

  &.loading {
    background: #7786c9;
    cursor: progress;
  }

  &.danger {
    background: #f1f1f1;
    color: #ea5058;
  }

  &.secondary {
    color: #1d2c6f;
    background: #f1f1f1;
    font-family: 'Circe Lt', sans-serif;

    &.active {
      background: white;
      outline: 1px solid #f1f1f1;
    }

    &[disabled] {
      color: #c0c0c0;
      cursor: not-allowed;
    }
  }
}

.loader {
  margin-right: 15px;

  width: 28px;
  height: 28px;
  border: 3px solid #f1f1f1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
