<template>
  <div
    class="object-card-wrapper"
    :class="[type]"
    @click="$emit('toObject', data)"
  >
    <img class="preview" :src="getPreview(data)" alt="" />
    <div class="description">
      <h6>
        {{ data?.city?.name }}, {{ data.street }},
        {{ data.buildingNumber }}
      </h6>
      <div class="properties">
        <p v-if="data.apartmentNumber">
          {{ t('number.' + data.objectType, { number: data.apartmentNumber }) }}
        </p>
        <p v-if="data.floor">
          {{ t('floor', { number: data.floor }) }}
        </p>
        <p>
          {{ t('area', { number: data.area }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IShortObject } from '@/models/Object';
import ObjectPreviewPug from '@/assets/images/object-preview-pug.png';
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps<{
  type?: string;
  data: IShortObject;
}>();

defineEmits(['toObject']);

const mediaUrl = import.meta.env.VITE_MEDIA_URL;
const { t } = useI18n({
  useScope: 'local',
  messages: {
    en: {
      floor: '{number} floor',
      area: '{number} m²',
      number: {
        flat: 'Apartment {number}',
        office: 'Office {number}',
        house: 'House {number}',
        building: 'Building {number}',
      },
    },
    ru: {
      floor: '{number} этаж',
      area: '{number} м²',
      number: {
        flat: '{number} кв.',
        office: 'Офис {number}',
        house: 'Дом {number}',
        building: 'Здание {number}',
      },
    },
    es: {
      floor: '{number} suelo',
      area: '{number} m²',
      number: {
        flat: 'Plano {number}',
        office: 'Oficina {number}',
        house: 'Casa {number}',
        building: 'Edificio {number}',
      },
    },
  },
});

function getPreview(object: IShortObject) {
  if (object.image?.url) {
    return object.image.url;
  }
  return ObjectPreviewPug;
}
</script>

<script lang="ts">
export default {
  name: 'TheObjectCard',
};
</script>

<style scoped lang="scss">
.object-card-wrapper {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    .preview {
      outline: 1px solid #8091dc;
    }
  }

  &.single {
    img {
      max-height: 600px;
      object-fit: cover;
    }
  }

  .preview {
    width: 100%;
    border-radius: 3px;
  }

  .description {
    margin-top: 20px;

    h6 {
      font-family: 'Circe Th', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      color: #161e57;
      margin-bottom: 8px;
    }

    .properties {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      p {
        font-family: 'Circe Lt', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #161e57;
      }
    }
  }

  &.mini {
    .preview {
      height: 348px;
      object-fit: cover;
    }

    .description {
      h6 {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  &.micro {
    .preview {
      height: 298px;
      object-fit: cover;
    }

    .description {
      h6 {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
</style>
