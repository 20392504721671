<template>
  <div id="auth-layout-wrapper">
    <TheHeader logo-route-name="about-service" />
    <img
      class="banner"
      :src="bannerLayout"
      alt=""
    >
    <perfect-scrollbar
      v-if="isShowContent"
      class="wrapper-page"
    >
      <slot />
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import bannersAuth from '@/config/banners-auth';

const route = useRoute();
const bannerLayout = computed(() => bannersAuth.get(route.meta.banner));
const isShowContent = ref<boolean>(true);

watch(
    () => route.name,
    () => {
        isShowContent.value = false;
        nextTick(() => isShowContent.value = true);
    }
);
</script>

<script lang="ts">
export default {
    name: 'AuthLayout'
};
</script>

<style scoped lang="scss">
#auth-layout-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 90px auto;
  grid-template-areas: "header header"
                        "banner page";
  height: 100%;

  #header-wrapper {
    grid-area: header;
    border-bottom: 1px solid #F1F1F1;
  }

  .banner {
    display: block;
    grid-area: banner;
    object-fit: cover;
    align-self: start;
    overflow: hidden;
  }

  .wrapper-page {
    grid-area: page;
    padding: 25px 0;
  }
}
</style>
