import {
  IGeoRepository,
  IHintByLocationQueryRequest,
  IHintByLocationResponse,
} from '@/repositories/GeoRepository.d';

import axios from '@/axios';
import { locationHints } from '@/mocks/geolocation';
import NotificationsService from '@/services/NotificationsService';

const notificationsService = new NotificationsService();

export default class GeoRepository implements IGeoRepository {
  private readonly modeApp: string;

  constructor() {
    this.modeApp = import.meta.env.MODE;
  }

  async getHintsByLocation(
    queryParams: IHintByLocationQueryRequest
  ): Promise<IHintByLocationResponse[]> {
    try {
      const response = await axios.get('/suggestion', {
        params: queryParams,
      });
      return response.data;
    } catch (e) {
      await notificationsService.add({
        text: 'Нам не удалось получить данные стран и регионов((',
        title: 'Упс!',
        type: 'error',
      });

      if (this.modeApp === 'development') {
        return locationHints;
      }

      return [];
    }
  }
}
