<template>
  <div id="request-denial-template-wrapper">
    <div id="request-denial-wrapper">
      <h3>
        {{ t('title') }}
      </h3>
      <span>{{ t('description') }}</span>
      <p>
        {{ store.state.user.generalData.banReason }}
      </p>
      <div id="button-wrapper">
        <TheButton class="active" @click="$router.push({ name: 'objects' })">
          {{ t('home_button') }}
        </TheButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import locales from '@/components/templates/TheRequestDenial/locales.json';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheRequestDenial',
};
</script>

<style scoped lang="scss">
#request-denial-template-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #request-denial-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    & > * {
      max-width: 379px;
    }

    h3 {
      font-family: 'Circe Th', sans-serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 51px;
      color: #161e57;
      margin-bottom: 16px;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #161e57;
    }

    p {
      padding-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #161e57;
    }

    #button-wrapper {
      width: 100%;
      padding-top: 64px;
    }
  }
}
</style>
