import BasicIcon from '@/components/atoms/Icons/BasicIcon.vue';
import FurnitureIcon from '@/components/atoms/Icons/FurnitureIcon.vue';
import ElectricIcon from '@/components/atoms/Icons/ElectricIcon.vue';
import PlumbingIcon from '@/components/atoms/Icons/PlumbingIcon.vue';
import D3Icon from '@/components/atoms/Icons/3dIcon.vue';
import TableIcon from '@/components/atoms/Icons/TableIcon.vue';
import CustomIcon from '@/components/atoms/Icons/CustomIcon.vue';

import TheBasicTab from '@templates/TheBasicTab/index.vue';
import TheFurnitureTab from '@templates/TheFurnitureTab/index.vue';
import TheElectricTab from '@templates/TheElectricTab/index.vue';
import ThePlumbingTab from '@templates/ThePlumbingTab/index.vue';
import The3dTab from '@templates/The3dTab/index.vue';
import TheTableTab from '@templates/TheTableTab/index.vue';
import TheCustomTab from '@templates/TheCustomTab/index.vue';

const configTab = new Map();

configTab.set('basic', {
    icon: BasicIcon,
    component: TheBasicTab
});

configTab.set('furniture', {
    icon: FurnitureIcon,
    component: TheFurnitureTab
});

configTab.set('electric', {
    icon: ElectricIcon,
    component: TheElectricTab
});

configTab.set('plumbing', {
    icon: PlumbingIcon,
    component: ThePlumbingTab
});

configTab.set('3d', {
    icon: D3Icon,
    component: The3dTab
});

configTab.set('table', {
    icon: TableIcon,
    component: TheTableTab
});

configTab.set('custom', {
    icon: CustomIcon,
    component: TheCustomTab
});

export default configTab;
