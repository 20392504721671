import PersonalDataRepository from '@/repositories/PersonalDataRepository';
import {IPersonalDataRepository, IPreparedPdParams} from '@/repositories/PersonalDataRepository.d';
import {
  IEmailConfirmRequest,
  IEmailResetRequest,
  IRecoveryPasswordRequest,
  IResetPasswordRequest,
} from '@/repositories/AuthRepository.d';
import {
  IAuthService,
  ISignInRequest,
  ISignUpRequest,
} from '@/services/AuthService.d';

import AuthRepository from '@/repositories/AuthRepository';
import AuthTokenService from '@/services/AuthTokenService';
import NotificationsService from '@/services/NotificationsService';

import { getHash } from '@/encrypt';

export default class AuthService implements IAuthService {
  private authRepository: AuthRepository;
  private personalDataRepository: IPersonalDataRepository;
  private authTokenService: AuthTokenService;
  private notifications: NotificationsService;

  constructor() {
    this.authRepository = new AuthRepository();
    this.personalDataRepository = new PersonalDataRepository();
    this.authTokenService = new AuthTokenService();
    this.notifications = new NotificationsService();
  }

  /**
   * Method for user sign in
   * @param data
   * @return void
   */
  async signIn(data: ISignInRequest): Promise<void> {
    const emailHash = await getHash(data.email);

    const response = await this.authRepository.signIn({
      emailHash: emailHash,
      password: data.password,
    });

    this.authTokenService.set(response.token, true);
  }

  /**
   * Method for user sign un
   * @param data
   * @return void
   */
  async signUp(data: ISignUpRequest): Promise<void> {
    const personalData = {
      email: data.email,
      name: data.name,
      ...(data.phone && {
        phone: data.phone
          .replaceAll(' ', '')
          .replaceAll('(', '')
          .replaceAll(')', ''),
      }),
    };
    const pdHash = await getHash(Object.values(personalData).join('###'));
    const emailHash = await getHash(personalData.email);

    const singUpRequest = {
      pdHash,
      emailHash: emailHash,
      password: data.password,
      cityId: data.cityId,
      agreeWithPolicy: data.agreeWithPolicy,
      captchaToken: data.captchaToken,
      lang: data.lang
    };

    const preparedRequest = await this.authRepository.signUp(singUpRequest);
    preparedRequest.params.data = personalData;
    const result = await this.personalDataRepository.create(preparedRequest);

    if (result) {
      await this.authRepository.signUpConfirm({
          id: preparedRequest.params.id,
          pdHash: pdHash,
          emailHash: emailHash,
          requestId: preparedRequest.id,
          signature: preparedRequest.signature,
        });
    }
  }

  async emailConfirm(data: IEmailConfirmRequest): Promise<void> {
    const response = await this.authRepository.emailConfirm(data);
    this.authTokenService.set(response.token, true);
  }

  async emailReset(data: IEmailResetRequest): Promise<void> {
    try {
      await this.authRepository.emailReset(data);
    } catch (e) {
      this.notifications.add({
        text:
          'Мы не смогли сменить ваш email. ' + 'Пожалуйста, попробуйте позже',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async passwordRecovery(data: IRecoveryPasswordRequest): Promise<void> {
    // eslint-disable-next-line no-useless-catch
    try {
      await this.authRepository.recoveryPassword(data);
    } catch (e) {
      throw e;
    }
  }

  async resetPassword(data: IResetPasswordRequest): Promise<void> {
    try {
      const response = await this.authRepository.resetPassword(data);
      this.authTokenService.set(response.token, true);
    } catch (e) {
      this.notifications.add({
        text:
          'Мы не смогли установить новый пароль. ' +
          'Пожалуйста, попробуйте позже',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async resendConfirmEmail(email: string): Promise<void> {
    // eslint-disable-next-line no-useless-catch
    try {
      const emailHash = await getHash(email);
      await this.authRepository.resendConfirm(emailHash);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Method for user logout
   */
  async leave(): Promise<void> {
    await this.authRepository.leave();
    this.authTokenService.remove();
  }
}
