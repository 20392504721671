<template>
  <div id="requests-layout-wrapper">
    <TheHeader logo-route-name="objects">
      <template #left-content>
        <TheHeaderListMenu />
      </template>
    </TheHeader>
    <img
      class="banner"
      :src="bannerLayout"
      alt=""
    >
    <perfect-scrollbar class="wrapper-page">
      <slot />
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import TheHeaderListMenu from '@molecules/TheHeaderListMenu/index.vue';
import bannersAuth from '@/config/banners-auth';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();
const bannerLayout = computed(() => bannersAuth.get(route.meta.banner));
</script>

<script lang="ts">
export default {
    name: 'TheRequestsLayout'
};
</script>

<style scoped lang="scss">
#requests-layout-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: 90px auto;
  grid-template-areas: "header header"
                        "banner page";
  height: 100%;

  #header-wrapper {
    grid-area: header;
    border-bottom: 1px solid #F1F1F1;
  }

  .banner {
    display: block;
    grid-area: banner;
    object-fit: cover;
    align-self: stretch;
    overflow: hidden;
  }

  .wrapper-page {
    grid-area: page;
  }
}
</style>
