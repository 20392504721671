<template>
  <div id="edit-profile-form-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <Form
      v-slot="{ errors }"
      ref="profileForm"
      class="edit-profile-form"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <TheInput
        :locales="locales"
        :label="t('form.fields.name.label')"
        :default-value="currentProfile.name"
        required
        name="name"
        @input="setHasChanges"
      />
      <TheInput
        :locales="locales"
        :label="t('form.fields.phone.label')"
        :default-value="currentProfile.phone ? String(currentProfile.phone) : ''"
        name="phone"
        placeholder="+7 (000) 000 00 00"
        mask="+7 (###) ### ## ##"
        @input="setHasChanges"
      />
      <TheRegionSelector
        :locales="locales"
        :label="t('form.fields.cityId.label')"
        :default-value="
          currentProfile.country
            ? { ...currentProfile.country, city: currentProfile.city }
            : undefined
        "
        name="cityId"
        @input="setHasChanges"
        @newPdServerCode="serverCodeChange"
      />
      <TheButton
        :loading="isLoadingForm"
        type="submit"
        :is-active="hasChanges && !Object.keys(errors).length"
      >
        {{ t('form.submit-button') }}
      </TheButton>
    </Form>
    <TheModal v-model:show="isShowSaveDataModal">
      <template #default="{ close }">
        <TheSavingDataForm
          :close-modal="close"
          :route-name="leaveRouteName"
          @notSaveStatus="setIsNotSaveData"
        />
      </template>
    </TheModal>
  </div>
</template>

<script lang="ts" setup>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import locales from './locales.json';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheInput from '@molecules/TheInput/index.vue';
import TheRegionSelector from '@/components/organisms/TheRegionSelector/index.vue';
import { computed, ref } from 'vue';
import { IProfile } from '@/models/user';
import { IUpdateProfileRequest } from '@/services/UserService.d';
import UserService from '@/services/UserService';
import { onBeforeRouteLeave } from 'vue-router';
import TheSavingDataForm from '@templates/TheSavingDataForm/index.vue';
import TheModal from '@/components/organisms/TheModal/index.vue';

const isShowSaveDataModal = ref<boolean>(false);
const profileForm = ref(null);
const store = useStore();
const { t } = useI18n({
  messages: locales,
});
const leaveRouteName = ref<string>('');
const isNotSaveData = ref<boolean>(false);
const userService = new UserService();
const isLoadingForm = ref<boolean>(false);
const currentProfile = computed<IProfile>(() => {
  return { ...store.state.user.personalData, ...store.state.user.generalData };
});
const hasChanges = ref<boolean>(false);
const newServerCode = ref<string>('');

const schema = Yup.object().shape({
  name: Yup.string().required('form.fields.name.required'),
  cityId: Yup.number(),
  phone: Yup.string()
    .nullable()
    .transform((o, c) => (o === '' ? null : c))
    .min(18, 'form.fields.phone.isValid'),
});

async function onSubmit(values: IUpdateProfileRequest) {
  try {
    isLoadingForm.value = true;
    values.phone = values.phone
      ? String(values.phone)
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
      : '';
    values.newServerCode = newServerCode.value;
    values.email = currentProfile.value.email;

    await userService.updateProfile(values);
  } finally {
    isLoadingForm.value = false;
    newServerCode.value = ''
  }
}

function setIsNotSaveData(status: boolean) {
  isNotSaveData.value = status;
}

function setHasChanges() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const valuesFromForm = profileForm.value?.getValues();
  const formValues = {
    name: valuesFromForm.name,
    phone: valuesFromForm.phone
      ? String(valuesFromForm.phone)
          .replaceAll('+', '')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
      : null,
    cityId: valuesFromForm.cityId,
  };

  const defaultValue = {
    name: currentProfile.value.name,
    phone: String(currentProfile.value.phone).replace('+', ''),
    cityId: currentProfile.value?.city?.id,
  };

  hasChanges.value =
    JSON.stringify(formValues) !== JSON.stringify(defaultValue);
}

function serverCodeChange(serverCode: string) {
  newServerCode.value = currentProfile.value.pdServerCode == serverCode ? '' : serverCode;
}

onBeforeRouteLeave((to, from, next) => {
  if (hasChanges.value && !isNotSaveData.value) {
    isShowSaveDataModal.value = true;
    leaveRouteName.value = String(to.name);
    return;
  }

  next();
});
</script>

<script lang="ts">
export default {
  name: 'TheEditProfileForm',
};
</script>

<style scoped lang="scss">
#edit-profile-form-wrapper {
  h2 {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #161e57;
    margin-bottom: 16px;
  }
}

.edit-profile-form {
  .input-wrapper,
  .region-selector-wrapper {
    margin-bottom: 32px;
  }
}
</style>
