<template>
  <TheLoader v-if="isEmailConfirmationProcessing" />
  <div v-else id="email-confirmation-page">
    <component
      :is="showComponent"
      v-bind="{
        ...(!isLinkExpired && { 'is-already-confirmed': isAlreadyConfirmed }),
        ...(isLinkExpired && { text: 'user-verify' }),
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import AuthService from '@/services/AuthService';
import TheLoader from '@molecules/TheLoader/index.vue';
import TheEmailConfirmation from '@templates/TheEmailConfirmation/index.vue';
import TheLinkExpiredPage from '@templates/TheLinkExpiredPage/index.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const authService = new AuthService();
const route = useRoute();

const isEmailConfirmationProcessing = ref<boolean>(true);
const isAlreadyConfirmed = ref<boolean>(false);
const isLinkExpired = ref<boolean>(false);

const showComponent = computed(() => {
  if (isLinkExpired.value) {
    return TheLinkExpiredPage;
  }
  return TheEmailConfirmation;
});

async function confirmEmail() {
  try {
    await authService.emailConfirm({
      id: Number(route.query?.id || ''),
      token: String(route.query?.token || ''),
    });
  } catch (e: any) {
    if (e.response.status === 409) {
      const message = e.response.data.message;

      isLinkExpired.value = message === 'Link is expired';
      isAlreadyConfirmed.value = message === 'User already confirmed';
    }
  } finally {
    isEmailConfirmationProcessing.value = false;
  }
}

confirmEmail();
</script>

<script lang="ts">
export default {
  name: 'TheEmailConfirmationPage',
};
</script>

<style scoped lang="scss">
#email-confirmation-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
