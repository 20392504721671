<template>
  <div class="file-icon-wrapper">
    <img
      :src="FileIcon"
    >
    <span
      :style="{ fontSize: name.length >= 4 ? '10px' : '14px' }"
      class="format-name"
    >
      .{{ name || 'emp' }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import FileIcon from '@/assets/icons/file-icon.svg';

defineProps<{
  name: string
}>();
</script>

<script lang="ts">
export default {
  name: 'TheFileIcon'
};
</script>

<style lang="scss" scoped>
.file-icon-wrapper {
  width: 36px;
  height: 46px;
  position: relative;


  .format-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
  }
}
</style>
