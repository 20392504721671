import { IObjectService } from '@/services/ObjectService.d';
import {
  IObjectRepository,
  IGetShareUrlRequest,
  IGetShareUrlResponse,
  IAddNoteRequest,
} from '@/repositories/ObjectRepository.d';

import ObjectRepository from '@/repositories/ObjectRepository';

import store from '@/store';
import { shortList, objectData } from '@/mocks/object';

import NotificationsService from '@/services/NotificationsService';
const notificationsService = new NotificationsService();

export default class ObjectService implements IObjectService {
  private objectRepository: IObjectRepository;
  private readonly modeApp: string;

  constructor() {
    this.objectRepository = new ObjectRepository();
    this.modeApp = import.meta.env.MODE;
  }

  async getObjects(): Promise<void> {
    try {
      const response = await this.objectRepository.getShortObjects();
      store.commit('object/setShortList', response);
    } catch (e) {
      await notificationsService.add({
        text: 'Нам не удалось получить данные ваших объектов((',
        title: 'Упс!',
        type: 'error',
      });

      if (this.modeApp === 'development') {
        store.commit('object/setShortList', shortList);
      }
    }
  }

  async getObject(id: number): Promise<void> {
    try {
      const response = await this.objectRepository.getObjectData(id);
      store.commit('object/setCurrentObject', response);
    } catch (e) {
      await notificationsService.add({
        text: 'Нам не удалось получить данные текущего объекта((',
        title: 'Упс!',
        type: 'error',
      });

      if (this.modeApp === 'development') {
        store.commit('object/setCurrentObject', objectData);
      }
    }
  }

  async addNote(id: number, data: IAddNoteRequest): Promise<void> {
    try {
      await this.objectRepository.addNote(id, data);
    } catch (e) {
      console.log(e);
    }
  }

  async getShared(token: string): Promise<void> {
    const response = await this.objectRepository.getSharedObject(token);
    store.commit('object/setCurrentObject', response);
  }

  async share(
    id: number,
    data: IGetShareUrlRequest
  ): Promise<IGetShareUrlResponse> {
    try {
      return await this.objectRepository.getShareUrl(id, data);
    } catch (e) {
      if (this.modeApp === 'development') {
        return { url: 'iuyuwtauyhidjoawdj' };
      }
      throw e;
    }
  }
}
