import { INotification } from '@/services/NotificationsService.d';

type stateT = {
  notifications: INotification[]
}
const getState = ():stateT => ({
    notifications: [],
});

export default {
    namespaced: true,
    state: getState(),
    actions: {},
    mutations: {
        addNotifications(state: stateT, data:INotification) {
            state.notifications.unshift(data);
        },
        deleteNotification(state: stateT, id:number) {
            const index = state.notifications.findIndex(
                (n:INotification) => n.id === id
            );
            if (index > -1) state.notifications.splice(index, 1);
        },
        clearState(state: stateT) {
            Object.assign(state, getState());
        },
    },
    getters: {}
};
