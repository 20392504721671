<template>
  <div id="empty-plan-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <p v-if="route.name !== 'shared-object'">
      {{ text || t('description') }}
    </p>
    <TheButton
      v-if="route.name !== 'shared-object'"
      is-active
      @click="
        $router.push({
          name: 'edit-object-request',
          query: { object: route.params.id },
        })
      "
    >
      {{ t('fill-out-an-application') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import locales from '@templates/TheEmptyPlan/locales.json';
import TheButton from '@/components/atoms/TheButton/index.vue';

defineProps<{ text: string }>();

const { t } = useI18n({
  messages: locales,
});

const route = useRoute();
</script>

<script lang="ts">
export default {
  name: 'TheEmptyPlan',
};
</script>

<style scoped lang="scss">
#empty-plan-wrapper {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  h2 {
    margin-bottom: 10px;

    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    font-family: 'Circe Th', sans-serif;
    color: #161e57;
  }

  p {
    margin-bottom: 35px;

    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
  }

  button {
    max-width: 342px;
  }
}
</style>
