import object from '@/pages/shared/object/index.vue';

export default [
  {
    name: 'shared-object',
    path: 'object/:id',
    component: object,
    meta: {
      title: 'pages.shared.shared-object',
      banner: 'password-recovery',
    },
  },
];
