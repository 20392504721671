<template>
    <TheSupportForm />
</template>

<script setup lang="ts">
import TheSupportForm from '@templates/TheSupportForm/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheSupportPage',
};
</script>

<style scoped></style>
