<template>
  <div id="sign-up-page-wrapper">
    <TheSignUpForm />
  </div>
</template>

<script lang="ts" setup>
import TheSignUpForm from '@templates/TheSignUpForm/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheSignUpPage',
};
</script>

<style scoped lang="scss">
#sign-up-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

::v-deep(.sign-up-form-wrapper) {
  width: 342px;
}
</style>
