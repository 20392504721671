<template>
  <div
    class="input-wrapper"
    :class="{
      // success: meta.valid,
      'has-error': isOutFocus && (!!errorMessage || isErrors || customErrors),
    }"
  >
    <!-- TheInput Field -->
    <input
      v-if="!suffics"
      :id="name"
      v-maska
      :data-maska="mask"
      :name="name"
      :type="inputType"
      :value="inputValue"
      :placeholder="placeholder"
      @input="inputField"
      @blur="handleBlur"
      :class="isLikeSelect ? 'hideCaret' : ''"
    />
    <div v-else class="suffics-input-wrapper">
      <input
        :id="name"
        :name="name"
        :type="inputType"
        :value="inputValue"
        :placeholder="placeholder"
        @input="inputField"
        @blur="handleBlur"
      />
      <span v-if="inputValue?.length" ref="sufficsRef" class="suffics-suffix">
        {{ suffics }}
      </span>
    </div>

    <!-- Label Field -->
    <label :for="name" :class="[!!inputValue && 'has-value']">
      {{ label }}{{ required ? '*' : '' }}
    </label>

    <!-- Toggle Password Field -->
    <button
      v-if="type === 'password'"
      type="button"
      class="toggle-password"
      @click="isShowPassword = !isShowPassword"
    >
      <img :src="isShowPassword ? EyeOpenIcon : EyeCloseIcon" alt="" />
    </button>

    <!-- Messages by status Field -->
    <template v-if="!hideMessage && inputValue">
      <!-- Errors -->
      <p
        v-if="
          ((!isRequiredMessage && errorMessage) || customErrors) && isOutFocus
        "
        class="help-message"
      >
        {{ customErrors || (errorMessage && t(`${errorMessage}`)) || '' }}
      </p>

      <!-- Messages -->
      <p v-else-if="hint && !isOutFocus" class="help-message">
        {{ hint }}
      </p>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { vMaska } from 'maska';
import {
  computed,
  defineProps,
  onMounted,
  ref,
  toRef,
  defineEmits,
  watch,
  nextTick,
} from 'vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import EyeOpenIcon from '@/assets/icons/eye-open.svg';
import EyeCloseIcon from '@/assets/icons/eye-close.svg';

const props = defineProps<{
  label: string;
  name: string;
  locales: object;
  successMessage?: string;
  placeholder?: string;
  type?: string;
  defaultValue?: string;
  newValue?: string | number;
  hideMessage?: boolean;
  isErrors?: boolean;
  required?: boolean;
  hint?: string;
  mask?: string;
  customErrors?: string;
  suffics?: string;
  isLikeSelect?: boolean;
}>();

const emits = defineEmits(['input', 'getIsFocus']);
const isOutFocus = ref<boolean>(true);

type locales = typeof props.locales;

const { t } = useI18n<locales>({
  useScope: 'local',
  allowComposition: true,
  messages: props.locales,
});

// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialValue: props.value,
});

const sufficsRef = ref<HTMLTextAreaElement | null>(null);
let canvas: HTMLCanvasElement | null = null;

const inputMessage = computed(() => {
  if (errorMessage.value) return t(`${errorMessage.value}`);
  if (props.successMessage) return t(props.successMessage);
  return props.hint || '';
  // return t('great') + '!';
});

const isRequiredMessage = computed(() => {
  if (!errorMessage.value) return false;
  return errorMessage.value?.indexOf('required') > -1;
});

const inputType = computed(() => {
  if (props.type) {
    if (props.type === 'password') {
      return isShowPassword.value ? 'text' : 'password';
    }
    return props.type;
  }
  return 'text';
});

const isShowPassword = ref<boolean>(false);
const isShowMessage = ref<boolean>(false);

function inputField(data: any) {
  const value = data.target.value.replaceAll(',', '.');
  emits('input', value);
  handleChange(value || '');

  if (props.suffics) moveSuffics();
}

function moveSuffics() {
  if (!canvas) canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return;

  context.font = '16px Circe Rg';
  const metrics = context?.measureText(inputValue.value);
  const width = metrics?.width;

  if (sufficsRef.value && width) {
    sufficsRef.value.style.left = width + 17 + 'px';
  }
}

function checkRequired(name: string | undefined) {
  return (name && name.indexOf('required') > -1) || true;
}

onMounted(() => {
  if (props.defaultValue) handleChange(props.defaultValue);
  nextTick(() => {
    window.addEventListener('click', (event) => {
      const target = event.target as HTMLInputElement;
      isShowMessage.value = target?.name === `${props.name}`;
      isOutFocus.value = target?.name !== `${props.name}`;
      emits('getIsFocus', target?.name === `${props.name}`);
    });
  });
});

watch(
  () => props.newValue,
  (newValue) => {
    if (newValue != undefined) {
      handleChange(newValue);
    }
  }
);
</script>

<script lang="ts">
export default {
  name: 'TheInput',
};
</script>

<style src="./styles.scss" lang="scss" scoped></style>
