<template>
  <TheEditorPersonalArea>
    <TheChangePasswordForm />
  </TheEditorPersonalArea>
</template>

<script lang="ts" setup>
import TheEditorPersonalArea from '@/layouts/editor-personal-area.vue';
import TheChangePasswordForm from '@templates/TheChangePasswordForm/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheChangePasswordPage',
};
</script>

<style scoped></style>
