<template>
  <div class="saving-data-form">
    <h3 v-if="!isSuccess">
      {{ t('title') }}
    </h3>
    <h3 v-if="isSuccess">
      {{ t('success_title') }}
    </h3>
    <h5 v-if="!isSuccess">
      {{ t('text') }}
    </h5>
    <h5 v-if="isSuccess">
      {{ t('success_description') }}
    </h5>
    <TheButton
      v-if="!isSuccess"
      class="mb-[20px] max-w-[343px]"
      is-active
      @click="send"
    >
      {{ t('next') }}
    </TheButton>
    <TheButton class="max-w-[343px]" style-type="secondary" @click="closeModal">
      <div v-if="!isSuccess">
        {{ t('reject') }}
      </div>
      <div v-if="isSuccess">
        {{ t('close') }}
      </div>
    </TheButton>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TheRetryRegisterMail',
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TheButton from '@/components/atoms/TheButton/index.vue';
import {ref} from "vue";

const router = useRouter();
const isSuccess = ref<boolean>(false);

const emits = defineEmits(['sendMail', 'close']);
const { t } = useI18n({
  useScope: 'local',
  messages: {
    ru: {
      title:
        'Email не подтвержден',
      text: 'Email уже зарегистрирован, но подтверждение не завершено. Отправьте письмо повторно для завершения регистрации или попробуйте зарегистрироваться снова через 24 часа',
      next: 'Отправить письмо повторно',
      reject: 'Отмена',
      close: 'Закрыть',
      success_title: 'Письмо отправлено',
      success_description: 'Мы выслали вам письмо на указанный email. Перейдите по ссылке в письме, чтобы завершить регистрацию.'
    },
    en: {
      title: 'Email not confirmed',
      text: 'The email has already been registered, but the confirmation has not been completed. Resend the email to complete registration or try registering again in 24 hours',
      close: 'Close',
      next: 'Resend email',
      reject: 'Cancel',
      success_title: 'Email sent',
      success_description: 'We have sent you a letter to the specified email. Follow the link in the email to complete registration.'
    },
    es: {
      title: 'Correo electrónico no confirmado',
      text: 'El correo electrónico ya ha sido registrado, pero no se ha completado la confirmación. Vuelva a enviar el correo electrónico para completar el registro o intente registrarse nuevamente en 24 horas',
      close: 'Сerca',
      next: 'Reenviar email',
      reject: 'Cancel',
      success_title: 'Email enviado',
      success_description: 'Le hemos enviado una carta al correo electrónico especificado. Siga el enlace en el correo electrónico para completar el registro.'
    },
  },
});

const closeModal = () => {
  emits('close');
}
const send = () => {
  emits('sendMail');
  isSuccess.value = true;
  // emits('close');
}
</script>

<style scoped lang="scss">
.saving-data-form {
  width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #161e57;
    margin-bottom: 10px;
  }

  h5 {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

  }
}
</style>
