<template>
  <TheRequestsLayout>
    <router-view />
  </TheRequestsLayout>
</template>

<script lang="ts" setup>
import TheRequestsLayout from '@/layouts/requests.vue';
</script>

<script lang="ts">
export default {
    name: 'TheRequestsWrapper'
};
</script>

<style scoped lang="scss">

</style>
