import PersonalDataRepository from '@/repositories/PersonalDataRepository';
import { IPersonalDataRepository } from '@/repositories/PersonalDataRepository.d';
import {
  IUpdateLanguageRequest,
  IUpdatePasswordRequest,
  IUpdateSettingsRequest,
  IUserRepository,
} from '@/repositories/UserRepository.d';
import NotificationsService from '@/services/NotificationsService';
import { INotificationService } from '@/services/NotificationsService.d';
import {
  IUserService,
  IUpdateProfileRequest,
} from '@/services/UserService.d';

import { generalData, personalData } from '@/mocks/user';
import UserRepository from '@/repositories/UserRepository';
import store from '@/store';
import { getHash } from '@/encrypt';

export default class UserService implements IUserService {
  private userRepository: IUserRepository;
  private personalDataRepository: IPersonalDataRepository;
  private notifications: INotificationService;
  private readonly modeApp: string;

  constructor() {
    this.userRepository = new UserRepository();
    this.personalDataRepository = new PersonalDataRepository();
    this.notifications = new NotificationsService();
    this.modeApp = import.meta.env.MODE;
  }

  async getProfileGeneralData(): Promise<void> {
    try {
      const response = await this.userRepository.getProfileData();
      store.commit('user/setGeneralData', response);
    } catch (e) {
      throw e;
    }
  }

  async getProfilePersonalData(id: string): Promise<void> {
    try {
      const preparedRequest= await this.userRepository.prepareGetRequest();
      preparedRequest.params = {id: id, data: {}, hash: ''}
      const response = await this.personalDataRepository.get(preparedRequest);

      store.commit('user/setPersonalData', response);
    } catch (e) {
      throw e;
    }
  }

  async clearProfile(): Promise<void> {
    store.commit('user/setGeneralData', null);
    store.commit('user/setPersonalData', null);
  }

  async updateProfile(data: IUpdateProfileRequest): Promise<void> {
    try {
      const { cityId, newServerCode, ...personalData } = data;
      const pdHash = await getHash(Object.values(personalData).join('###'));

      if (newServerCode == '' || newServerCode == undefined) {
        const generalData = await this.userRepository.updateProfileData({ cityId });
        store.commit('user/setGeneralData', generalData);

        const preparedRequest =
          await this.userRepository.prepareUpdateRequest({ pd_hash: pdHash });

        preparedRequest.params.data = personalData;
        const response = await this.personalDataRepository.update(preparedRequest);
        store.commit('user/setPersonalData', response);
      } else {
        const preparedRequest = await this.userRepository.prepareMigrateRequest({
          pd_hash: pdHash,
          pd_server_code: newServerCode,
        });
        preparedRequest.params.data = personalData;

        const response = await this.personalDataRepository.create(preparedRequest);
        await this.userRepository.confirmMigrate({
          city_id: cityId,
          pd_server_code: newServerCode,
        });

        store.commit('user/setPersonalData', personalData);
      }
    } catch (e) {
      this.notifications.add({
        text:
          'Мы не смогли сохранить ваши новые данные профиля. ' +
          'Пожалуйста, попробуйте позже.',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async editSetting(data: IUpdateSettingsRequest): Promise<void> {
    try {
      await this.userRepository.updateSettings(data);
      await this.getProfileGeneralData();
    } catch (e) {
      this.notifications.add({
        text:
          'Мы не смогли сохранить настройки. ' +
          'Пожалуйста, попробуйте позже.',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async editLanguage(data: IUpdateLanguageRequest): Promise<void> {
    try {
      await this.userRepository.updateLanguage(data);
      await this.getProfileGeneralData();
    } catch (e) {
      this.notifications.add({
        text: 'Мы не смогли сохранить язык. ' + 'Пожалуйста, попробуйте позже.',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async editEmail(id: string, email: string): Promise<void> {
    try {
      const emailHash = await getHash(email)
      const preparedRequest = await this.userRepository.prepareEditEmailRequest(
        {email_hash: emailHash}
      );
      preparedRequest.params.data = {email: email, email_hash: emailHash}
      this.personalDataRepository.editEmail(preparedRequest).then(() => {
        this.userRepository.changeEmailSendEmail(emailHash)
      });
    } catch (e) {
      this.notifications.add({
        text:
          'Мы не смогли сохранить ваш новый email. ' +
          'Пожалуйста, попробуйте позже.',
        title: 'Упс!',
        type: 'error',
      });
      throw e;
    }
  }

  async editPassword(data: IUpdatePasswordRequest): Promise<void> {
    // eslint-disable-next-line no-useless-catch
    try {
      await this.userRepository.updatePassword(data);
    } catch (e) {
      // this.notifications.add({
      //     text: 'Мы не смогли сохранить ваш новый пароль. ' +
      //   'Пожалуйста, попробуйте позже.',
      //     title: 'Упс!',
      //     type: 'error'
      // });
      throw e;
    }
  }
}
