import { IShortObject, IObject } from '@/models/Object';

export const shortList:IShortObject[] = [
    {
        id: 1,
        area: '183',
        'street': 'Пушкина',
        'objectType': 'flat',
        'buildingNumber': '175/2',
        'apartmentNumber': '105',
        'city': {
            'id': 1,
            'name': 'Омск'
        }
    },
    {
        id: 2,
        area: '183',
        'street': 'Пушкина',
        'objectType': 'flat',
        'buildingNumber': '175/2',
        'apartmentNumber': '105',
        'city': {
            'id': 1,
            'name': 'Омск'
        }
    },
    // {
    //     id: 3,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // },
    // {
    //     id: 4,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // },
    // {
    //     id: 5,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // },
    // {
    //     id: 6,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // },
    // {
    //     id: 7,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // },
    // {
    //     id: 8,
    //     'street': 'Пушкина',
    //     'buildingNumber': '175/2',
    //     'apartmentNumber': '105',
    //     'city': {
    //         'id': 1,
    //         'name': 'Омск'
    //     }
    // }
];

export const objectData:IObject = {
    'id': 26,
    'street': 'Фермерская',
    'buildingNumber': '12',
    'apartmentNumber': '34',
    'floor': '3',
    'area': '123',
    'objectType': 'flat',
    'buildingType': 'brick',
    'image': {
        'id': 102,
        // eslint-disable-next-line max-len
        'url': 'https://3.bp.blogspot.com/-oW_cccvgEuY/XGso01JnsCI/AAAAAAAACns/VlZjrFd8qgQzTmcyKLpsvwbAXSAd6m_4QCKgBGAs/w3840-h2400-c/mountain-lake-nature-forest-landscape-clouds-scenery-4K-171.jpg',
    },
    'city': {
        'id': 9004,
        'name': 'Боко'
    },
    // pdf: {
    //     id: 12,
    //     url: 'https://www.google-analytics.com/analytics.js'
    // },
    // model: {
    //     id: 12,
    //     url: 'https://www.google.com/'
    // },
    'tabs': [
        {
            'id': 199,
            'name': 'Основной план',
            'order': 0,
            'text': 'Основной план',
            'type': 'basic',
            'image': {
                'id': 103,
                // eslint-disable-next-line max-len
                'url': 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
            'elements': [
                {
                    'id': 1,
                    'name': 'p',
                    'type': 'wall',
                    'data': {
                        area: 123,
                        volume: true,
                        composition: 'Composition Text',
                        image: {
                            id: 13,
                            // eslint-disable-next-line max-len
                            url: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
                        },
                        notes: 'Текст заметок',
                    },
                    'polygons': [
                        {
                            id: 1,
                            tabElementId: 1,
                            'points': [
                                {
                                    'x': 42.59375,
                                    'y': 228.1953125
                                },
                                {
                                    'x': 41.59375,
                                    'y': 335.1953125
                                },
                                {
                                    'x': 170.59375,
                                    'y': 335.1953125
                                },
                                {
                                    'x': 170.59375,
                                    'y': 228.1953125
                                },
                                {
                                    'x': 43.59375,
                                    'y': 228.1953125
                                }
                            ]
                        },
                        {
                            id: 2,
                            tabElementId: 1,
                            'points': [
                                {
                                    'x': 274.59375,
                                    'y': 179.1953125
                                },
                                {
                                    'x': 328.59375,
                                    'y': 70.1953125
                                },
                                {
                                    'x': 388.59375,
                                    'y': 176.1953125
                                },
                                {
                                    'x': 276.59375,
                                    'y': 179.1953125
                                }
                            ]
                        }
                    ]
                },
                {
                    'id': 2,
                    'name': 'e',
                    'type': 'door',
                    'data': {
                        'area': 123,
                        'volume': false,
                        'composition': '\u0411\u0435\u0442\u043e\u043d',
                        'image': {
                            'id': 104,
                            // eslint-disable-next-line max-len
                            'url': '/storage/admin/b31501cf92161fc99190fced7fea634b23496ad637ced16235b5c951670872b3.jpeg'
                        }
                    },
                    'polygons': [
                        {
                            id: 3,
                            tabElementId: 2,
                            'points': [
                                {
                                    'x': 390.59375,
                                    'y': 250.1953125
                                },
                                {
                                    'x': 707.59375,
                                    'y': 251.1953125
                                },
                                {
                                    'x': 707.59375,
                                    'y': 282.1953125
                                },
                                {
                                    'x': 390.59375,
                                    'y': 280.1953125
                                },
                                {
                                    'x': 390.59375,
                                    'y': 250.1953125
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            'id': 200,
            'name': 'План с мебелью',
            'order': 1,
            'text': 'План с мебелью',
            'type': 'furniture'
        },
        {
            'id': 201,
            'name': 'План электрики',
            'order': 2,
            'text': 'План электрики',
            'type': 'electric'
        },
        {
            'id': 202,
            'name': 'План сантехники',
            'order': 3,
            'text': 'План сантехники',
            'type': 'plumbing'
        },
        {
            'id': 203,
            'name': '3D',
            'order': 4,
            'text': '3D',
            'type': '3d'
        },
        {
            'id': 204,
            'name': 'Таблицы',
            'order': 5,
            'text': 'Таблицы',
            'type': 'table'
        },
        {
            'id': 205,
            'name': 'Кастомный план ',
            'order': 5,
            'text': 'Таблицы',
            'type': 'custom'
        }
    ]
};
