<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59839 3.2915H4C3.44772 3.2915 3 3.73922 3 4.2915V19.2915C3 19.8438 3.44772 20.2915 4 20.2915H6.59839V3.2915Z"
    />
    <path
      d="M8.09839 20.2915H20C20.5523 20.2915 21 19.8438 21 19.2915V17.0415L8.09839 17.0415V20.2915Z"
    />
    <path d="M21 15.5415V12.5415L8.09839 12.5415V15.5415L21 15.5415Z" />
    <path d="M21 11.0415V8.0415L8.09839 8.0415L8.09839 11.0415L21 11.0415Z" />
    <path
      d="M21 6.5415V4.2915C21 3.73922 20.5523 3.2915 20 3.2915H8.09839V6.5415L21 6.5415Z"
    />
  </svg>
</template>
