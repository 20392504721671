<template>
  <div id="header-wrapper">
    <div>
      <router-link :to="{ name: logoRouteName }">
        <img
          :src="fullSizeLogotype"
          alt=""
        >
      </router-link>
      <slot name="left-content" />
    </div>
    <div>
      <slot name="right-content" />
      <TheLanguageSwitcher />
    </div>
  </div>
</template>

<script lang="ts" setup>
import fullSizeLogotype from '@/assets/images/logotype/full.svg';
import TheLanguageSwitcher from '@molecules/TheLanguageSwitcher/index.vue';
import { defineProps } from 'vue';

defineProps<{
  logoRouteName: string
}>();
</script>

<script lang="ts">
export default {
    name: 'TheHeader'
};
</script>

<style scoped lang="scss">
#header-wrapper {
  padding: 19px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    display: flex;
    align-items: center;
  }
}
</style>
