import {
  IPersonalDataRepository,
  IPreparedPdRequest,
} from '@/repositories/PersonalDataRepository.d';
import { IPersonalData } from '@/models/user';

import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export default class PersonalDataRepository implements IPersonalDataRepository {
  async get(request: IPreparedPdRequest): Promise<IPersonalData> {
    const newFormatRequest = snakecaseKeys(request, { deep: true });
    const response = await axios.post(request.host + '/pd/users/get', newFormatRequest);

    return camelcaseKeys(response.data, { deep: true });
  }

  async create(request: IPreparedPdRequest): Promise<IPersonalData> {
    const newFormatRequest = snakecaseKeys(request, { deep: true });
    const response = await axios.post(request.host + '/pd/users/create', newFormatRequest);

    return camelcaseKeys(response.data, { deep: true });
  }

  async update(request: IPreparedPdRequest): Promise<IPersonalData> {
    const newFormatRequest = snakecaseKeys(request, { deep: true });
    const response = await axios.post(request.host + '/pd/users/update', newFormatRequest);

    return camelcaseKeys(response.data, { deep: true });
  }

  async editEmail(request: IPreparedPdRequest): Promise<IPersonalData> {
    const newFormatRequest = snakecaseKeys(request, { deep: true });
    const response = await axios.post(request.host + '/pd/users/change-email', newFormatRequest);

    return camelcaseKeys(response.data, { deep: true });
  }

  async confirmEditEmail(request: IPreparedPdRequest): Promise<IPersonalData> {
    const newFormatRequest = snakecaseKeys(request, { deep: true });
    const response = await axios.post(request.host + '/pd/users/change-email/confirm', newFormatRequest);

    return camelcaseKeys(response.data, { deep: true });
  }
}
