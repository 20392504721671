<template>
  <div id="object-tabs-wrapper">
    <div id="scroll-wrapper">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        :class="[currentTab === tab.order && 'active']"
        @click="currentTab = tab"
      >
        <component :is="objectTabs.get(tab.type).icon" />
        <span>
          {{ tab.type === 'custom' ? t(tab.name) : t(tab.type) }}
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import objectTabs from '@/config/object-tabs';
import { ITabObject } from '@/models/Object';
import { computed } from 'vue';
import locales from '@/components/organisms/TheObjectTabs/locales.json';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  messages: locales,
});
const store = useStore();

const tabs = computed<ITabObject[]>(
  () => store.state.object.currentObject?.tabs || []
);
const currentTab = computed({
  get: () => store.state.object.currentTabOrder,
  set: (tab: ITabObject) => {
    store.commit('object/setCurrentTabOrder', tab.order);
    store.commit('object/setCurrentTabName', tab.type);
  },
});

if (tabs.value) currentTab.value = tabs.value[0];
</script>

<script lang="ts">
export default {
  name: 'TheObjectTabs',
};
</script>

<style scoped lang="scss">
#object-tabs-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-right: 1px solid #f1f1f1;
  background: white;

  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;

  #scroll-wrapper {
    overflow-y: auto;
    -ms-overflow-style: none; /* hide scroll IE and Edge */
    scrollbar-width: none; /* hide scroll Firefox */

    button {
      display: grid;
      grid-template-columns: 24px auto;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 3px;
      width: 100%;

      padding: 8px;
      overflow: hidden;
      transition: 0.3s;

      svg {
        fill: #1d2c6f;
      }

      span {
        display: block;
        white-space: nowrap;
        justify-self: start;
        transition: 0.3s;
        box-sizing: border-box;
        text-align: left;
        color: #161e57;

        opacity: 0;
        max-width: 0;
      }

      &.active {
        background: #1d2c6f;

        svg {
          fill: #ffffff;
        }

        span {
          color: #ffffff;
        }
      }

      &:hover {
        border: 1px solid #f1f1f1;
      }
    }

    & > button:not(:last-child) {
      margin-bottom: 4px;
    }

    &:hover {
      button {
        padding: 8px 16px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          opacity: 1;
          max-width: 240px;
          margin-left: 6px;
        }
      }
    }
  }
  #scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
}
</style>
