import SignIn from '@/pages/auth/sign-in/index.vue';
import SignUp from '@/pages/auth/sign-up/index.vue';
import EmailConfirmation from '@/pages/auth/email-confirmation/index.vue';
import PasswordRecovery from '@/pages/auth/password-recovery/index.vue';
import ResetPassword from '@/pages/auth/reset-password/index.vue';
import Logout from "@/pages/auth/logout/index.vue";

export default [
  {
    name: 'sign-in',
    path: 'sign-in',
    component: SignIn,
    meta: {
      title: 'pages.auth.sign-in',
      banner: 'sign-in',
    },
  },
  {
    name: 'logout',
    path: 'logout',
    component: Logout,
  },
  {
    name: 'sign-up',
    path: 'sign-up',
    component: SignUp,
    meta: {
      title: 'pages.auth.sign-up',
      banner: 'sign-up',
    },
  },
  {
    name: 'email-confirmation',
    path: 'email-confirmation',
    component: EmailConfirmation,
    meta: {
      title: 'pages.auth.email-confirmation',
      banner: 'sign-up',
    },
  },
  {
    name: 'password-recovery',
    path: 'password-recovery',
    component: PasswordRecovery,
    meta: {
      title: 'pages.auth.password-recovery',
      banner: 'password-recovery',
    },
  },
  {
    name: 'reset-password',
    path: 'reset-password',
    component: ResetPassword,
    meta: {
      title: 'pages.auth.reset-password',
      banner: 'password-recovery',
    },
  },
];
