<template>
  <div id="password-recovery-page-wrapper">
    <TheRecoveryPasswordForm
      @setSuccess="(status) => isSuccessRecoveryForm = status"
    />
    <router-link
      v-if="!isSuccessRecoveryForm"
      :to="{ name: 'sign-in' }"
    >
      {{ t('back-sign-in') }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import TheRecoveryPasswordForm from
    '@templates/TheRecoveryPasswordForm/index.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const isSuccessRecoveryForm = ref<boolean>(false);
const { t } = useI18n({
    messages: {
        en: {
            'back-sign-in': 'Back to Sign In'
        },
        ru: {
            'back-sign-in': 'Назад к авторизации'
        },
        es: {
            'back-sign-in': 'Volver a autorización'
        }
    }
});
</script>

<script lang="ts">
export default {
    name: 'ThePasswordRecovery'
};
</script>

<style scoped lang="scss">
#password-recovery-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8091dc;
  }
}
</style>
