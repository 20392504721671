<template>
  <div class="mt-[12px]">
    <h6 v-if="label">
      {{ label }}
    </h6>
    <input
      ref="inputFile"
      :accept="accepts && accepts.join(',')"
      :multiple="multiple"
      class="hidden"
      type="file"
      @change="changeInput"
    >
    <div
      v-if="files.length"
      class="mt-[33px]"
    />
    <div
      v-for="(file, index) in files"
      :key="file.size"
      class="file"
    >
      <TheFileIcon :name="getFormatFile(file)" />
      <div
        :class="fileHasError(index) && 'secundary-text'"
        class="information"
      >
        <p>{{ file.name }}</p>
        <span> {{ getFileSizeMB(file) }} {{ t('mb') }} </span>
      </div>
      <div class="actions">
        <span
          v-if="fileHasError(index)"
          class="error-text"
        >
          {{ getFileError(index) }}
        </span>
        <span v-else>
          {{ t('uploaded') }}
        </span>
        <img
          :src="CloseIcon"
          @click="deleteFile(index)"
        >
      </div>
    </div>
    <div
      v-if="files.length"
      class="mb-[44px]"
    />
    <div
      v-if="isLimitExceeded"
      class="message error-message"
    >
      {{
        t('error.limit', files.length - limit, {
          exceededCount: files.length - limit,
        })
      }}
    </div>
    <div
      v-else
      class="message"
    >
      <span>{{ t('help-message', {limit}) }}</span>
      <span>{{ t('help-message-files', {accepts: availableImageExtensions.join(', '), size: 10}) }}</span>
      <span>{{ t('help-message-files', {accepts: available3DExtensions.join(', '), size: 100}) }}</span>
    </div>
    <TheButton
      :disabled="isUploadDisabled"
      class="add-file-button max-w-[435px]"
      style-type="secondary"
      @click="$refs.inputFile.click()"
    >
      <img
        :src="isLimitExceeded ? PlusInCircleGray : PlusInCircle"
        alt=""
      >
      <span>{{ t('upload') }}</span>
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import CloseIcon from '@/assets/icons/close-gray.svg';
import PlusInCircleGray from '@/assets/icons/plus-in-circle-gray.svg';
import PlusInCircle from '@/assets/icons/plus-in-circle.svg';
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheFileIcon from '@/components/atoms/TheFileIcon/index.vue';
import locales from '@molecules/TheAttachingFiles/locales.json';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { available3DExtensions, availableImageExtensions } from '@/utils/consts';

const props = defineProps<{
  limit?: number;
  accepts?: string[];
  multiple?: boolean;
  files: File[];
  label?: string;
}>();

const emits = defineEmits(['update:files', 'setHasValidationErrors']);

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});

const files = computed<File[]>({
  get: () => props.files || [],
  set: (newValue) => emits('update:files', newValue),
});

const isLimitExceeded = computed(() => !props.limit || props.limit < files.value.length);
const isUploadDisabled = computed(() => isLimitExceeded.value || bigSizeIndexes.value.length || wrongExtensionIndexes.value.length);

const bigSizeIndexes = ref<number[]>([]);
const wrongExtensionIndexes = ref<number[]>([]);

function changeInput(event: Event) {
  const target = event.target as HTMLInputElement;

  Object.values(target.files as FileList).forEach((file: File) => {
    files.value.push(file);
    validateFile(file, files.value.length - 1);
  });
}

function getFormatFile(file: File): string {
  return file.name.split('.').at(-1) || '';
}

function getFileSizeMB(file: File): string {
  return (file.size / (1024 * 1024)).toFixed(2);
}

function deleteFile(indexFile: number) {
  bigSizeIndexes.value = [];
  wrongExtensionIndexes.value = [];
  emits('setHasValidationErrors', false);

  files.value.splice(indexFile, 1);
  files.value.forEach((f, index) => validateFile(f, index));
}

function validateFile(file: File, index: number): void {
  const fileExtension = getFormatFile(file);
  const is3DFile = available3DExtensions.includes(fileExtension);
  const isImageFile = availableImageExtensions.includes(fileExtension);

  if (!is3DFile && !isImageFile) {
    wrongExtensionIndexes.value.push(index);
    emits('setHasValidationErrors', true);
  }

  const maxSize = is3DFile ? 100 : 10;

  if (Number(getFileSizeMB(file)) > maxSize) {
    bigSizeIndexes.value.push(index);
    emits('setHasValidationErrors', true);
  }
}

function fileHasError(index: number): boolean {
  return bigSizeIndexes.value.includes(index) || wrongExtensionIndexes.value.includes(index);
}

function getFileError(index: number): string | void {
  if (bigSizeIndexes.value.includes(index)) {
    return t('error.size');
  }

  if (wrongExtensionIndexes.value.includes(index)) {
    return t('error.extension');
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheAttachingFiles',
};
</script>

<style lang="scss" scoped>
.file {
  display: grid;
  grid-template-columns: 36px auto 111px;
  gap: 22px;
  margin: 0 17px 36px 17px;

  .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 5px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 277px;
      height: 22px;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #848484;
    }

    &.secundary-text {
      p {
        color: #c0c0c0;
      }

      span {
        color: #c0c0c0;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #6fec61;

      &.error-text {
        color: #e53222;
      }
    }

    img {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.add-file-button {
  img {
    margin-right: 2px;
  }

  span {
    margin-left: 7px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
  }
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 20px;
  max-width: 435px;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #848484;
    margin-bottom: 2px;
    margin-top: 2px;

    &.error-message {
      color: #e53222;
    }
  }
}

h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #141732;
}
</style>
