<template>
  <div class="object-actions-wrapper">
    <button
      class="toggle-button"
      :class="[isShowDropdown && 'active']"
      @click="isShowDropdown = !isShowDropdown"
    >
      <img :src="isShowDropdown ? CloseIcon : DownloadIcon" alt="" />
    </button>
    <ul v-if="isShowDropdown" class="actions">
      <template v-for="action in actions" :key="action.title">
        <li
          v-if="action.title === 'download-pdf'"
          class="action"
          :class="object.pdf ? 'active' : 'disabled'"
          @click="object.pdf && callActionMethod(action)"
        >
          {{ t(action.title) }}
        </li>
        <li
          v-else-if="action.title === 'download-3d-model'"
          class="action"
          :class="object.model ? 'active' : 'disabled'"
          @click="object.model && callActionMethod(action)"
        >
          {{ t(action.title) }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import CloseIcon from '@/assets/icons/close.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import locales from '@/components/organisms/TheObjectActions/locales.json';
import { actionT, actions } from '@/config/object-actions';
import { IObject } from '@/models/Object';
import { defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  object: IObject;
}>();
const { t } = useI18n({
  messages: locales,
});
const isShowDropdown = ref<boolean>(false);
const isShowShareModal = ref<boolean>(false);

function callActionMethod(action: actionT) {
  action.method(props.object, isShowShareModal);
}
</script>

<script lang="ts">
export default {
  name: 'TheSharedObjectActions',
};
</script>

<style scoped lang="scss">
.object-actions-wrapper {
  position: relative;

  .toggle-button {
    width: 40px;
    height: 40px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: #1d2c6f;
    }
  }

  .actions {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.05));
    z-index: 99;

    .action {
      padding: 13px 16px;
      white-space: nowrap;

      &.active {
        cursor: pointer;
        color: #161e57;
      }

      &.disabled {
        color: #848484;
      }
    }

    & > .action:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }
}
</style>
