<template>
  <TheLoader v-if="isLoading" />
  <CabinetLayout v-else-if="!isLoading && !isLinkExpired">
    <div id="object-page-wrapper">
      <TheHeaderSharedObject :object="currentObject" />
      <div v-if="currentObject.tabs" class="object-page-content">
        <TheObjectTabs />
        <div class="viewer-block ml-[65px]">
          <component :is="currentComponentTab" :key="Math.random()" :tab-data="currentDataTab" />
        </div>
      </div>
    </div>
  </CabinetLayout>
  <AuthLayout v-else-if="!isLoading && isLinkExpired">
    <div id="link-expired-page-wrapper">
      <TheLinkExpiredPage :text="'shared-object'" />
    </div>
  </AuthLayout>
</template>

<script lang="ts" setup>
import AuthLayout from '@/layouts/auth.vue';
import CabinetLayout from '@/layouts/cabinet.vue';
import TheLinkExpiredPage from '@templates/TheLinkExpiredPage/index.vue';
import ObjectService from '@/services/ObjectService';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import TheHeaderSharedObject from '@/components/organisms/TheHeaderSharedObject/index.vue';
import { useStore } from 'vuex';
import { IObject, ITabObject } from '@/models/Object';
import ObjectTabs from '@/config/object-tabs';
import TheLoader from '@molecules/TheLoader/index.vue';
import TheObjectTabs from '@/components/organisms/TheObjectTabs/index.vue';

const route = useRoute();
const store = useStore();
const objectService = new ObjectService();
const isLoading = ref<boolean>();
const isLinkExpired = ref<boolean>(false);

const currentTabName = computed(() => store.state.object.currentTabName);
const currentTabOrder = computed(() => store.state.object.currentTabOrder);
const tabs = computed(() => store.state.object.currentObject?.tabs || []);
const currentObject = computed<IObject>(() => store.state.object.currentObject);
const currentComponentTab = computed(() => {
  if (!currentTabName.value) return null;
  return ObjectTabs.get(currentTabName.value).component;
});
const currentDataTab = computed(() =>
  tabs.value.find((tab: ITabObject) => tab.order === currentTabOrder.value)
);

async function getObject() {
  try {
    isLoading.value = true;
    await objectService.getShared(String(route.params.id));
  } catch (e: any) {
    if (e.response.status === 409 || e.response.status === 404) {
      isLinkExpired.value = true;
    }
  } finally {
    isLoading.value = false;
  }
}

getObject();
</script>

<script lang="ts">
export default {
  name: 'TheSharedObjectPage',
};
</script>

<style scoped lang="scss">
#object-page-wrapper {
  height: calc(100% - 91px);

  .object-page-content {
    height: calc(100% - 86px);
    display: flex;

    position: relative;

    .viewer-block {
      width: 100%;
    }
  }
}

#link-expired-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
