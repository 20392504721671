<template>
  <div id="not-found-wrapper">
    <p id="not-found-code">404</p>
    <p id="not-found-title">{{ t('title') }}</p>
    <span>{{ t('description') }}</span>
    <div id="go-home-button-wrapper">
      <TheButton class="active" @click="$router.push({ name: 'objects' })">
        {{ t('home_button') }}
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import locales from '@/components/templates/TheNotFound/locales.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheNotFound',
};
</script>

<style scoped lang="scss">
#not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 490px;

  #not-found-code {
    font-style: normal;
    font-weight: 800;
    font-size: 120px;
    line-height: 100px;
    font-family: 'Circe Th', sans-serif;
    color: #161e57;
  }

  #not-found-title {
    padding-bottom: 12px;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 59px;
    font-family: 'Circe Th', sans-serif;
    color: #161e57;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #161e57;
  }

  #go-home-button-wrapper {
    width: 342px;
    padding-top: 56px;
  }
}
</style>
