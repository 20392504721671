<template>
  <div id="reset-password-form-wrapper">
    <h3>
      {{ t('title') }}
    </h3>
    <p>
      {{ t('description') }}
    </p>
    <Form
      v-slot="{ errors }"
      :validation-schema="schema"
      class="reset-password-form"
      @submit="onSubmit"
    >
      <TheInput
        :locales="locales"
        name="password"
        :label="t('fields.password.title')"
        type="password"
      />
      <TheInput
        :locales="locales"
        name="newPassword"
        :label="t('fields.newPassword.title')"
        type="password"
      />
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
      >
        {{ t('submit-button') }}
      </TheButton>
    </Form>
  </div>
</template>

<script lang="ts" setup>
import { Form } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import * as Yup from 'yup';
import locales from '@templates/TheResetPasswordForm/locales.json';
import TheInput from '@molecules/TheInput/index.vue';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { ref } from 'vue';
import AuthService from '@/services/AuthService';
import { IResetPasswordRequest } from '@/repositories/AuthRepository.d';

const authService = new AuthService();
const route = useRoute();
const router = useRouter();
const { t } = useI18n({
  messages: locales,
});
const isLoadingForm = ref<boolean>(false);
const schema = Yup.object().shape({
  password: Yup.string()
    .required('fields.password.required')
    .min(6, 'fields.password.min')
    .test('isValidPass', 'fields.password.isValidPass', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      // const hasSymbole = /[!@#%&]/.test(value);
      return hasUpperCase && hasLowerCase && hasNumber;
    }),
  newPassword: Yup.string()
    .required('fields.newPassword.required')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .oneOf([Yup.ref('password'), null], 'fields.newPassword.repeat'),
});

async function onSubmit(values: IResetPasswordRequest) {
  try {
    isLoadingForm.value = true;
    await authService.resetPassword({
      ...values,
      token: String(route.query?.token || ''),
    });
    await router.push({ name: 'objects' });
  } finally {
    isLoadingForm.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheResetPasswordForm',
};
</script>

<style scoped lang="scss">
#reset-password-form-wrapper {
  width: 411px;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
  }

  .reset-password-form {
    .input-wrapper {
      margin-bottom: 51px;
    }
  }
}
</style>
