<template>
  <CabinetLayout>
    <router-view />
  </CabinetLayout>
</template>

<script setup lang="ts">
import CabinetLayout from '@/layouts/cabinet.vue';
</script>

<script lang="ts">
export default {
    name: 'TheCabinetPage'
};
</script>

<style scoped>

</style>
