// This module was created due to one unsolvable problem
type stateT = {
  // ...
};
const getState = (): stateT => ({});

export default {
  namespaced: true,
  state: getState(),
  actions: {},
  mutations: {
    clearState(state: stateT) {
      Object.assign(state, getState());
    },
  },
  getters: {},
};
