<template>
  <div class="viewer-wrapper h-full">
    <div ref="viewerBody" class="w-full h-full">
      <template v-if="isShowCanvas">
        <div class="action-bar">
          <TheButton
            v-for="action in actionButtons"
            :key="action.icon"
            type="secondary"
            :class="[!!state[action.type] && 'active']"
            @click="actions[action.methodName]()"
          >
            <component :is="action.icon" />
          </TheButton>
        </div>
        <div class="canvas-wrapper" :class="cursorClass">
          <v-stage
            ref="stage"
            name="Stage"
            :config="configStage"
            @dragstart="handleDragStart"
            @dragmove="handleDragMove"
            @dragend="handleDragEnd"
            @click="handleSelectPolygon"
            @mousemove="handleMousemove"
          >
            <v-layer>
              <v-image :config="configImage" />
              <template v-for="element in elements" :key="element.id">
                <v-group
                  v-for="polygon in element.polygons"
                  :id="polygon.id"
                  :key="polygon"
                  :tab-element-id="polygon.tabElementId"
                >
                  <v-line
                    v-if="polygon.type === 'custom'"
                    :config="{
                      ...defaultPolygon,
                      points: getLineInPixels(polygon.data.points),
                      opacity: 0,
                    }"
                  />
                  <v-line
                    v-if="polygon.type === 'pipe'"
                    :config="{
                      ...defaultPipeConfig,
                      points: getLineInPixels(polygon.data.points),
                      strokeWidth: strokeWidth * polygon.data.width,
                      stroke: element.color,
                      opacity: 0,
                    }"
                  />
                  <v-rect
                    v-if="polygon.type === 'rectangle'"
                    :config="{
                      ...defaultShapeConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      width: (configImage.width / 100) * (polygon.data.width * 100),
                      height: (configImage.height / 100) * (polygon.data.height * 100),
                      opacity: 0,
                    }"
                  />
                  <v-ellipse
                    v-if="polygon.type === 'ellipse'"
                    :config="{
                      ...defaultShapeConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      radiusX: (configImage.width / 100) * (polygon.data.radiusX * 100),
                      radiusY: (configImage.height / 100) * (polygon.data.radiusY * 100),
                      opacity: 0,
                    }"
                  />

                  <v-line
                    v-if="polygon.type === 'custom' && polygon.selected"
                    :config="{
                      ...defaultPolygon,
                      points: getLineInPixels(polygon.data.points),
                      stroke: element.color,
                      fill: element.color,
                      opacity: 0.6,
                    }"
                  />
                  <v-line
                    v-if="
                      polygon.type === 'custom' &&
                      (polygon.selected || polygon.hovered)
                    "
                    :config="{
                      ...selectedLineConfig,
                      points: getLineInPixels(polygon.data.points),
                      fill: element.color,
                      stroke: element.color,
                    }"
                  />

                  <v-line
                    v-if="
                      polygon.type === 'pipe' &&
                      (polygon.selected || polygon.hovered)
                    "
                    :config="{
                      ...defaultPipeConfig,
                      points: getLineInPixels(polygon.data.points),
                      stroke: element.color,
                      strokeWidth: strokeWidth * polygon.data.width,
                    }"
                  />

                  <v-rect
                    v-if="polygon.type === 'rectangle' && polygon.hovered"
                    :config="{
                      ...selectedLineConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      width: (configImage.width / 100) * (polygon.data.width * 100),
                      height: (configImage.height / 100) * (polygon.data.height * 100),
                      stroke: element.color,
                      opacity: 0.6,
                    }"
                  />
                  <v-rect
                    v-if="polygon.type === 'rectangle' && polygon.selected"
                    :config="{
                      ...defaultShapeConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      width: (configImage.width / 100) * (polygon.data.width * 100),
                      height: (configImage.height / 100) * (polygon.data.height * 100),
                      fill: element.color,
                      stroke: element.color,
                      opacity: 0.6,
                    }"
                  />

                  <v-ellipse
                    v-if="polygon.type === 'ellipse' && polygon.hovered"
                    :config="{
                      ...selectedLineConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      radiusX: (configImage.width / 100) * (polygon.data.radiusX * 100),
                      radiusY: (configImage.height / 100) * (polygon.data.radiusY * 100),
                      stroke: element.color,
                      opacity: 0.6,
                    }"
                  />
                  <v-ellipse
                    v-if="polygon.type === 'ellipse' && polygon.selected"
                    :config="{
                      ...defaultShapeConfig,
                      x: getXInPixels(polygon.data.x),
                      y: getYInPixels(polygon.data.y),
                      radiusX: (configImage.width / 100) * (polygon.data.radiusX * 100),
                      radiusY: (configImage.height / 100) * (polygon.data.radiusY * 100),
                      fill: element.color,
                      stroke: element.color,
                      opacity: 0.6,
                    }"
                  />
                </v-group>
              </template>
            </v-layer>
          </v-stage>
        </div>
      </template>
    </div>
    <The3DViewerSidebar v-if="selectedElement" :element="selectedElement" />
  </div>
</template>

<script setup lang="ts">
import TheButton from '@/components/atoms/TheButton/index.vue';
import The3DViewerSidebar from '@/components/organisms/The3DViewerSidebar/index.vue';
import {
  getActionButtons,
  getConfigState,
  getDefaultPolygon,
  getDefaultState,
  getLineConfig,
  getPipeConfig,
  getShapeConfig,
} from '@/config/polygon-viewer';
import {
  IElementObject,
  IPointPolygon,
  IPolygonElement,
} from '@/models/Object';
import {
  defineEmits,
  defineProps,
  nextTick,
  onMounted,
  reactive,
  ref,
} from 'vue';

const props = defineProps<{
  image: string;
  elements: IElementObject[];
}>();
const emits = defineEmits(['select']);

let canvasWidth = 0;
let canvasHeight = 0;
const strokeWidth = 2.5;

const isShowCanvas = ref<boolean>(false);
const cursorClass = ref('');
const actionButtons = reactive(getActionButtons());
const defaultPolygon = reactive(getDefaultPolygon());
const defaultState = reactive(getDefaultState());
const configStage = reactive(getConfigState({ canvasWidth, canvasHeight }));
const selectedLineConfig = reactive(getLineConfig({ strokeWidth }));
const defaultPipeConfig = reactive(
  getPipeConfig({
    lineJoin: 'round',
    lineCap: 'round',
  })
);
const defaultShapeConfig = reactive(
  getShapeConfig({
    strokeWidth,
  })
);

const state = ref({ ...defaultState, ...{ viewMode: true } });
const stage = ref(null);
const viewerBody = ref(null);
const polygonsState = ref(
  props?.elements?.length
    ? props.elements.flatMap((element) => element.polygons)
    : []
);

const configImage = reactive({
  x: 0,
  y: 0,
  image: null,
  width: 1,
  height: 1,
});
const mediaUrl = import.meta.env.VITE_MEDIA_URL;
const selectedElement = ref(null);

onMounted(() => {
  nextTick(() => {
    fitStageInCanvas();
  });
});

const manualImage = new window.Image();
// eslint-disable-next-line vue/no-setup-props-destructure
manualImage.src = props.image.startsWith('http')
  ? props.image
  : mediaUrl + props.image;
manualImage.onload = () => {
  configImage.image = manualImage;
  fitImageInCanvas();
};

const actions = reactive({
  setZoomInMode() {
    if (state.value.zoomInMode) {
      setViewMode();
      return;
    }
    state.value = { ...defaultState, ...{ zoomInMode: true } };
    configStage.draggable = false;
    cursorClass.value = 'zoom-in-mode-cursor';
  },
  setZoomOutMode() {
    if (state.value.zoomOutMode) {
      setViewMode();
      return;
    }
    state.value = { ...defaultState, ...{ zoomOutMode: true } };
    cursorClass.value = 'zoom-out-mode-cursor';
    configStage.draggable = false;
  },
  setHandMode() {
    if (state.value.handMode) {
      setViewMode();
      return;
    }
    state.value = { ...defaultState, ...{ handMode: true } };
    cursorClass.value = 'hand-mode-cursor';
    configStage.draggable = true;
  },
});

const setViewMode = () => {
  state.value = { ...defaultState, ...{ viewMode: true } };
  configStage.draggable = false;
  cursorClass.value = '';
};

const handleSelectPolygon = (e) => {
  if (state.value.zoomInMode || state.value.zoomOutMode) {
    scaleStage();
    return;
  }

  const tabElementId = e.target?.parent?.attrs?.['tab-element-id'];

  if (tabElementId) {
    polygonsState.value.forEach((polygon) => {
      polygon.selected = polygon.tabElementId === tabElementId;
    });
    emits('select', tabElementId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedElement.value =
      props.elements.find((element: IElementObject) => {
        return element.id === tabElementId;
      }) || null;

    isShowCanvas.value = false;
    nextTick(() => fitStageInCanvas());
  } else {
    polygonsState.value.forEach((polygon) => {
      polygon.selected = false;
    });
    selectedElement.value = null;
    if (isShowCanvas.value) {
      isShowCanvas.value = false;
      nextTick(() => fitStageInCanvas());
    }
  }
};

const handleMousemove = (e) => {
  const tabElementId = e.target?.parent?.attrs?.['tab-element-id'];

  polygonsState.value.forEach((polygon) => {
    polygon.hovered = polygon.tabElementId === tabElementId;
  });
};

const handleDragStart = () => {
  if (state.value.handMode) {
    cursorClass.value = 'hand-active-mode-cursor';
  }
};

const handleDragMove = (e) => {
  const targetAttrs = e.target.attrs;

  if (state.value.handMode && targetAttrs.name === 'Stage') {
    const { x: newX, y: newY } = targetAttrs;

    const stageContainer = stage.value.getNode();
    const liveImageData = stageContainer
      .getChildren()[0] // layer node
      .getChildren()[0] // image node
      .getClientRect();

    const isUltraWidth = liveImageData.width > configStage.width;
    const isUltraHeight = liveImageData.height > configStage.height;

    const moveStageCoordinates = {
      x: configStage.position.x, // not move by default
      y: configStage.position.y, // not move by default
    };

    if (isUltraWidth) {
      moveStageCoordinates.x = moveStageByWidth(liveImageData) ?? newX;
    }
    if (isUltraHeight) {
      moveStageCoordinates.y = moveStageByHeight(liveImageData) ?? newY;
    }

    stageContainer.position(moveStageCoordinates);
  }
};

const handleDragEnd = (e) => {
  configStage.position.x = e.target.attrs.x;
  configStage.position.y = e.target.attrs.y;

  if (state.value.handMode) {
    cursorClass.value = 'hand-mode-cursor';
  }
};

const scaleStage = () => {
  const scale = 0.25;
  const oldScale = configStage.scaleX;
  const center = {
    x: configStage.width / 2,
    y: configStage.height / 2,
  };
  const relatedTo = {
    x: (center.x - configStage.position.x) / oldScale,
    y: (center.y - configStage.position.y) / oldScale,
  };
  let newScale = null;

  if (state.value.zoomInMode) {
    if (oldScale < 2) {
      newScale = oldScale + scale;
    }
  } else {
    if (oldScale > 0.5) {
      newScale = oldScale - scale;
    }
  }

  if (newScale) {
    configStage.scaleX = newScale;
    configStage.scaleY = newScale;

    configStage.position = {
      x: newScale === 1 ? 0 : center.x - relatedTo.x * newScale,
      y: newScale === 1 ? 0 : center.y - relatedTo.y * newScale,
    };
  }
};

const moveStageByWidth = (liveImageData: any) => {
  let xCoordinate = null;
  const isBeyondLeft = liveImageData.x < 0;
  const isBeyondRight =
    liveImageData.x + liveImageData.width > configStage.width;

  const isCrossesLeftBorder = !isBeyondLeft && isBeyondRight;
  const isCrossesRightBorder = isBeyondLeft && !isBeyondRight;

  if (isCrossesLeftBorder) {
    xCoordinate = 0 - configImage.x * configStage.scaleX;
  } else if (isCrossesRightBorder) {
    xCoordinate =
      0 -
      configImage.x * configStage.scaleX -
      liveImageData.width +
      canvasWidth;
  }

  return xCoordinate;
};

const moveStageByHeight = (liveImageData: any) => {
  let yCoordinate = null;

  const isBeyondTop = liveImageData.y < 0;
  const isBeyondBottom =
    liveImageData.y + liveImageData.height > configStage.height;

  const isCrossesTopBorder = !isBeyondTop && isBeyondBottom;
  const isCrossesBottomBorder = isBeyondTop && !isBeyondBottom;

  if (isCrossesTopBorder) {
    yCoordinate = 0 - configImage.y * configStage.scaleX;
  } else if (isCrossesBottomBorder) {
    yCoordinate =
      0 -
      configImage.y * configStage.scaleX -
      liveImageData.height +
      canvasHeight;
  }

  return yCoordinate;
};

const getXInPixels = (x: number) => {
  return (configImage.width / 100) * (x * 100) + configImage.x;
};

const getYInPixels = (y: number) => {
  return (configImage.height / 100) * (y * 100) + configImage.y;
};

const getLineInPixels = (points: IPointPolygon[]) => {
  return points.flatMap((p) => [
    (configImage.width / 100) * (p.x * 100) + configImage.x,
    (configImage.height / 100) * (p.y * 100) + configImage.y,
  ]);
};

const fitImageInCanvas = () => {
  const scale = Math.min(
    configStage.width / manualImage.width,
    configStage.height / manualImage.height
  );

  configImage.width = manualImage.width * scale;
  configImage.height = manualImage.height * scale;
  configImage.x = configStage.width / 2 - configImage.width / 2;
  configImage.y = configStage.height / 2 - configImage.height / 2;
};

const fitStageInCanvas = () => {
  canvasWidth = viewerBody.value.offsetWidth - 10;
  canvasHeight = viewerBody.value.offsetHeight - 100;
  configStage.width = canvasWidth;
  configStage.height = canvasHeight;

  fitImageInCanvas();

  isShowCanvas.value = true;
};
</script>

<script lang="ts">
export default {
  name: 'The3DViewer',
};
</script>

<style scoped lang="scss">
.viewer-wrapper {
  //margin: -20px 0;
  display: flex;

  .canvas-wrapper {
    width: 100%;

    &.zoom-in-mode-cursor {
      cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDE1TDE5IDE5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTAuNSIgcj0iNi4yNSIgZmlsbD0iIzE2MUU1NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPHBhdGggZD0iTTggMTAuNUgxMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPHBhdGggZD0iTTEwLjUgOEwxMC41IDEzIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8cGF0aCBkPSJNMTQgMTRMMTkgMTkiIHN0cm9rZT0iIzE2MUU1NyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9zdmc+Cg=='),
        zoom-in;
    }

    &.zoom-out-mode-cursor {
      cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDE1TDE5IDE5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTAuNSIgcj0iNi4yNSIgZmlsbD0iIzE2MUU1NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPHBhdGggZD0iTTggMTAuNUgxMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPHBhdGggZD0iTTE0IDE0TDE5IDE5IiBzdHJva2U9IiMxNjFFNTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo='),
        zoom-out;
    }

    &.hand-mode-cursor {
      cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjAwMDEgNy41SDE1LjUwMDFWN0MxNS41MDAxIDYuNDQ3NzIgMTUuMDUyNCA2IDE0LjUwMDEgNkgxMi45OTk5QzEyLjk5OTkgNS40NDc3MiAxMi41NTIyIDUgMTEuOTk5OSA1SDExLjQ5OTlDMTAuOTQ3NyA1IDEwLjUgNS40NDc3NCAxMC40OTk5IDZIOC45OTk5M0M4LjQ0NzY1IDYgNy45OTk5MyA2LjQ0NzcyIDcuOTk5OTMgN1YxMi40QzcuOTk5OTMgMTIuNTkyMiA3LjczNjU4IDEyLjY4ODQgNy41OTMwNyAxMi41NjA2QzYuOTY0NDEgMTIuMDAwNCA1Ljk1MDc3IDExLjU0OSA1LjAwMDA3IDEyLjVDMy45OTk5NSAxMy41MDA2IDUuNTk0NDQgMTQuODIwNSA2LjUwMDA3IDE2QzguMjAyNjcgMTguMjE3NSA5LjQwMDA3IDE5IDEzLjAwMDEgMTlDMTYuNjAwMSAxOSAxNy44MzM0IDE1LjY2NjcgMTguMDAwMSAxNFY4LjVDMTguMDAwMSA3Ljk0NzcyIDE3LjU1MjQgNy41IDE3LjAwMDEgNy41WiIgZmlsbD0iIzE2MUU1NyIvPgo8cGF0aCBkPSJNMTUuNTAwMSAxMlY3LjVNMTUuNTAwMSA3LjVIMTcuMDAwMUMxNy41NTI0IDcuNSAxOC4wMDAxIDcuOTQ3NzIgMTguMDAwMSA4LjVWMTRDMTcuODMzNCAxNS42NjY3IDE2LjYwMDEgMTkgMTMuMDAwMSAxOUM5LjQwMDA3IDE5IDguMjAyNjcgMTguMjE3NSA2LjUwMDA3IDE2QzUuNTk0NDQgMTQuODIwNSAzLjk5OTk1IDEzLjUwMDYgNS4wMDAwNyAxMi41QzUuOTUwNzcgMTEuNTQ5IDYuOTY0NDEgMTIuMDAwNCA3LjU5MzA3IDEyLjU2MDZDNy43MzY1OCAxMi42ODg0IDcuOTk5OTMgMTIuNTkyMiA3Ljk5OTkzIDEyLjRWN0M3Ljk5OTkzIDYuNDQ3NzIgOC40NDc2NSA2IDguOTk5OTMgNkgxMC40OTk5TTE1LjUwMDEgNy41VjdDMTUuNTAwMSA2LjQ0NzcyIDE1LjA1MjQgNiAxNC41MDAxIDZIMTIuOTk5OU0xMC40OTk5IDZWMTJNMTAuNDk5OSA2VjZDMTAuNSA1LjQ0Nzc0IDEwLjk0NzcgNSAxMS40OTk5IDVIMTEuOTk5OUMxMi41NTIyIDUgMTIuOTk5OSA1LjQ0NzcyIDEyLjk5OTkgNlY2TTEyLjk5OTkgNlYxMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K'),
        move;
    }

    &.hand-active-mode-cursor {
      cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjAwMDIgOS41SDE1LjUwMDJDMTUuNTAwMiA5LjIyMzg2IDE1LjI3NjMgOSAxNS4wMDAyIDlIMTMuMDAwMUMxMy4wMDAxIDguNzIzODggMTIuNzc2MyA4LjUgMTIuNTAwMiA4LjVIMTEuMDAwMkMxMC43MjQxIDguNSAxMC41MDAyIDguNzIzODMgMTAuNTAwMSA5SDkuMDAwMDZDOC40NDc3OCA5IDguMDAwMDYgOS40NDc3MiA4LjAwMDA2IDEwVjEyLjRDOC4wMDAwNiAxMi41OTIyIDcuNzk2OTIgMTIuNjQ3MSA3LjczMDYxIDEyLjQ2NjdDNy42MTYyNSAxMi4xNTU2IDcuNTI1IDEyIDYuNTAwMjUgMTJDNS4wMDAxOCAxMiA1LjU5NDU3IDE0LjgyMDUgNi41MDAyIDE2QzguMjAyOCAxOC4yMTc1IDkuNDAwMiAxOSAxMy4wMDAyIDE5QzE2LjYwMDIgMTkgMTcuODMzNSAxNS42NjY3IDE4LjAwMDIgMTRWMTAuNUMxOC4wMDAyIDkuOTQ3NzIgMTcuNTUyNSA5LjUgMTcuMDAwMiA5LjVaIiBmaWxsPSIjMTYxRTU3Ii8+CjxwYXRoIGQ9Ik0xNS41MDAyIDEyVjkuNU0xNS41MDAyIDkuNUgxNy4wMDAyQzE3LjU1MjUgOS41IDE4LjAwMDIgOS45NDc3MiAxOC4wMDAyIDEwLjVWMTRDMTcuODMzNSAxNS42NjY3IDE2LjYwMDIgMTkgMTMuMDAwMiAxOUM5LjQwMDIgMTkgOC4yMDI4IDE4LjIxNzUgNi41MDAyIDE2QzUuNTk0NTcgMTQuODIwNSA1LjAwMDE4IDEyIDYuNTAwMjUgMTJDNy41MjUgMTIgNy42MTYyNSAxMi4xNTU2IDcuNzMwNjEgMTIuNDY2N0M3Ljc5NjkyIDEyLjY0NzEgOC4wMDAwNiAxMi41OTIyIDguMDAwMDYgMTIuNFYxMEM4LjAwMDA2IDkuNDQ3NzIgOC40NDc3OCA5IDkuMDAwMDYgOUgxMC41MDAxTTE1LjUwMDIgOS41VjkuNUMxNS41MDAyIDkuMjIzODYgMTUuMjc2MyA5IDE1LjAwMDIgOUgxMy4wMDAxTTEwLjUwMDEgOVYxMk0xMC41MDAxIDlWOUMxMC41MDAyIDguNzIzODMgMTAuNzI0MSA4LjUgMTEuMDAwMiA4LjVIMTIuNTAwMkMxMi43NzYzIDguNSAxMy4wMDAxIDguNzIzODggMTMuMDAwMSA5VjlNMTMuMDAwMSA5VjEyIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo='),
        move;
    }

    & ::v-deep(.konvajs-content) {
      width: 100% !important;
    }
  }

  .action-bar {
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;

    button {
      width: fit-content;
      padding: 10px;

      &.active {
        background-color: #1d2c6f;

        svg {
          fill: #ffffff;
        }
      }

      svg {
        fill: #161e57;
      }
    }

    & > button:not(:last-child) {
      margin-right: 12px;
    }
  }
}
</style>
