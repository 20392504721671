<template>
  <div class="checkbox-wrapper">
    <div class="body">
      <input
        :id="name"
        :class="[errorMessage && 'has-error']"
        :name="name"
        type="checkbox"
        @input="onChange"
        @blur="handleBlur"
      />
      <label :for="name">
        <slot />
      </label>
    </div>
    <!--<p-->
    <!--  v-show="errorMessage || !!inputValue && meta.valid"-->
    <!--  class="help-message"-->
    <!--  :class="[-->
    <!--    !!errorMessage && 'has-error',-->
    <!--    meta.valid && 'success'-->
    <!--  ]"-->
    <!--&gt;-->
    <!--  {{ inputMessage }}-->
    <!--</p>-->
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref, toRef } from 'vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  name: string;
  locales: object;
  successMessage?: string;
}>();

type locales = typeof props.locales;
const { t } = useI18n<locales>({
  messages: props.locales,
});
const status = ref<true | undefined>(undefined);
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialValue: props.value,
});

// const inputMessage = computed(() => {
//     if (errorMessage.value) return t(`${ errorMessage.value }`);
//     if (props.successMessage) return t(props.successMessage);
//     return t('great') + '!';
// });

function onChange() {
  status.value = status.value ? undefined : true;
  handleChange(status.value);
}
</script>

<script lang="ts">
export default {
  name: 'TheCheckbox',
};
</script>

<style scoped lang="scss">
.checkbox-wrapper {
  .body {
    display: grid;
    grid-template-columns: 16px auto;

    input[type='checkbox'] {
      position: relative;
      border: 2px solid #161e57;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 0.6em 0 0;
      outline: 0;
      padding: 0 !important;
      vertical-align: text-top;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
      //opacity: .5;
      border-radius: 3px;
    }

    input[type='checkbox']:hover {
      opacity: 1;
    }

    input[type='checkbox']:checked {
      background-color: #161e57;
      opacity: 1;
    }

    input[type='checkbox']:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: url('../../../assets/icons/check-white-solid.svg') no-repeat
        center;
      z-index: 2;
    }
  }

  input {
    margin-top: 6px;

    &.has-error {
      border-color: #ea5058 !important;
    }
  }

  label {
    margin-left: 14px;
  }

  &::v-deep(a) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8091dc;
  }

  .help-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0 16px;

    &.success {
      color: #21a67a;
    }

    &.has-error {
      color: #ea5058;
    }
  }
}
</style>
