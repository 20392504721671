import createObject from '@/pages/requests/create-object/index.vue';
import editObject from '@/pages/requests/edit-object/index.vue';

export default [
  {
    name: 'create-object-request',
    path: 'create-object',
    component: createObject,
    meta: {
      title: 'pages.requests.create-object',
      banner: 'request',
    },
  },
  {
    name: 'edit-object-request',
    path: 'edit-object',
    component: editObject,
    meta: {
      title: 'pages.requests.edit-object',
      banner: 'request',
    },
  },
];
