<template>
  <TheEditorPersonalArea>
    <TheChangeEmailForm />
  </TheEditorPersonalArea>
</template>

<script lang="ts" setup>
import TheEditorPersonalArea from '@/layouts/editor-personal-area.vue';
import TheChangeEmailForm from '@templates/TheChangeEmailForm/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheChangeEmailPage',
};
</script>

<style scoped lang="scss"></style>
