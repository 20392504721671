import {
    IHintByLocationResponse
} from '@/repositories/GeoRepository.d';

export const locationHints:IHintByLocationResponse[] = [
    {
        'id': 1,
        'name': 'Россия',
        'city': {
            'id': 1,
            'name': 'Омск'
        }
    },
    {
        'id': 2,
        'name': 'Россия',
        'city': {
            'id': 12,
            'name': 'Омск 1'
        }
    },
    {
        'id': 3,
        'name': 'Россия',
        'city': {
            'id': 13,
            'name': 'Омск 2'
        }
    },
    {
        'id': 4,
        'name': 'Россия',
        'city': {
            'id': 14,
            'name': 'Омск 3'
        }
    },
    {
        'id': 5,
        'name': 'Россия',
        'city': {
            'id': 15,
            'name': 'Омск 4'
        }
    },
    {
        'id': 6,
        'name': 'Россия',
        'city': {
            'id': 16,
            'name': 'Омск 5'
        }
    },
    {
        'id': 7,
        'name': 'Россия',
        'city': {
            'id': 17,
            'name': 'Омск 6'
        }
    }
];
