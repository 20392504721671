<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.57471"
      y="5.59912"
      width="2.71429"
      height="7.2381"
      transform="rotate(-90 4.57471 5.59912)"
    />
    <rect x="16.7107" y="8.38086" width="2.71429" height="7.2381" />
    <rect
      x="4.57471"
      y="21.1147"
      width="2.71429"
      height="7.2381"
      transform="rotate(-90 4.57471 21.1147)"
    />
    <rect
      x="5.69385"
      y="17.3169"
      width="10.6348"
      height="5"
      transform="rotate(-90 5.69385 17.3169)"
    />
    <rect x="10.6938" y="9.5" width="5.08838" height="5" />
  </svg>
</template>
