import axios, { AxiosInstance } from 'axios';
import AuthTokenService from '@/services/AuthTokenService';
import router from '@/routes';
import store from '@/store';

const authTokenService = new AuthTokenService();
const prefix = import.meta.env.VITE_DOMAIN_PREFIX;
const modeApp = import.meta.env.MODE;
const backendUrl = import.meta.env.VITE_BACKEND_URL;

const instance: AxiosInstance = axios.create({
    ...(
        modeApp !== 'development' && { baseURL: backendUrl }
    ),
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});
instance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
    // If there is no "/api" in the url, insert it automatically
    if (!config.url?.startsWith('/api')) {
        config.url = prefix + config.url;
    }

    // If there is an authorization token
    if (authTokenService.get()) {
        config.headers['Authorization'] = `Bearer ${ authTokenService.get() }`;
    }

    return config;
});

instance.interceptors.response.use((data) => data, (error) => {
    if (error.response.status === 401) {
        authTokenService.remove();
        store.commit('clearState');
        router.push({ name: 'sign-in' }).then(r => r);
    }

    throw error;
});

export default instance;
