<template>
  <div v-if="isSuccessSignUp" id="sign-up-success-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <p>
      {{ t('success.description') }}
    </p>
    <TheButton is-active @click="$router.push({ name: 'sign-in' })">
      {{ t('success.to-sing-in-button') }}
    </TheButton>
    <div id="information-wrapper">
      <span
        id="more-info-toggle"
        @click="isInformationViewable = !isInformationViewable"
      >
        {{ t('success.more-info-toggle') }}
      </span>
      <div id="information" :class="isInformationViewable ? '' : 'invisible'">
        {{
          t('success.information', {
            email: supportEmail,
          })
        }}
      </div>
    </div>
  </div>
  <div v-else class="sign-up-form-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <Form
      v-slot="{ errors }"
      class="sign-up-form"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <TheInput
        :locales="locales"
        :label="t('form.fields.name.label')"
        name="name"
        required
      />
      <TheInput
        :locales="locales"
        :label="t('form.fields.email.label')"
        name="email"
        placeholder="example@email.ru"
        required
      />
      <TheInput
        :locales="locales"
        :label="t('form.fields.phone.label')"
        placeholder="+7 (000) 000 00 00"
        name="phone"
        mask="+7 (###) ### ## ##"
      />
      <TheRegionSelector
        :locales="locales"
        :label="t('form.fields.cityId.label')"
        name="cityId"
      />
      <TheInput
        type="password"
        :locales="locales"
        :label="t('form.fields.password.label')"
        name="password"
        :hint="t('form.fields.password.isValidPass')"
        required
      />
      <TheReCaptcha name="captchaToken" :locales="locales" action="signUp" />
      <TheCheckbox name="agreeWithPolicy" :locales="locales">
        <div
          v-html="
            t('form.fields.agreeWithPolicy.label', {
              link: getPrivacyPolicyFilePath(),
            })
          "
        />
      </TheCheckbox>
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
        @click="isShowFormErrors = true"
      >
        {{ t('form.submit-button') }}
      </TheButton>
      <p
        v-if="
          isShowFormErrors && (Object.keys(errors).length || serverErrorMessage)
        "
        class="form-errors"
      >
        {{ t(serverErrorMessage || 'form.error') }}
      </p>
    </Form>
    <p class="mt-[24px] mb-[24px] text-center">
      {{ t('have-an-account') }}
      <router-link :to="{ name: 'sign-in' }">
        {{ t('log-in') }}
      </router-link>
    </p>
    <div>&nbsp;</div>
    <TheModal v-model:show="userNotConfirmed">
      <template #default="{ close }">
        <TheRetryRegisterMail
          @close="close"
          @sendMail="resendConfirmation"
        />
      </template>
    </TheModal>
  </div>
</template>

<script lang="ts" setup>
import { Form } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';
import locales from '@/components/templates/TheSignUpForm/locales.json';
import TheInput from '@/components/molecules/TheInput/index.vue';
import { ref } from 'vue';
import AuthService from '@/services/AuthService';
import { ISignUpRequest } from '@/services/AuthService.d';
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheRegionSelector from '@/components/organisms/TheRegionSelector/index.vue';
import TheReCaptcha from '@/components/atoms/TheCaptcha/index.vue';
import NotificationsService from '@/services/NotificationsService';
import TheCheckbox from '@/components/atoms/TheCheckbox/index.vue';
import { isEmail } from '@/config/regex';
import TheModal from "@/components/organisms/TheModal/index.vue";
import TheRetryRegisterMail from "@templates/TheRetryRegisterMail/index.vue";

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;
const { t, locale } = useI18n({
  useScope: 'local',
  messages: locales,
});

const notificationsService = new NotificationsService();
const isShowFormErrors = ref<boolean>(false);
const isSuccessSignUp = ref<boolean>(false);
const isInformationViewable = ref<boolean>(false);

const schema = Yup.object().shape({
  name: Yup.string().required('form.fields.name.required'),
  email: Yup.string()
    .required('form.fields.email.required')
    .matches(isEmail, 'form.fields.email.email'),
  phone: Yup.string()
    .nullable()
    .transform((o, c) => (o === '' ? null : c))
    .min(18, 'form.fields.phone.isValid'),
  cityId: Yup.number(),
  password: Yup.string()
    .required('form.fields.password.required')
    .test('isValidPass', 'form.fields.password.isValidPass', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLength = value.length >= 6;
      return hasUpperCase && hasLowerCase && hasNumber && hasLength;
    }),
  captchaToken: Yup.string().required(),
  agreeWithPolicy: Yup.boolean()
    .required('form.fields.agreeWithPolicy.required')
    .oneOf([true], 'form.fields.agreeWithPolicy.required'),
});

const isLoadingForm = ref<boolean>(false);
const serverErrorMessage = ref<string>('');
let userNotConfirmed = ref<boolean>(false);
const authService = new AuthService();
let email = ref('');

async function resendConfirmation() {
  await authService.resendConfirmEmail(email.value);
  notificationsService.add({
    title: 'Успешно',
    type: 'primary',
    text: t('success.description'),
    autoClose: true,
  });
}

async function onSubmit(values: ISignUpRequest) {
  try {
    isLoadingForm.value = true;
    serverErrorMessage.value = '';
    userNotConfirmed.value = false;
    values.lang = locale.value.toString()
    await authService.signUp(values);
    isSuccessSignUp.value = true;
  } catch (e: any) {
    if (e.response.status === 409) {
      serverErrorMessage.value = 'errors.409';
      notificationsService.add({
        title: 'Упс!',
        type: 'warning',
        text: t('errors.409'),
        autoClose: true,
      });
    } else if (e.response.status === 400) {
      serverErrorMessage.value = 'errors.400';
      userNotConfirmed.value = true;
      email.value = values.email;
    } else {
      serverErrorMessage.value = 'errors.other';
      notificationsService.add({
        title: 'Упс!',
        type: 'warning',
        text: t('errors.other'),
        autoClose: true,
      });
    }
  } finally {
    isLoadingForm.value = false;
  }
}
function getPrivacyPolicyFilePath() {
  switch (locale.value) {
    case 'ru':
      return '/privacy-policy-ru.pdf';
    case 'es':
      return '/privacy-policy-en.pdf';
    case 'en':
      return '/privacy-policy-en.pdf';
    default:
      return '/privacy-policy-en.pdf';
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheSignUpForm',
};
</script>

<style scoped lang="scss">
h2 {
  font-weight: 400;
  font-size: 40px;
  line-height: 51px;
  color: #161e57;
  margin-bottom: 32px;
  font-family: 'Circe Th', sans-serif;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #161e57;

  a {
    cursor: pointer;
    color: #8091dc;
  }
}

.sign-up-form {
  .input-wrapper,
  .region-selector-wrapper {
    margin-bottom: 32px;
  }
  .checkbox-wrapper {
    margin-bottom: 46px;
  }
  .submit-btn {
    background: #87a7d5;
    border-radius: 10px;
    padding: 14px 0;
    width: 100%;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    &.active {
      background: #3b629c;
    }
  }

  .form-errors {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ea5058;
    margin-top: 8px;
  }

  .link {
    cursor: pointer;
    color: #8091dc;
  }
}

.server-errors {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5058;
  padding: 0 16px;
  margin-bottom: 10px;
}

#sign-up-success-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > * {
    max-width: 343px;
  }

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 59px;
    color: #161e57;
    font-family: 'Circe Th', sans-serif;
    padding-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
    padding-bottom: 52px;
  }

  #information-wrapper {
    display: flex;
    flex-direction: column;

    #more-info-toggle {
      margin-top: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      text-align: center;
      color: #8091dc;
      cursor: pointer;
    }

    #information {
      background: #f9f9f9;
      border-radius: 3px;
      padding: 8px;
      margin-top: 24px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #161e57;
    }
  }
}
</style>
