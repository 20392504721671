<template>
  <TheLoader />
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import AuthService from '@/services/AuthService';
import TheLoader from '@molecules/TheLoader/index.vue';

const router = useRouter();
const authService = new AuthService();

setTimeout(async () => {
  await authService.leave();
  await router.push({ name: 'sign-in' });
}, 500);
</script>

<script lang="ts">
export default {
  name: 'LogoutPage',
};
</script>
