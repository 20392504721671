<template>
  <div id="email-confirmation-wrapper">
    <h3>
      {{ t('title') }}
    </h3>
    <p v-if="!isAlreadyConfirmed">
      {{ t('success_text') }}
    </p>
    <p v-else>
      {{ t('already_confirmed_text') }}
    </p>
    <TheButton class="active" @click="$router.push({ name: 'objects' })">
      {{ t('home_button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import locales from '@templates/TheEmailConfirmation/locales.json';
import { useI18n } from 'vue-i18n';

defineProps<{
  isAlreadyConfirmed: boolean;
}>();

const { t } = useI18n({
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheEmailConfirmation',
};
</script>

<style scoped lang="scss">
#email-confirmation-wrapper {
  width: 367px;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 59px;
    color: #161e57;
    margin-bottom: 16px;
  }

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 52px;
    color: #161e57;
  }
}
</style>
