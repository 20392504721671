<template>
  <div
    id="object-cards-wrapper"
    class="container"
    :class="[typeCards.wrapperClass]"
  >
    <h1>
      {{ t('objects') }}
    </h1>
    <TheObjectCard
      v-for="object in objects"
      :key="object.id"
      :data="object"
      :type="typeCards.type"
      @to-object="toObject"
    />
    <div class="add-object-card">
      <img v-if="objects.length > 1" :src="EmptyHousePugImage" alt="" />
      <TheButton
        type="secondary"
        class="add-object-button"
        @click="$router.push({ name: 'create-object-request' })"
      >
        {{ t('add-object-button') }}
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheObjectCard from '@molecules/TheObjectCard/index.vue';
import locales from '@templates/TheObjectCards/locales.json';
import { IShortObject } from '@/models/Object';
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TheButton from '@/components/atoms/TheButton/index.vue';
import EmptyHousePugImage from '@/assets/images/empty-house-pug.svg';
const props = defineProps<{
  objects: IShortObject[];
}>();

const { t } = useI18n({
  messages: locales,
});
const router = useRouter();
const typeCards = computed(() => {
  if (props.objects.length < 2) {
    return {
      type: 'single',
      wrapperClass: 'single-object',
    };
  }
  if (props.objects.length < 4) {
    return {
      type: 'mini',
      wrapperClass: 'two-column-objects',
    };
  }
  return {
    type: 'micro',
    wrapperClass: 'three-column-objects',
  };
});

function toObject(object: IShortObject) {
  router.push({ name: 'object', params: { id: object.id } });
}
</script>

<script lang="ts">
export default {
  name: 'TheObjectCards',
};
</script>

<style scoped lang="scss">
#object-cards-wrapper {
  display: grid;
  padding: 30px 0;
  gap: 20px;

  h1 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
  }

  &.single-object {
    grid-template-columns: 1fr;
  }

  &.two-column-objects {
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    h1 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &.three-column-objects {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 27px;

    h1 {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  .add-object-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 350px;
    }

    button {
      max-width: 343px;
      font-family: 'Circe Rg';
      font-weight: 700;
      border-radius: 12px;
    }
  }
}
</style>
