import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';
import es from '@/locales/es.json';

const i18n = createI18n({
    warnHtmlMessage: false,
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('lang') || 'ru',
    fallbackLocale: 'ru',
    availableLocales: ['en', 'ru', 'es'],
    messages: {
        en,
        ru,
        es
    }
});

export default i18n;
