<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.25 20.8336V11.6546L3 7.98793V17.167L11.25 20.8336Z" />
    <path
      d="M3.92311 6.75672L12 3.16699L20.0769 6.75671L12 10.3464L3.92311 6.75672Z"
    />
    <path d="M21 7.98791L12.75 11.6546V20.8337L21 17.167V7.98791Z" />
  </svg>
</template>
