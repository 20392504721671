import TouchIcon from '@/components/atoms/Icons/TouchIcon.vue';
import ZoomMinusIcon from '@/components/atoms/Icons/ZoomMinusIcon.vue';
import ZoomPlusIcon from '@/components/atoms/Icons/ZoomPlusIcon.vue';
import {
  IActionButton,
  IConfigState,
  IDefaultPolygon,
  IDefaultState,
  ILineConfig,
  IPipeConfig,
  IShapeConfig,
} from '@/models/polygon';

type ConfigStateParamsT = {
  canvasWidth: number | string;
  canvasHeight: number | string;
};

type LineConfigParamsT = {
  strokeWidth: number;
};

type PipeConfigParamsT = {
  lineJoin: string;
  lineCap: string;
};

type ShapeConfigParamsT = {
  strokeWidth: number;
};

type DefaultPolygonParamsT = {
  mainColor: string;
};

export const getConfigState = (params: ConfigStateParamsT): IConfigState => ({
  x: 0,
  y: 0,
  width: params.canvasWidth,
  height: params.canvasHeight,
  drawBorder: true,
  draggable: false,
  scaleX: 1,
  scaleY: 1,
  position: {
    x: 0,
    y: 0,
  },
});

export const getLineConfig = (params: LineConfigParamsT): ILineConfig => ({
  strokeWidth: params.strokeWidth,
});

export const getPipeConfig = (params: PipeConfigParamsT): IPipeConfig => ({
  lineJoin: params.lineJoin,
  lineCap: params.lineCap,
});

export const getShapeConfig = (params: ShapeConfigParamsT): IShapeConfig => ({
  strokeWidth: params.strokeWidth,
});

export const getDefaultPolygon = (): IDefaultPolygon => ({
  closed: true,
  opacity: 1,
});

export const getDefaultState = (): IDefaultState => ({
  viewMode: true,
  zoomInMode: false,
  zoomOutMode: false,
  handMode: false,
});

export const getActionButtons = (): IActionButton[] => [
  {
    icon: ZoomPlusIcon,
    type: 'zoomInMode',
    methodName: 'setZoomInMode',
  },
  {
    icon: ZoomMinusIcon,
    type: 'zoomOutMode',
    methodName: 'setZoomOutMode',
  },
  {
    icon: TouchIcon,
    type: 'handMode',
    methodName: 'setHandMode',
  },
];
