import { IShortObject, IObject, tabNameT } from '@/models/Object';
type stateT = {
  shortList: IShortObject[]
  currentObject: IObject | null
  currentTabOrder: number | null
  currentTabName: tabNameT
}
const getState = ():stateT => ({
    shortList: [],
    currentObject: null,
    currentTabOrder: null,
    currentTabName: null,
});

export default {
    namespaced: true,
    state: getState(),
    actions: {},
    mutations: {
        clearState(state: stateT) {
            Object.assign(state, getState());
        },
        setShortList(state: stateT, list: IShortObject[]) {
            state.shortList = list;
        },
        setCurrentObject(state: stateT, data: IObject) {
            state.currentObject = data;
        },
        setCurrentTabOrder(state: stateT, order: number) {
            state.currentTabOrder = order;
        },
        setCurrentTabName(state: stateT, name: tabNameT) {
            state.currentTabName = name;
        }
    },
    getters: {}
};
