<template>
  <div v-if="!isSuccessForm" class="change-email-form-wrapper">
    <h3>
      {{ t('title') }}
    </h3>
    <p class="mb-[16px]" v-html="t('email-info', { email: currentEmail })" />
    <p class="mb-[32px]">
      {{ t('description') }}
    </p>
    <Form v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit">
      <TheInput
        :label="t('form.fields.email.label')"
        :locales="locales"
        name="email_hash"
        :custom-errors="invalidCurrentPassword ? t(invalidCurrentPassword) : ''"
        @input="invalidCurrentPassword = ''"
      />
      <TheButton
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
        type="submit"
        class="mt-[58px]"
      >
        {{ t('form.submit-button') }}
      </TheButton>
    </Form>
    <router-link :to="{ name: 'profile-edit' }" class="back-link">
      {{ t('back') }}
    </router-link>
  </div>
  <div v-else class="success-change-email-wrapper">
    <h3 class="mb-[16px]">
      {{ t('title') }}
    </h3>
    <p class="mb-[16px]" v-html="t('email-info', { email: currentEmail })" />
    <p
      class="mb-[40px]"
      v-html="t('success.description', { email: newEmail })"
    />
    <TheButton is-active @click="$router.push({ name: 'profile-edit' })">
      {{ t('success.submit') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import locales from '@/components/templates/TheChangeEmailForm/locales.json';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import TheInput from '@molecules/TheInput/index.vue';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { isEmail } from '@/config/regex';
import UserService from '@/services/UserService';

const store = useStore();
const userService = new UserService();
const emits = defineEmits(['submit']);
const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});

const currentEmail = computed<string>(
  () => store.state.user.personalData?.email || ''
);

const isLoadingForm = ref<boolean>(false);
const newEmail = ref<string>('');
const isSuccessForm = ref<boolean>(false);

const schema = Yup.object().shape({
  email_hash: Yup.string()
    .required('form.fields.email.required')
    .matches(isEmail, 'form.fields.email.email'),
});

async function onSubmit(values: { email_hash: string }) {
  try {
    isLoadingForm.value = true;

    await userService.editEmail(store.state.user.personalData.id, values.email_hash);

    newEmail.value = values.email_hash;
    isSuccessForm.value = true;
    emits('submit');
  } finally {
    isLoadingForm.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheChangeEmailForm',
};
</script>

<style scoped lang="scss">
.change-email-form-wrapper,
.success-change-email-wrapper {
  width: 343px;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #161e57;
    margin-bottom: 16px;
  }
}

.change-email-form-wrapper {
  .back-link {
    display: block;
    padding-top: 32px;

    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8091dc;
  }
}

.success-change-email-wrapper {
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
  }
}
</style>
