<template>
  <div class="viewer-wrapper h-full">
    <div
      :model="model"
      class="online_3d_viewer h-full"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, } from 'vue';
import * as OV from 'online-3d-viewer';

defineProps<{ model: string }>();

onMounted(() => {
  // tell the engine where to find the libs folder
  OV.SetExternalLibLocation('/libs');
  // init all viewers on the page
  OV.Init3DViewerElements();
});
</script>

<script lang="ts">
export default {
  name: 'The3DViewer',
};
</script>

<style lang="scss" scoped>
</style>
