<template>
  <div
    v-if="!isSuccessSendForm"
    id="edit-object-form-wrapper"
  >
    <h3 class="mb-[16px]">
      {{ t('title') }}
    </h3>
    <p class="mb-[24px] max-w-[478px]">
      {{ t('description') }}
    </p>
    <div class="max-w-[435px]">
      <TheTextarea
        v-model:text="text"
        :label="t('fields.text.title')"
        :limit="limit"
        :name="'edit-object-text'"
      />
    </div>
    <TheAttachingFiles
      v-model:files="files"
      :accepts="availableFileExtensions"
      :label="t('fields.files.title')"
      :limit="limitFilesCount"
      class="max-w-[513px]"
      multiple
      @set-has-validation-errors="setHasValidationErrors"
    />
    <TheButton
      :is-active="isAccessSubmit"
      :loading="isLoading"
      class="max-w-[435px] mt-[24px]"
      @click="onSubmit"
    >
      {{ t('submit.title') }}
    </TheButton>
    <router-link
      :to="{ name: 'object', params: { id: Number(route.query.object) } }"
      class="back-link"
    >
      {{ t('back-link') }}
    </router-link>
  </div>
  <div
    v-else
    id="edit-object-success-wrapper"
  >
    <h3>
      {{ t('success.title') }}
    </h3>
    <p>
      {{ t('success.description') }}
    </p>
    <TheButton
      is-active
      @click="$router.push({ name: 'objects' })"
    >
      {{ t('success.submit-button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import TheAttachingFiles from '@molecules/TheAttachingFiles/index.vue';
import TheTextarea from '@/components/atoms/TheTextarea/index.vue';
import { computed, ref } from 'vue';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { useI18n } from 'vue-i18n';
import locales from '@templates/TheEditObjectForm/locales.json';
import { useRoute } from 'vue-router';
import RequestsService from '@/services/RequestsService';
import { availableFileExtensions } from '@/utils/consts';

const route = useRoute();
const files = ref<File[]>([]);
const text = ref<string>('');
const isLoading = ref<boolean>(false);
const requestsService = new RequestsService();
const isSuccessSendForm = ref<boolean>(false);
const objectId = computed<number>(() => Number(route.query.object));
const limit = 600;
const limitFilesCount = 10;
let hasValidationErrors = ref<boolean>(false);

const setHasValidationErrors = (value: boolean) => {
  hasValidationErrors.value = value;
};

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const isAccessSubmit = computed<boolean>(
  () =>
    !!text.value.length &&
    text.value.length <= limit &&
    limitFilesCount >= files.value.length &&
    !hasValidationErrors.value
);

async function onSubmit() {
  try {
    if (!isAccessSubmit.value) return;
    isLoading.value = true;
    await requestsService.sendUpdateObject({
      type: 'update',
      objectId: Number(route.query.object),
      description: text.value,
      files: files.value.map((file) => ({ file })),
    });
    isSuccessSendForm.value = true;
  } finally {
    isLoading.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheEditObjectForm',
};
</script>

<style lang="scss" scoped>
#edit-object-form-wrapper {
  padding: 30px 100px;

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    font-family: 'Circe Th', sans-serif;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #141732;
  }

  .back-link {
    display: block;
    width: 435px;
    padding-top: 24px;

    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8091dc;
  }
}

#edit-object-success-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    max-width: 379px;
  }

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 16px;
    width: 100%;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
    margin-bottom: 66px;
  }
}
</style>
