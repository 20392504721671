<template>
  <router-view />
</template>

<script lang="ts" setup></script>

<script lang="ts">
export default {
  name: 'TheSharedWrapper',
};
</script>

<style scoped lang="scss"></style>
