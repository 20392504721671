<template>
  <router-view />
</template>

<script lang="ts">
export default {
    name: 'TheProfilePage'
};
</script>

<style scoped lang="scss">

</style>
