import AboutBanner from '@/assets/images/about-banner.png';
import SignInBanner from '@/assets/images/sign-in-banner.png';
import SignUpBanner from '@/assets/images/sign-up-banner.png';
import RequestBanner from '@/assets/images/request-banner.png';
import SupportBanner from '@/assets/images/support-banner.png';
import PasswordRecoveryBanner from '@/assets/images/password-recovery-banner.png';

const list = new Map();

list.set('about-service', AboutBanner);
list.set('sign-in', SignInBanner);
list.set('sign-up', SignUpBanner);
list.set('request', RequestBanner);
list.set('support', SupportBanner);
list.set('password-recovery', PasswordRecoveryBanner);

export default list;
