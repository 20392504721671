import {
  IObjectRepository,
  IGetShareUrlRequest,
  IGetShareUrlResponse,
  IAddNoteRequest,
} from '@/repositories/ObjectRepository.d';
import { IObject, IShortObject } from '@/models/Object';

import axios from '@/axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export default class ObjectRepository implements IObjectRepository {
  async getShortObjects(): Promise<IShortObject[]> {
    const response = await axios.get('/objects');
    return camelcaseKeys(response.data, { deep: true });
  }

  async getSharedObject(token: string): Promise<IObject> {
    const response = await axios.get(`/shared/${token}`);
    return camelcaseKeys(response.data, { deep: true });
  }

  async getObjectData(id: number): Promise<IObject> {
    const response = await axios.get(`/objects/${id}`);
    return camelcaseKeys(response.data, { deep: true });
  }

  async addNote(id: number, data: IAddNoteRequest): Promise<void> {
    await axios.put(`/objects/${id}`, data);
  }

  async getShareUrl(
    id: number,
    data: IGetShareUrlRequest
  ): Promise<IGetShareUrlResponse> {
    const newFormatData = snakecaseKeys(data, { deep: true });
    const response = await axios.post(
      `/objects/${id}/share-url`,
      newFormatData
    );
    return camelcaseKeys(response.data, { deep: true });
  }
}
