<template>
  <template v-if="!isSuccessForm">
    <h2>
      {{ t('title') }}
    </h2>
    <perfect-scrollbar v-if="object" class="max-h-[350px]">
      <TheCheckboxPlanAll
        v-model:checked="isAllTabsSelected"
        :text="t('allTabs')"
        class="checkbox"
      />
      <TheCheckboxPlan
        v-for="(tab, index) in object.tabs"
        :key="tab.id + 'checkbox'"
        v-model:checked="tabsModel[index].checked"
        class="checkbox"
        :data="tab"
      />
    </perfect-scrollbar>
    <TheToggle
      :title="t('toggle.title')"
      :default-value="false"
      class="mt-[10px]"
      @change="(status) => (isMainInfo = status)"
    />
    <TheButton
      class="mt-[18px]"
      :loading="isLoadingForm"
      :is-active="isTabsSelected"
      :disabled="!isTabsSelected"
      @click="submit"
    >
      {{ t('submit.title') }}
    </TheButton>
    <TheButton style-type="secondary" class="mt-[20px]" @click="closeModal">
      {{ t('reject') }}
    </TheButton>
  </template>
  <template v-else>
    <h2 class="!mb-[12px]">
      {{ t('success.title') }}
    </h2>
    <p class="text-center max-w-[390px] mb-[50px]">
      {{ getSuccessText() }}
    </p>
    <TheButton is-active class="success-button" @click="closeModal">
      {{ t('success.submit.title') }}
    </TheButton>
    <TheButton
      style-type="secondary"
      class="success-button mt-[20px]"
      @click="copyToClipboard"
    >
      {{ t('success.repeat-copy.title') }}
    </TheButton>
  </template>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import locales from '@templates/TheSharingObjectForm/locales.json';
import TheToggle from '@molecules/TheToggle/index.vue';
import TheCheckboxPlanAll from '@/components/atoms/TheCheckboxPlanAll/index.vue';
import TheCheckboxPlan from '@/components/atoms/TheCheckboxPlan/index.vue';
import TheButton from '@/components/atoms/TheButton/index.vue';
import { IObject } from '@/models/Object';
import ObjectService from '@/services/ObjectService';

type TabModelT = {
  id: number;
  checked: boolean;
};
const props = defineProps<{
  closeModal(): void;
}>();
const objectService = new ObjectService();
const store = useStore();
const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const isMainInfo = ref<boolean>(false);
const shareUrl = ref<string>('');
const isLoadingForm = ref<boolean>(false);
const isAllTabsSelected = ref<boolean>(false);
const isTabsSelected = computed<boolean>(() =>
  tabsModel.value.some((tab) => tab.checked)
);
const isSuccessForm = ref<boolean>(false);
const object = computed<IObject>(() => store.state.object.currentObject);
const tabsModel = ref<TabModelT[]>(
  object.value.tabs.map((tab) => {
    return {
      id: tab.id,
      checked: false,
    };
  })
);

async function copyToClipboard() {
  await navigator.clipboard.writeText(shareUrl.value);
}

function getSuccessText() {
  let result = '';
  result += t('success.copied-tabs', {
    list: tabsModel.value
      .filter(({ checked }) => checked)
      .map(({ id }) => {
        const tab = object.value.tabs.find((tab) => tab.id === id);

        if (typeof tab === 'undefined' || typeof tab.type !== 'string') {
          return '';
        }

        return tab.type === 'custom' ? t(tab.name) : t(tab.type) || '';
      })
      .join(', '),
  });
  if (isMainInfo.value) {
    result += ' ' + t('success.object-characteristics');
  }
  result += ' ' + t('success.work-days');

  return result;
}

async function submit() {
  try {
    isLoadingForm.value = true;
    const response = await objectService.share(object.value.id, {
      mainInfo: isMainInfo.value,
      tabs: tabsModel.value
        .filter((tab) => tab.checked)
        .map((tab) => ({
          id: tab.id,
        })),
    });
    shareUrl.value = response.url;
    await copyToClipboard();
    isSuccessForm.value = true;
  } finally {
    isLoadingForm.value = false;
  }
}

function closeModal() {
  props.closeModal();
}

watch(isAllTabsSelected, (newValue) =>
  tabsModel.value.forEach((tab) => (tab.checked = newValue))
);
</script>

<script lang="ts">
export default {
  name: 'TheSharingObjectForm',
};
</script>

<style scoped lang="scss">
* {
  max-width: 343px;
  margin: 0 auto;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #161e57;
  text-align: center;
  font-family: 'Circe Th', sans-serif;
  margin-bottom: 24px;
  max-width: none;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #161e57;
  font-family: 'Circe Rg', sans-serif;
}

.checkbox {
  margin-bottom: 8px;
}

.success-button {
  font-family: 'Circe Rg', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
</style>
