<template>
  <div class="toggle-wrapper">
    <label class="switch">
      <input
        type="checkbox"
        :disabled="loading || disabled"
      >
      <span
        class="slider"
        :class="[
          loading && '!cursor-progress',
          model && 'active'
        ]"
        @click="onChange"
      />
    </label>
    <span>
      {{ title }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, defineEmits } from 'vue';

const props = defineProps<{
  title: string
  loading?: boolean
  disabled?: boolean
  defaultValue?: boolean
}>();
const emits = defineEmits(['change']);

const model = ref<boolean>(false);

function onChange() {
    if (props.loading) return;
    model.value = !model.value;
    emits('change', model.value);
}

if (props.defaultValue) model.value = true;
</script>

<script lang="ts">
export default {
    name: 'TheToggle'
};
</script>

<style scoped lang="scss">
.toggle-wrapper {
  display: flex;
  align-items: center;

  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #161E57;
    margin-left: 16px;
  }
}

.heading {
  text-align: center;
}

.switch {
  display: block;
  width: 60px;
  position: relative;
  height: 34px;
  min-width: 60px;
  max-width: 60px;
}

input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

//input[type="checkbox"]:checked + .slider {
//  background-color: #2196F3;
//}

//input[type="checkbox"]:checked + .slider:before {
//  transform: translateX(26px);
//}

//input[type="checkbox"]:checked + .slider:before {
//  transform: translateX(26px);
//}

span.slider {
  &.active {
    background-color: #14F046;

    &:before {
      transform: translateX(26px);
    }
  }
}

</style>
