<template>
  <div v-if="!isSuccessForm" id="recovery-password-form-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <p>
      {{ t('description') }}
    </p>
    <Form
      v-slot="{ errors }"
      :validation-schema="schema"
      class="recovery-password-form"
      @submit="onSubmit"
    >
      <TheInput
        :locales="locales"
        name="email_hash"
        :label="t('fields.email.title')"
        :custom-errors="customErrorsByEmail ? t(customErrorsByEmail) : ''"
      />
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
      >
        {{ t('submit-button') }}
      </TheButton>
    </Form>
  </div>
  <div v-else id="recovery-password-success">
    <h2>
      {{ t('title') }}
    </h2>
    <p v-html="t('success.description', { email: currentEmail })" />
    <TheButton
      is-active
      @click="$router.push('sign-in')"
    >
      {{ t('success.submit-button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import locales from '@templates/TheRecoveryPasswordForm/locales.json';
import * as Yup from 'yup';
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheInput from '@molecules/TheInput/index.vue';
import AuthService from '@/services/AuthService';
import { IRecoveryPasswordRequest } from '@/repositories/AuthRepository.d';
import { isEmail } from '@/config/regex';
import { getHash } from '@/encrypt';

const emits = defineEmits(['setSuccess']);

const customErrorsByEmail = ref<string>('');
const isSuccessForm = ref<boolean>(false);
const isLoadingForm = ref<boolean>(false);
const authService = new AuthService();
const { t } = useI18n({
  messages: locales,
});
const currentEmail = ref<string>('');

const schema = Yup.object().shape({
    email_hash: Yup.string()
        .required('fields.email_hash.required')
        .matches(isEmail, 'fields.email_hash.email'),
});

async function onSubmit(values: IRecoveryPasswordRequest) {
    try {
        customErrorsByEmail.value = '';
        isLoadingForm.value = true;
        const email = values.email_hash
        values.email_hash = await getHash(values.email_hash)
        await authService.passwordRecovery(values);
        currentEmail.value = email;
        isSuccessForm.value = true;
        emits('setSuccess', true);
    } catch (e:any) {
        if(e.response.data.message === 'The selected email hash is invalid.') {
            customErrorsByEmail.value = 'fields.email.invalid';
        }
        if(e.response.data.message === 'Too Many Attempts.') {
            customErrorsByEmail.value = 'toManyAttempts';
        }

    } finally {
        isLoadingForm.value = false;
    }
}
</script>

<script lang="ts">
export default {
  name: 'TheRecoveryPasswordForm',
};
</script>

<style scoped lang="scss">
#recovery-password-success,
#recovery-password-form-wrapper {
  h2 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
  }
}

#recovery-password-form-wrapper {
  & > * {
    max-width: 362px;
  }

  h2 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 32px;
  }

  .input-wrapper {
    margin-bottom: 58px;
  }
}

#recovery-password-success {
  & > * {
    max-width: 367px;
  }

  h2 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 40px;
  }
}
</style>
