import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import routes from '@/routes';
import i18n from '@/i18n';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueKonva from 'vue-konva';

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@/assets/styles/index.scss';
import '@/assets/styles/tailwind.css';
import '@/assets/fonts/circe/stylesheet.css';

const app = createApp(App);
app.use(routes);
app.use(store);
app.use(i18n);
app.use(PerfectScrollbar);
app.use(VueKonva);

app.mount('#app');
