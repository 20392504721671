<template>
  <TheLoader v-if="isLoadingObjects" />
  <component
    :is="objectComponent"
    v-else
    :objects="list"
  />
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import TheEmptyObjects from '@templates/TheEmptyObjects/index.vue';
import TheObjectCards from '@templates/TheObjectCards/index.vue';
import ObjectService from '@/services/ObjectService';
import TheLoader from '@molecules/TheLoader/index.vue';

const store = useStore();
const isLoadingObjects = ref<boolean>(true);
const objectService = new ObjectService();
const list = computed(() => store.state.object.shortList);
const objectComponent = computed(() => {
    if (!list.value.length) return TheEmptyObjects;
    return TheObjectCards;
});

async function getObject() {
    await objectService.getObjects();
    isLoadingObjects.value = false;
}

getObject();
</script>

<script lang="ts">
export default {
    name: 'TheObjectsPage'
};
</script>

<style scoped lang="scss">
</style>
