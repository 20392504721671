export const objectTypes = [
    {
        title: 'Офис',
        value: 'office'
    },
    {
        title: 'Частный дом',
        value: 'house'
    },
    {
        title: 'Квартира',
        value: 'flat'
    },
    {
        title: 'Здание',
        value: 'building'
    },
];

export const buildingTypes = [
    {
        title: 'Кирпичное',
        value: 'brick'
    },
    {
        title: 'Панельное',
        value: 'panel'
    },
    {
        title: 'Монолитное',
        value: 'monolithic'
    },
    {
        title: 'Деревянное',
        value: 'wooden'
    },
    {
        title: 'Иное',
        value: 'other'
    },
];
