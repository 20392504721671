<template>
  <div
    class="notification"
    :class="[
      config.class
    ]"
  >
    <!--  Icon  -->
    <img
      class="icon"
      :src="config.icon"
      alt=""
    >

    <!--  Title  -->
    <h3 v-if="data.title">
      {{ data.title }}
    </h3>

    <!--  Close button  -->
    <button
      v-if="isShowCloseButton"
      class="close"
      @click="closeNotification"
    >
      <!--<component :is="closeIcon" />-->
      <img
        :src="closeIcon"
        alt=""
      >
    </button>

    <!--  Description  -->
    <p v-if="data.text">
      {{ data.text }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { INotification } from '@/services/NotificationsService.d';
import types from '@templates/TheNotifications/types';
import closeIcon from '@/assets/icons/times-solid-close.svg';

const props = defineProps<{
  data: INotification
}>();
const store = useStore();

let autoCloseTime = ref<number>(5);
const config = computed(() => types.get(props.data?.type || 'default'));
const isShowCloseButton = computed(() => {
    return !(props.data.close !== undefined && !props.data.close);
});


function closeNotification() {
    store.commit('notifications/deleteNotification', props.data.id);
}

function startTimerAutoClose() {
    autoCloseTime.value--;
    setTimeout(() => {
        if (autoCloseTime.value === 0) closeNotification();
        else startTimerAutoClose();
    }, 1000);
}

onMounted(() => {
    if (props.data.time) autoCloseTime.value = props.data.time;
    if (props.data.autoClose) startTimerAutoClose();
});
</script>

<script lang="ts">
export default {
    name: 'TheNotification'
};
</script>

<style scoped lang="scss">
$primaryColor: #1890ff;
$successColor: #47cb5c;
$dangerColor: #ff4d4f;
$warningColor: #fbaf1b;
$infoColor: #90a3b5;

@mixin iconColor($color) {
  color: $color;
  background: rgba($color, 10%);
}

.notification {
  & > .icon {
    &.primary {
      @include iconColor($primaryColor);
    }
    &.success {
      @include iconColor($successColor);
    }
    &.error {
      @include iconColor($dangerColor);
    }
    &.warning {
      @include iconColor($warningColor);
    }
    &.info {
      @include iconColor($infoColor);
    }
  }
}

.notification {
  position: relative;
  background: white;
  display: grid;
  grid-template-columns: 22px auto 16px;
  column-gap: 12px;
  padding: 12px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 360px;
}

.notification > .close {
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #90a3b5;
  border: none;
  background: white;
  padding: 0;
  cursor: pointer;
  grid-column-start: 3;
  grid-column-end: 4;
  transition: 0.2s;

  img {
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: black;
  }
}

.notification> .icon {
  font-size: 14px;
  line-height: 14px;
  justify-self: center;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.notification > h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(#000, 85%);
  margin: 0;
  grid-column-start: 2;
  grid-column-end: 3;
}

.notification > p {
  grid-column-start: 2;
  grid-column-end: 4;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: rgba(#000, 65%);
  overflow-wrap: anywhere;
}

.notification > .actions {
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: 10px;

  button {
    font-size: 12px;
    line-height: 22px;
    padding: 1px 8px;
  }

  & > button:not(:last-child) {
    margin-right: 6px;
  }
}
</style>
