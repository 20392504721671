<template>
  <TheLoader v-if="isLinkVerificationProcessing" />
  <div v-else id="reset-password-page">
    <component
      :is="showComponent"
      v-bind="{
        ...(isLinkExpired && { text: 'password-recovery' }),
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import LinkVerificationRepository from '@/repositories/LinkVerificationRepository';
import TheLoader from '@molecules/TheLoader/index.vue';
import TheLinkExpiredPage from '@templates/TheLinkExpiredPage/index.vue';
import TheResetPasswordForm from '@templates/TheResetPasswordForm/index.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const linkVerificationRepository = new LinkVerificationRepository();

const isLinkVerificationProcessing = ref<boolean>(true);
const isLinkExpired = ref<boolean>(false);

const showComponent = computed(() => {
  if (isLinkExpired.value) {
    return TheLinkExpiredPage;
  }
  return TheResetPasswordForm;
});

async function verifyLink() {
  try {
    await linkVerificationRepository.checkPasswordRecovery({
      token: String(route.query?.token || ''),
    });
  } catch (e: any) {
    if (e.response.status === 409 || e.response.status === 404) {
      isLinkExpired.value = true;
    }
  } finally {
    isLinkVerificationProcessing.value = false;
  }
}

verifyLink();
</script>

<script lang="ts">
export default {
  name: 'TheResetPasswordPage',
};
</script>

<style scoped lang="scss">
#reset-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
