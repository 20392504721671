import { IGeneralData, IPersonalData } from '@/models/user';

type stateT = {
  generalData: IGeneralData | null;
  personalData: IPersonalData | null;
};
const getState = (): stateT => ({
  generalData: null,
  personalData: null,
});

export default {
  namespaced: true,
  state: getState(),
  actions: {},
  mutations: {
    clearState(state: stateT) {
      Object.assign(state, getState());
    },
    setGeneralData(state: stateT, data: IGeneralData) {
      state.generalData = data;
    },
    setPersonalData(state: stateT, data: IPersonalData) {
      state.personalData = data;
    },
  },
  getters: {},
};
