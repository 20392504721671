import appComponent from '@/App.vue';
import i18n from '@/i18n';
import store from '@/store';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

// Main Pages
import TheNotFoundPage from '@/pages/TheNotFoundPage.vue';
import about from '@/pages/about-service/index.vue';
import auth from '@/pages/auth/index.vue';
import cabinet from '@/pages/cabinet/index.vue';
import emailReset from '@/pages/email-reset/index.vue';
import requests from '@/pages/requests/index.vue';
import shared from '@/pages/shared/index.vue';
import support from '@/pages/support/index.vue';

// Children Route
import authChildren from '@/pages/auth/routes';
import cabinetChildren from '@/pages/cabinet/routes';
import requestsChildren from '@/pages/requests/routes';
import sharedChildren from '@/pages/shared/routes';

// Services
import AuthTokenService from '@/services/AuthTokenService';
import UserService from '@/services/UserService';

const authTokenService = new AuthTokenService();
const userService = new UserService();

const routes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'App',
      path: '/',
      component: appComponent,
      meta: {
        title: 'Main page',
      },
    },
    {
      name: 'Authorization',
      path: '/auth',
      meta: {
        title: 'pages.auth.sign-in',
      },
      component: auth,
      children: authChildren,
      redirect: { name: 'sign-in' },
    },
    {
      name: 'about-service',
      path: '/about-service',
      meta: {
        title: 'pages.about-service.about-service',
        banner: 'about-service',
      },
      component: about,
    },
    {
      name: 'Cabinet',
      path: '/cabinet',
      meta: {
        title: 'pages.cabinet.cabinet.profile',
      },
      component: cabinet,
      children: cabinetChildren,
      redirect: { name: 'profile' },
    },
    {
      name: 'reset-email',
      path: '/reset-email',
      component: emailReset,
      meta: {
        title: 'pages.email-reset.email-reset',
        banner: 'sign-up',
      },
    },
    {
      name: 'Requests',
      path: '/requests',
      meta: {
        title: 'pages.requests.requests',
      },
      component: requests,
      children: requestsChildren,
    },
    {
      name: 'Support',
      path: '/support',
      meta: {
        title: 'pages.support.support',
        banner: 'support',
      },
      component: support,
    },
    {
      name: 'Shared',
      path: '/shared',
      meta: {
        title: 'pages.shared.shared',
      },
      component: shared,
      children: sharedChildren,
    },
    {
      name: 'NotFound',
      path: '/:pathName(.*)',
      component: TheNotFoundPage,
    },
  ],
});

routes.beforeEach(async (to) => {
  const isLogin = authTokenService.get();
  const mainParent = to.matched[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isProfileGeneralData = store.state.user.generalData;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isProfilePersonalData = store.state.user.personalData;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isAuthPage = mainParent.name.indexOf('Authorization') > -1;
  const isAboutPage = mainParent.name.indexOf('about-service') > -1;
  const isResetEmailPage = mainParent.name.indexOf('reset-email') > -1;
  const isSharedPage = mainParent.name.indexOf('Shared') > -1;

  // Get User Profile general data
  if (isLogin && !isProfileGeneralData) {
    await userService.getProfileGeneralData();
  }
  // Get User Profile personal data
  if (isLogin && !isProfilePersonalData) {
    await userService.getProfilePersonalData(store.state.user.generalData.id);
  }

  // Clear User Profile
  if (!isLogin && isProfileGeneralData) {
    await userService.clearProfile();
  }

  // Check in Authorization
  if (!isLogin && !isAuthPage && !isAboutPage && !isSharedPage && !isResetEmailPage) {
    return { name: 'sign-in' };
  }

  // Do not allow authorized users to login pages
  if (isLogin && (isAuthPage || to.name === 'App')) {
    return { name: 'Cabinet' };
  }
});

routes.afterEach((to) => {
  const defaultTitle = 'Unknown page name';
  // const defaultHeader:string = 'default'
  const appSelector = document.querySelector<HTMLElement>('#app');
  // eslint-disable-next-line max-len
  const documentLoaderSelector =
    document.querySelector<HTMLElement>('.loader-container');
  // Use next tick to handle router history correctly
  // see:
  // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    const title = to.meta.title
      ? i18n.global.t(String(to.meta.title))
      : defaultTitle;
    // Set title page
    document.title = `ID Home | ${title}`;

    if (!to.meta.title) {
      console.warn(
        'The title of the page was not set. ' +
          'To set in the configuration: router.meta.title'
      );
    }

    // Off global loader
    documentLoaderSelector && (documentLoaderSelector.style.display = 'none');
    appSelector && (appSelector.style.display = 'block');
  }).then((r) => r);
});

export default routes;
