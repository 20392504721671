<template>
  <div class="header-object-wrapper">
    <img
      class="cursor-pointer mt-[5px]"
      :src="BackArrowIcon"
      @click="$router.push({ name: 'objects' })"
    />
    <div class="other-information">
      <div>
        <h1>
          {{ (object?.city && object?.city?.name + ',') || '' }}
          {{
            [object.street, object.buildingNumber, object.apartmentNumber]
              .filter((x) => x)
              .join(', ')
          }}
        </h1>
        <div class="properties">
          <span v-if="object.floor">
            {{ t('floor', { number: object.floor }) }}
          </span>
          <span v-if="object.area">
            {{ t('area', { number: object.area }) }}
          </span>
          <span v-if="object.objectType">
            {{ t('object_types.' + object.objectType) }}
          </span>
          <span v-if="object.buildingType">
            {{ t('building_types.' + object.buildingType) }}
          </span>
        </div>
      </div>
    </div>
    <TheObjectActions v-if="!hideActions" :object="object" />
  </div>
</template>

<script lang="ts" setup>
import BackArrowIcon from '@/assets/icons/back-arrow.svg';
import locales from '@/components/organisms/TheHeaderObject/locales.json';
import { IObject } from '@/models/Object';
import { useI18n } from 'vue-i18n';
import TheObjectActions from '@/components/organisms/TheObjectActions/index.vue';

defineProps<{
  object: IObject;
  hideActions: boolean;
}>();

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheHeaderObject',
};
</script>

<style scoped lang="scss">
.header-object-wrapper {
  padding: 24px 24px 8px 24px;
  border-bottom: 1px solid #f1f1f1;

  display: flex;
  align-items: flex-start;

  .other-information {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      font-family: 'Circe Th', sans-serif;
      color: #161e57;
    }

    .properties {
      display: flex;
      align-items: center;

      span {
        display: block;
        font-family: 'Circe Rg', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        color: #161e57;
      }

      & > span:not(:last-child) {
        margin-right: 20px;
        position: relative;

        &::after {
          display: block;
          content: '';
          width: 4px;
          height: 4px;
          background: #161e57;
          border-radius: 50%;

          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -12px;
        }
      }
    }
  }
}
</style>
