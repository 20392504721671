<template>
  <component :is="objectComponent" />
</template>

<script lang="ts" setup>
import TheCreateObjectForm from '@templates/TheCreateObjectForm/index.vue';
import TheRequestDenial from '@templates/TheRequestDenial/index.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const objectComponent = computed(() => {
  if (store.state.user.generalData.isBanned) return TheRequestDenial;
  return TheCreateObjectForm;
});
</script>

<script lang="ts">
export default {
  name: 'TheCreateObjectRequestPage',
};
</script>
