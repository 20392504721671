<template>
  <TheLoader v-if="isLoadingObject" />
  <div
    v-else
    id="object-page-wrapper"
  >
    <TheHeaderObject :object="currentObject" />
    <div
      v-if="currentObject.tabs"
      class="object-page-content"
    >
      <TheObjectTabs />
      <div class="viewer-block ml-[65px]">
        <component :is="currentComponentTab" :key="Math.random()" :tab-data="currentDataTab" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ObjectService from '@/services/ObjectService';
import TheObjectTabs from '@/components/organisms/TheObjectTabs/index.vue';
import { computed, ref } from 'vue';
import ObjectTabs from '@/config/object-tabs';
import { IObject, ITabObject } from '@/models/Object';
import TheLoader from '@molecules/TheLoader/index.vue';
import TheHeaderObject from '@/components/organisms/TheHeaderObject/index.vue';

const route = useRoute();
const store = useStore();
const isLoadingObject = ref<boolean>(true);
const objectService = new ObjectService();
const currentTabName = computed(() => store.state.object.currentTabName);
const currentTabOrder = computed(() => store.state.object.currentTabOrder);
const tabs = computed(() => store.state.object.currentObject?.tabs || []);
const currentComponentTab = computed(() => {
      if (!currentTabName.value) return null;
      return ObjectTabs.get(currentTabName.value).component;
  });
const currentDataTab = computed(
    () => tabs.value.find(
        (tab: ITabObject) => tab.order === currentTabOrder.value
    )
);
const currentObject = computed<IObject>(() => store.state.object.currentObject);

async function getObject() {
  await objectService.getObject(Number(route.params.id));
  isLoadingObject.value = false;
}

getObject();
</script>

<script lang="ts">
export default {
  name: 'TheObjectPage',
};
</script>

<style scoped lang="scss">
#object-page-wrapper {
  height: calc(100% - 91px);

  .object-page-content {
    height: calc(100% - 86px);
    display: flex;

    position: relative;

    .viewer-block {
      width: 100%;
    }
  }
}
</style>
