<template>
  <div class="saving-data-form">
    <h3>
      {{ t('title') }}
    </h3>
    <TheButton
      class="mb-[20px] max-w-[343px]"
      is-active
      @click="continueNavigation"
    >
      {{ t('next') }}
    </TheButton>
    <TheButton class="max-w-[343px]" style-type="secondary" @click="closeModal">
      {{ t('reject') }}
    </TheButton>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TheSavingDataForm',
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TheButton from '@/components/atoms/TheButton/index.vue';

const router = useRouter();
const props = defineProps<{
  routeName: string;
  closeModal(): void;
}>();
const emits = defineEmits(['approve', 'reject', 'close', 'not-save-status']);
const { t } = useI18n({
  useScope: 'local',
  messages: {
    ru: {
      title:
        'Ваши данные не сохранятся. ' + 'Вы уверены, что хотите продолжить?',
      next: 'Продолжить',
      reject: 'Отмена',
    },
    en: {
      title:
        'Your data will not be saved. ' + 'Are you sure you want to continue?',
      next: 'Continue',
      reject: 'Cancel',
    },
    es: {
      title:
        'Tus datos no se guardarán. ' +
        '¿Estás seguro de que quieres continuar?',
      next: 'Continúe en',
      reject: 'Anulación',
    },
  },
});

function continueNavigation() {
  emits('not-save-status', true);
  router.push({ name: props.routeName });
}
</script>

<style scoped lang="scss">
.saving-data-form {
  width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #161e57;
    margin-bottom: 50px;
  }
}
</style>
