<template>
  <div id="about-header">
    <div
      class="image-container"
      :style="{ backgroundImage: `url('${bannerLayout}')` }"
    >
      <div class="header-message-container">
        <h3>{{ t('header.title') }}</h3>
        <p>{{ t('header.text') }}</p>
        <button v-if="!isUserAuth" class="header-button" type="button">
          <span>{{ t('header.button') }}</span>
        </button>
      </div>
    </div>
    <div class="look-more">
      <img :src="DoubleDownIcon" />
    </div>
  </div>
  <div class="features-wrapper">
    <div class="wrapper-title">
      <h3>{{ t('features.title') }}</h3>
      <p>{{ t('features.text') }}</p>
    </div>
    <div class="features-container">
      <div
        v-for="(feature, index) in features"
        :key="index"
        class="feature-card"
      >
        <img :src="feature.src" alt="" />
        <p>{{ t('features.' + feature.text) }}</p>
      </div>
    </div>
  </div>
  <div id="plans-container-wrapper" class="content-wrapper">
    <div class="wrapper-title">
      <h3>{{ t('plans.title') }}</h3>
      <p>{{ t('plans.text') }}</p>
    </div>
    <div class="plans-container">
      <div
        v-for="(plan, index) in plans"
        :key="index"
        class="plan-card-wrapper plan-card"
      >
        <img :src="plan.src" alt="" />
        <div class="plan-text">
          <div class="plan-text-icon">
            <component :is="plan.icon" />
          </div>
          <p>{{ t('plans.' + plan.text) }}</p>
        </div>
      </div>
    </div>
    <div id="scroll-stopper"></div>
  </div>
  <div class="content-wrapper">
    <div class="wrapper-title">
      <h3>{{ t('benefits.title') }}</h3>
      <p>{{ t('benefits.text') }}</p>
    </div>
    <div class="benefits-container">
      <div
        v-for="(item, index) in items"
        :key="index"
        :observer="cardsObserver"
        class="benefits-card"
      >
        <div class="benefits-image-container">
          <img class="benefits-image image" :src="item.image" alt="" />
          <img class="benefits-image text" :src="item.text" alt="" />
        </div>
        <div class="benefits-description">
          <h3 class="number">{{ item.number }}</h3>
          <p class="title">{{ t('benefits.' + item.title) }}</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div id="about-footer">
      <span>© ID HOME {{ new Date().getFullYear() }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DoubleDownIcon from '@/assets/icons/double-down.svg';
import OneImage from '@/assets/images/animations/01-image.svg';
import OneText from '@/assets/images/animations/01-text.svg';
import TwoImage from '@/assets/images/animations/02-image.svg';
import TwoText from '@/assets/images/animations/02-text.svg';
import ThreeImage from '@/assets/images/animations/03-image.svg';
import ThreeText from '@/assets/images/animations/03-text.svg';
import FourImage from '@/assets/images/animations/04-image.svg';
import FourText from '@/assets/images/animations/04-text.svg';
import EditImage from '@/assets/images/edit.png';
import ElectricImage from '@/assets/images/electric.png';
import FurnitureImage from '@/assets/images/furniture.png';
import NotesImage from '@/assets/images/notes.png';
import ObjectImage from '@/assets/images/object.png';
import SaveImage from '@/assets/images/save.png';
import ShareImage from '@/assets/images/share.png';
import SizesImage from '@/assets/images/sizes.png';
import TablesImage from '@/assets/images/tables.png';
import locales from '@/components/templates/TheAboutService/locales.json';
import bannersAuth from '@/config/banners-auth';
import objectTabs from '@/config/object-tabs';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const isUserAuth = computed<number | null>(() => store.state.user.generalData?.id);

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const route = useRoute();
const bannerLayout = computed(() => bannersAuth.get(route.meta.banner));
const features = [
  {
    src: EditImage,
    text: 'editText',
  },
  {
    src: NotesImage,
    text: 'notesText',
  },
  {
    src: ShareImage,
    text: 'shareText',
  },
  {
    src: SaveImage,
    text: 'saveText',
  },
];
const plans = [
  {
    text: '3dText',
    icon: objectTabs.get('3d').icon,
    src: ObjectImage,
  },
  {
    text: 'basicText',
    icon: objectTabs.get('basic').icon,
    src: SizesImage,
  },
  {
    text: 'electricText',
    icon: objectTabs.get('electric').icon,
    src: ElectricImage,
  },
  {
    text: 'tableText',
    icon: objectTabs.get('table').icon,
    src: TablesImage,
  },
  {
    text: 'furnitureText',
    icon: objectTabs.get('furniture').icon,
    src: FurnitureImage,
  },
];
const items = [
  {
    number: 1,
    title: 'oneText',
    image: OneImage,
    text: OneText,
  },
  {
    number: 2,
    title: 'twoText',
    image: TwoImage,
    text: TwoText,
  },
  {
    number: 3,
    title: 'threeText',
    image: ThreeImage,
    text: ThreeText,
  },
  {
    number: 4,
    title: 'fourText',
    image: FourImage,
    text: FourText,
  },
];

const cardsObserver = ref();

const handleScroll = (e) => {
  if (!e.deltaY) return;

  e.preventDefault();
  e.stopPropagation();

  const plansWrapper = document.getElementById('plans-container-wrapper');
  const plansWrapperViewData = plansWrapper?.getBoundingClientRect();
  const plansContainer = document.querySelector('.plans-container');

  if (!plansWrapperViewData || !plansContainer) {
    console.log(plansWrapperViewData, plansContainer);
    return;
  }

  const isPlansContainerViewable =
    plansWrapperViewData.top >= 0 &&
    plansWrapperViewData.left >= 0 &&
    plansWrapperViewData.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    plansWrapperViewData.right <=
      (window.innerWidth || document.documentElement.clientWidth);

  const scrollDirection = e.deltaY > 0 ? 1 : -1;

  if (!isPlansContainerViewable) {
    window.scrollTo(0, window.scrollY + e.deltaY);
    return;
  }

  const scrollLeft = Math.round(plansContainer.scrollLeft);
  const maxScrollLeft = Math.round(
    plansContainer.scrollWidth - plansContainer.clientWidth
  );

  if (
    (scrollDirection === -1 && scrollLeft > 0) ||
    (scrollDirection === 1 && scrollLeft < maxScrollLeft)
  ) {
    plansContainer.scrollLeft += e.deltaY;
  } else {
    window.scrollTo(0, window.scrollY + e.deltaY);
  }
};

onMounted(() => {
  cardsObserver.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const description = entry.target.querySelector('.benefits-description');
      const image = entry.target.querySelector('.benefits-image.image');
      const text = entry.target.querySelector('.benefits-image.text');

      if (entry.isIntersecting) {
        description?.classList.add('show');
        image?.classList.add('show');
        text?.classList.add('show');
        return;
      }

      description?.classList.remove('show');
      image?.classList.remove('show');
      text?.classList.remove('show');
    });
  });
  const cards = document.querySelectorAll('.benefits-card');
  cards.forEach((card: Element) => cardsObserver.value.observe(card));

  const page = document.getElementById('about-layout-wrapper');
  page?.addEventListener('mousewheel', handleScroll, false);
});
</script>

<script lang="ts">
export default {
  name: 'TheAboutService',
};
</script>

<style scoped lang="scss">
#about-header {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;

  .image-container {
    flex-grow: 3;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 85%;

    .header-message-container {
      margin-top: auto;
      padding: 64px;
      width: 644px;

      h3 {
        font-family: 'Circe Th', sans-serif;
        font-weight: 400;
        font-size: 48px;
        line-height: 100%;
        color: #ffffff;
        padding: 0;
        text-stroke: 1px #161e57;
        -webkit-text-stroke: 1px #161e57;
      }

      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        padding: 30px 0;
      }

      .header-button {
        padding: 14px 16px;
        background: #f1f1f1;
        border-radius: 3px;

        span {
          line-height: 22px;
          font-size: 16px;
          font-weight: 700;
          color: #1d2c6f;
        }
      }
    }
  }

  .look-more {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.features-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 64px;

  .features-container {
    display: flex;
    justify-content: space-between;

    .feature-card {
      display: flex;
      flex-direction: column;

      width: 300px;
      padding-top: 45px;

      img {
        width: 240px;
        height: 230px;
        justify-self: center;
        align-self: center;
      }
      p {
        margin-top: 45px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #161e57;
      }
    }
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 64px;
}

.plans-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding-bottom: 40px;

  .plan-card-wrapper {
    flex: 0 0 auto;
  }

  .plan-card {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    width: 521px;
    height: 451px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 10px;

    img {
      width: 500px;
      height: 355px;
      justify-self: center;
      align-self: center;
    }

    .plan-text {
      display: flex;
      padding: 20px 0;

      .plan-text-icon {
        padding: 9px;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        background: #1d2c6f;
        fill: #ffffff;
        border-radius: 3px;

        svg {
          width: 22px;
          height: auto;
        }
      }

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #161e57;
        justify-self: center;
        align-self: center;
      }
    }
  }
}

.plans-container::-webkit-scrollbar {
  height: 2px;
}

.plans-container::-webkit-scrollbar-thumb {
  background-color: #14f046;
}

.plans-container::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.benefits-container {
  display: flex;
  flex-direction: column;

  .benefits-card {
    display: flex;
    justify-content: space-evenly;

    .benefits-image-container {
      position: relative;
      .benefits-image {
        opacity: 0;

        &.show {
          opacity: 1.5;
          transform: translateY(0);
        }
      }

      .image {
        transform: translateY(-70%);
        transition: all 1.5s ease;
      }

      .text {
        transform: translateY(-50%);
        position: absolute;
        top: 0;
        right: 0;
        transition: all 1.5s ease 1s;
      }
    }

    .benefits-description {
      opacity: 0;
      transform: translateY(-100%);
      transition: all 1.5s ease;
      justify-self: center;
      align-self: center;
      width: 400px;

      &.show {
        opacity: 1;
        transform: translateY(0);
      }

      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #161e57;
      }

      .title {
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #161e57;
      }
    }
  }
}

.wrapper-title {
  margin-bottom: 40px;
  width: 644px;

  h3 {
    margin-bottom: 10px;

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #161e57;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
  }
}

#about-footer {
  height: 82px;
  background: #f1f1f1;
  text-align: end;
  padding-top: 30px;
  padding-right: 122px;
}
</style>
