<template>
  <label :for="inputId">
    <component :is="objectTabs.get(data.type).icon" />
    <span>
      {{ t(data.name || data.text) }}
    </span>
    <input
      :id="inputId"
      type="checkbox"
      :checked="checked"
      @input="model = !model"
    >
  </label>
</template>

<script lang="ts" setup>
import objectTabs from '@/config/object-tabs';
import { ITabObject } from '@/models/Object';
import { computed } from 'vue';
import locales from "@templates/TheSharingObjectForm/locales.json";
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  data: ITabObject;
  checked: boolean;
}>();
const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const emits = defineEmits(['update:checked']);

const inputId = computed(
  () => `checkbox-${props.data.text || props.data.name}`
);
const model = computed({
  get: () => props.checked,
  set: (newValue) => emits('update:checked', newValue),
});
</script>

<script lang="ts">
export default {
  name: 'TheCheckboxPlan',
};
</script>

<style scoped lang="scss">
label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 10px 16px;

  input[type='checkbox'] {
    position: relative;
    border: 2px solid #161e57;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    //opacity: .5;
    border-radius: 3px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  input[type='checkbox']:hover {
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: #161e57;
    opacity: 1;
  }

  input[type='checkbox']:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: url('../../../assets/icons/check-white-solid.svg') no-repeat
      center;
    z-index: 2;
  }

  svg {
    margin-right: 16px;
    fill: #161e57;
    width: 32px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Circe Rg', sans-serif;
    color: #161e57;
    margin-right: 16px;
    width: -webkit-fill-available;
    display: block;
  }
}
</style>
