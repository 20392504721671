<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3016_49472)">
      <path d="M1.87176 6.99609L11.1249 11.4642C11.3658 11.5791 11.6874 11.6391 12.0057 11.6391C12.324 11.6391 12.6455 11.5791 12.886 11.4642L22.1391 6.99609C22.6257 6.76172 22.6257 6.37828 22.1391 6.14297L12.886 1.67484C12.6455 1.56 12.3235 1.5 12.0057 1.5C11.6879 1.5 11.3658 1.56 11.1249 1.67484L1.87176 6.14297C1.3852 6.37828 1.3852 6.76078 1.87176 6.99609Z" stroke="#161E57" stroke-width="1.5"/>
      <path d="M22.1391 11.5735C22.1391 11.5735 20.4492 10.7588 20.2252 10.6492C20.0011 10.5395 19.9406 10.5456 19.7053 10.6548C19.47 10.764 12.8803 13.9468 12.8803 13.9468C12.6026 14.0668 12.3025 14.1265 12 14.1221C11.6827 14.1221 11.3602 14.0617 11.1197 13.9468C11.1197 13.9468 4.70486 10.8531 4.40392 10.704C4.07814 10.547 3.98439 10.547 3.68767 10.6876L1.85955 11.5679C1.37299 11.8023 1.37299 12.1862 1.85955 12.421L11.1141 16.8892C11.3545 17.004 11.6766 17.064 11.9944 17.064C12.3122 17.064 12.6342 17.004 12.8752 16.8892L22.1283 12.422C22.6256 12.1913 22.6256 11.8088 22.1391 11.5735Z" stroke="#161E57" stroke-width="1.5"/>
      <path d="M22.1392 17.0037C22.1392 17.0037 20.4493 16.189 20.2253 16.0779C20.0012 15.9668 19.9407 15.9743 19.7054 16.0835C19.4701 16.1928 12.886 19.3826 12.886 19.3826C12.6082 19.5023 12.3082 19.5619 12.0057 19.5575C11.6884 19.5575 11.3659 19.4975 11.1249 19.3826C11.1249 19.3826 4.7101 16.2889 4.40963 16.1398C4.08151 15.9809 3.98776 15.9809 3.69291 16.1234L1.86479 17.0037C1.37775 17.2381 1.37775 17.6215 1.86479 17.8568L11.1179 22.325C11.3584 22.445 11.6804 22.4998 11.9982 22.4998C12.316 22.4998 12.6381 22.4398 12.8785 22.325L22.1335 17.8592C22.6257 17.6215 22.6257 17.239 22.1392 17.0037Z" stroke="#161E57" stroke-width="1.5"/>
    </g>
    <defs>
      <clipPath id="clip0_3016_49472">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
