<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4109 6.26562C4.7483 6.26562 3.66497 7.95885 3.66497 9.21026C5.56949 9.21026 6.4109 9.87658 6.4109 12.878H17.5117C17.5117 9.87658 18.4307 9.21026 20.5682 9.21026C20.5682 7.95885 19.4849 6.26562 17.8223 6.26562H6.4109Z"
    />
    <path
      d="M1.71582 9.87658H3.9303C4.83554 9.87658 5.56949 10.6105 5.56949 11.5158V13.6984H18.4307V11.5158C18.4307 10.6105 19.1647 9.87658 20.0699 9.87658H22.2844V17.734H1.71582V9.87658Z"
    />
  </svg>
</template>
