<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8611 4H19C19.5523 4 20 4.44772 20 5V10.3613H16.4444V11.8613H20V19C20 19.5523 19.5523 20 19 20H16.4444H13.7778H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44772 4 5 4H10.3611V13.7778H11.8611V11.8613H13.7778V10.3613H11.8611L11.8611 4ZM10.3611 19.9999V16.4443H11.8611V19.9999H10.3611Z"
    />
  </svg>
</template>
