<template>
  <div
    class="input-wrapper"
    :class="{
      'has-error': !!errorMessage,
      success: meta.valid,
    }"
  >
    <div class="captcha-wrapper">
      <div ref="captcha" hidden/>
      <p class="captcha-links">
        {{ t('protected') }}
        <a href="https://policies.google.com/privacy">{{ t('privacy_policy') }}</a> {{ t('and') }}
        <a href="https://policies.google.com/terms">{{ t('terms_of_service') }}</a>{{ t('apply') }}.
      </p>
    </div>
<!--    <p
      v-show="errorMessage || !!inputValue && meta.valid"
      class="help-message"
    >
      {{ inputMessage }}
    </p>-->
  </div>
</template>

<script lang="ts" setup>
import { load } from 'recaptcha-v3';
import { nextTick, onMounted, ref, defineProps, toRef, computed } from 'vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import locales from './locales.json';

const props = defineProps<{
  action: string;
  name: string;
  successMessage?: string;
  locales: object;
}>();

type locales = typeof props.locales;
const { locale } = useI18n();

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});

let counter = 0;
const captchaToken = import.meta.env.VITE_CAPTCHA_SITE_KEY;
const captcha = ref<Element>();

// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
} = useField(name, undefined, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialValue: props.value,
});

const inputMessage = computed(() => {
  if (errorMessage.value) return t(`${errorMessage.value}`);
  if (props.successMessage) return t(props.successMessage);
  return t('great') + '!';
});

async function checkCaptcha() {
  counter++;
  try {
    const recaptcha = await load(captchaToken, {
      useRecaptchaNet: true,
      useEnterprise: true,
      explicitRenderParameters: {
        container: captcha.value,
        badge: 'inline',
        size: 'invisible',
      },
    });
    const token = await recaptcha.execute(props.action);
    handleChange(token);
  } catch (e) {
    if (counter <= 2) setTimeout(checkCaptcha, 2000);
    handleChange(undefined);
  }
}

onMounted(() => {
  nextTick(async () => {
    await checkCaptcha();
  });
});
</script>

<script lang="ts">
export default {
  name: 'TheReCaptcha',
};
</script>
<style src="../../molecules/TheInput/styles.scss" lang="scss" scoped></style>
<style lang="scss">
.captcha-wrapper {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;

    a {
      cursor: pointer;
      color: #8091dc;
    }
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}
</style>
