import profileEdit from '@/pages/cabinet/profile/edit/index.vue';
import changeEmail from '@/pages/cabinet/profile/change-email/index.vue';
import changePassword from '@/pages/cabinet/profile/change-password/index.vue';

export default [
  {
    name: 'profile-edit',
    path: 'edit',
    component: profileEdit,
  },
  {
    name: 'change-email-cabinet',
    path: 'change-email',
    component: changeEmail,
    meta: {
      title: 'pages.cabinet.cabinet.change-email',
      banner: 'password-recovery',
    },
  },
  {
    name: 'change-password-cabinet',
    path: 'change-password',
    component: changePassword,
    meta: {
      title: 'pages.cabinet.cabinet.change-password',
      banner: 'password-recovery',
    },
  },
];
