<template>
  <div id="not-found-page-wrapper">
    <TheHeader logo-route-name="objects" />
    <img class="banner" src="../assets/images/not-found-banner.png" alt="" />
    <div class="wrapper-page">
      <TheNotFound />
    </div>
  </div>
</template>

<script setup lang="ts">
import TheHeader from '@/components/organisms/TheHeader/index.vue';
import TheNotFound from '@templates/TheNotFound/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheNotFoundPage',
};
</script>

<style scoped lang="scss">
#not-found-page-wrapper {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 90px auto;
  grid-template-areas:
    'header header'
    'banner page';
  height: 100%;

  #header-wrapper {
    grid-area: header;
    border-bottom: 1px solid #f1f1f1;
  }

  .banner {
    display: block;
    grid-area: banner;
    object-fit: cover;
    align-self: stretch;
    overflow: hidden;
  }

  .wrapper-page {
    grid-area: page;
    padding: 25px 0;
  }
}
</style>
