<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9916 2.5083H10.9807V11.5112H9.67032V4.3013H7.6594V14.814H6.37811V9.71821H4.36719V16.3128C4.36719 19.173 7.07964 21.492 10.4251 21.492H13.5748C16.9203 21.492 19.6327 19.173 19.6327 16.3128V6.30588H17.6218V11.5112H16.2848V3.64547H14.2739V11.5112H12.9916V2.5083Z"
    />
  </svg>
</template>
