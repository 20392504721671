<template>
  <div id="link-expired-wrapper">
    <h2>
      {{ t('title') }}
    </h2>
    <p>{{ t(text) }}</p>
    <TheButton is-active @click="$router.push({ name: 'sign-in' })">
      {{ t('button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import locales from '@templates/TheLinkExpiredPage/locales.json';
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps<{
  text: string;
}>();
const { t } = useI18n({
  messages: locales,
});
</script>

<script lang="ts">
export default {
  name: 'TheLinkExpiredPage',
};
</script>

<style scoped lang="scss">
#link-expired-wrapper {
  max-width: 367px;

  h2 {
    margin-bottom: 16px;
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
  }
  p {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
  }
}
</style>
