import { createStore } from 'vuex';

// Modules
import users from '@/store/users';
import user from '@/store/user';
import notifications from '@/store/notifications';
import object from '@/store/object';

const getModules = () => ({
    users,
    user,
    notifications,
    object
});

type stateT = {
  // ...
}
const getState = ():stateT => ({

});

const store = createStore({
    state: getState(),
    actions: {
        clearStore({ commit }):void {
            const modules = getModules();

            commit('clearState');

            Object.keys(modules).forEach((moduleName) => {
                commit(`${ moduleName }/clearState`);
            });
        }
    },
    mutations: {
        clearState(state: stateT):void {
            Object.assign(state, getState());
        },
    },
    modules: getModules()
});

export default store;
