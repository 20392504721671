<template>
  <div id="profile-edit-page-wrapper">
    <div class="container">
      <h1>
        {{ t('pages.cabinet.cabinet.personal-area') }}
      </h1>
      <h6 class="email">
        {{ store.state.user.personalData?.email }}
      </h6>
      <TheToggle
        :loading="isLoadingEmail"
        :title="tLocal('get-notifications')"
        :default-value="settings?.receiveEmailNotifications"
        @change="updateEmailNotifications"
      />
      <div class="actions-button">
        <TheButton
          type="button"
          style-type="secondary"
          @click="$router.push({ name: 'change-email-cabinet' })"
        >
          <template #left-content>
            <img class="mr-[2px]" :src="EmailIcon" alt="" />
          </template>
          {{ tLocal('change-email') }}
        </TheButton>
        <TheButton
          type="button"
          style-type="secondary"
          @click="$router.push({ name: 'change-password-cabinet' })"
        >
          <template #left-content>
            <img class="mr-[2px]" :src="LockIcon" alt="" />
          </template>
          {{ tLocal('change-password') }}
        </TheButton>
      </div>
      <TheEditProfileForm />
      <TheButton
        :loading="isLoadingLeave"
        class="mt-[44px]"
        type="button"
        style-type="danger"
        style="width: fit-content"
        @click="leave"
      >
        {{ tLocal('leave') }}
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import AuthService from '@/services/AuthService';
import TheToggle from '@molecules/TheToggle/index.vue';
import TheEditProfileForm from '@templates/TheEditProfileForm/index.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import EmailIcon from '@/assets/icons/email.svg';
import LockIcon from '@/assets/icons/lock.svg';
import { computed, ref } from 'vue';
import { ISettings } from '@/models/user';
import UserService from '@/services/UserService';
import locales from '@/pages/cabinet/profile/edit/locales.json';

const authService = new AuthService();
const userService = new UserService();
const router = useRouter();
const store = useStore();
const { t } = useI18n({
  useScope: 'global',
});
const { t: tLocal } = useI18n({
  useScope: 'local',
  messages: locales,
});
const isLoadingLeave = ref<boolean>(false);
const isLoadingEmail = ref<boolean>(false);
const settings = computed<ISettings>(
  () => store.state.user.generalData?.settings
);

async function leave() {
  await router.push({ name: 'logout' });
}

async function updateEmailNotifications(value: boolean) {
  try {
    isLoadingEmail.value = true;
    await userService.editSetting({
      receiveEmailNotifications: value,
    });
  } finally {
    isLoadingEmail.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheProfileEditPage',
};
</script>

<style scoped lang="scss">
#profile-edit-page-wrapper {
  padding: 30px 0;

  & > .container > * {
    width: 436px !important;
  }

  h1 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 40px;
  }

  .email {
    font-family: 'Circe Th', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #161e57;
    margin-bottom: 26px;
  }

  .actions-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    margin-bottom: 44px;
  }

  .toggle-wrapper {
    margin-bottom: 30px;
  }
}
</style>
