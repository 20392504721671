export const availableImageExtensions = [
  'png',
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfif',
];

export const available3DExtensions = [
  '3dm',
  '3ds',
  '3mf',
  'amf',
  'bim',
  'brep',
  'dae',
  'fbx',
  'fcstd',
  'FCStd',
  'glb',
  'gltf',
  'ifc',
  'iges',
  'igs',
  'mtl',
  'obj',
  'off',
  'ply',
  'step',
  'stl',
  'stp',
  'wrl',
];
 
export const availableFileExtensions = [...availableImageExtensions, ...available3DExtensions];
