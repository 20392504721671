<template>
  <perfect-scrollbar :class="[notSpace && 'not-space']">
    <TransitionGroup
      name="notification-list"
      tag="div"
    >
      <TheNotification
        v-for="(notification) in notifications"
        :key="notification.id"
        :data="notification"
      />
    </TransitionGroup>
  </perfect-scrollbar>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import TheNotification from '@templates/TheNotifications/TheNotification.vue';

const store = useStore();
const notSpace = ref<boolean>(false);
const notifications = computed(
    () => store.state.notifications['notifications']
);

watch(
    notifications,
    () => {
        if (Object.keys(notifications.value).length) notSpace.value = false;
        else
            setTimeout(() => {
                notSpace.value = true;
            }, 400);
    },
    {
        deep: true,
        immediate: true,
    }
);
</script>

<script lang="ts">
export default {
    name: 'TheNotifications'
};
</script>

<style scoped lang="scss">
.notification-list-enter-active {
  animation: showNotification 0.3s;
}

@keyframes showNotification {
  0% {
    transform: translateX(100%);
  }
  70% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}

.notification-list-leave-active {
  animation: hideNotification 0.3s;
}

@keyframes hideNotification {
  30% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(100%);
  }
}

.not-space {
  & > * {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.fix-notifications > * {
  position: fixed;
  top: 0;
  right: 0;
  height: min-content;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 99;
  padding: 16px 16px 40px 40px;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
}
</style>
