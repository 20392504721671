<template>
  <div class="sidebar-wrapper">
    <h4>
      {{ element.name }}
    </h4>
    <div class="properties">
      <template v-for="[key, value] in Object.entries(element.data)" :key="key">
        <div v-if="value" class="property">
          <h6 v-if="key !== 'notes'">
            {{ t(key) }}
          </h6>
          <img v-if="key === 'image'" :src="value.url" :alt="t(key)" />
          <p
            v-else-if="key !== 'notes'"
            style="white-space: pre-line"
          >
            {{ getCorrectValueProperty({ key, value }) }}
          </p>
        </div>
      </template>
      <div class="property" v-if="!currentObject.shared">
        <h6>{{ t('notes') }}</h6>
        <TheTextarea
          v-model:text="note"
          :name="'edit-object-text'"
          :limit="600"
          height="160"
        />
        <TheButton
          class="add-note-button"
          @click="handleAddNote"
          :disabled="note.length > 600"
        >
          <template v-if="isNoteSaved" #left-content>
            <img class="pt-0 pr-[5px]" :src="SavedIcon" alt="" />
          </template>
          <span>{{ isNoteSaved ? t('saved') : t('save_notes') }}</span>
        </TheButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue';
import { useRoute } from 'vue-router';
import locales from '@/components/organisms/The3DViewerSidebar/locales.json';
import TheButton from '@/components/atoms/TheButton/index.vue';
import ObjectService from '@/services/ObjectService';
import { IElementObject, IObject } from '@/models/Object';
import SavedIcon from '@/assets/icons/saved.svg';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import TheTextarea from "@/components/atoms/TheTextarea/index.vue";

const props = defineProps<{ element: IElementObject }>();

const { t } = useI18n({
  useScope: 'local',
  messages: locales,
});
const route = useRoute();
const store = useStore();
const objectService = new ObjectService();

const elementNote = props.element?.data?.notes ?? '';
const note = ref<string>(elementNote);
const isNoteSaved = ref<boolean>(false);
const currentObject = store.state.object.currentObject;

function getCorrectValueProperty(data: { key: string; value: string }) {
  if (data.key === 'volume') return `${data.value} м³`;
  if (data.key === 'area') return `${data.value} м²`;
  return data.value;
}

const handleAddNote = async () => {
  if (note.value.length <= 600) {
    await objectService
      .addNote(Number(route.params.id), {
        element_id: props.element.id,
        note: note.value,
      })
      .then(() => {
        isNoteSaved.value = true;
        setTimeout(() => (isNoteSaved.value = false), 2000);
      });
  }
};

</script>

<script lang="ts">
export default {
  name: 'The3DViewerSidebar',
};
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  position: absolute;
  right: 0;
  height: 100%;
  background-color: #fff;

  display: block;
  padding: 20px 16px;
  min-width: 350px;
  max-width: 350px;
  border-left: 1px solid #f1f1f1;

  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    font-family: 'Circe Th', sans-serif;
    color: #161e57;
  }

  .properties {
    margin-top: 19px;

    .property {
      margin-bottom: 20px;

      h6 {
        font-family: 'Circe Rg', sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #848484;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #161e57;
      }

      textarea {
        margin-top: 12px;
        padding: 5px;
        width: 100%;
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        color: #161e57;
      }

      textarea:disabled {
        margin-top: 0;
        resize: none;
        border: none;
        background-color: #fff;
      }

      .add-note-button {
        background: #f1f1f1;
        width: auto;
        margin-left: auto;

        img {
          padding-top: 0;
          padding-right: 5px;
        }

        span {
          color: #161e57;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
        }
      }

      img {
        padding-top: 5px;
        width: 100%;
      }
    }
  }
}
</style>
