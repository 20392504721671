<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_57_9001)">
      <path
        d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM10 10H7V12H10V15H12V12H15V10H12V7H10V10Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_57_9001">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
</template>
