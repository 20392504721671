<template>
  <AuthLayout>
    <router-view />
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from '@/layouts/auth.vue';
</script>

<script lang="ts">
export default {
    name: 'TheAuthorizationPage'
};
</script>

<style scoped lang="scss">

</style>
