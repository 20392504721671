<template>
  <Teleport v-if="isShow" to="body">
    <div class="modal-wrapper">
      <slot :close="closeModal" />
    </div>
    <div class="modal-wrapper-background" />
  </Teleport>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  show: boolean;
}>();
const emits = defineEmits(['update:show']);
const isShow = computed({
  get: () => props.show,
  set: (newValue) => emits('update:show', newValue),
});
function closeModal() {
  isShow.value = false;
}
</script>

<script lang="ts">
export default {
  name: 'TheModelWrapper',
};
</script>

<style scoped lang="scss">
.modal-wrapper-background {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  background: rgba(42, 40, 61, 0.5);
  z-index: 99;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 3px;
  padding: 30px;
  z-index: 100;
}
</style>
