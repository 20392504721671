<template>
  <AboutLayout>
    <div id="about-layout-wrapper">
      <TheAboutService />
    </div>
  </AboutLayout>
</template>

<script setup lang="ts">
import AboutLayout from '@/layouts/about.vue';
import TheAboutService from '@templates/TheAboutService/index.vue';
</script>

<script lang="ts">
export default {
  name: 'TheAboutPage',
};
</script>

<style scoped lang="scss">
#about-layout-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
