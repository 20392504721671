<template>
  <div v-if="!isSuccessSendForm" id="create-object-form-wrapper">
    <h3>
      {{ t('title') }}
    </h3>
    <p class="description">
      {{ t('description') }}
    </p>
    <Form
      v-slot="{ errors }"
      class="create-object-form"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="address-block">
        <h4>
          {{ t('groups.address') }}
        </h4>
        <TheRegionSelector
          :locales="locales"
          name="cityId"
          :label="t('fields.cityId.title')"
          required
        />
        <TheInput
          :locales="locales"
          name="street"
          :label="t('fields.street.title')"
          required
        />
        <div class="two-column">
          <TheInput
            :locales="locales"
            name="buildingNumber"
            :label="t('fields.buildingNumber.title')"
            required
          />
          <TheInput
            :locales="locales"
            name="apartmentNumber"
            :label="t('fields.apartmentNumber.title')"
          />
        </div>
      </div>
      <div class="object-block">
        <h4>
          {{ t('groups.object') }}
        </h4>
        <div class="two-column">
          <TheInput
            :locales="locales"
            name="floor"
            type="number"
            :label="t('fields.floor.title')"
          />
          <TheInput
            :locales="locales"
            name="area"
            required
            type="number"
            :label="t('fields.area.title')"
            :suffics="'м²'"
          />
        </div>
        <TheObjectTypeSelector
          :locales="locales"
          name="objectType"
          :label="t('fields.objectType.title')"
        />
        <TheBuildingTypeSelector
          :locales="locales"
          name="buildingType"
          :label="t('fields.buildingType.title')"
        />
      </div>
      <TheButton
        type="submit"
        :loading="isLoadingForm"
        :is-active="!Object.keys(errors).length"
      >
        {{ t('submit.title') }}
      </TheButton>
      <p v-if="Object.keys(errors).length" class="form-errors">
        {{ t('form.error') }}
      </p>
    </Form>
    <router-link :to="{ name: 'objects' }" class="back-link">
      {{ t('backToMainPage') }}
    </router-link>
  </div>
  <div v-else id="create-object-success-wrapper">
    <h3>
      {{ t('success.title') }}
    </h3>
    <p>
      {{ t('success.description') }}
    </p>
    <TheButton is-active @click="$router.push({ name: 'objects' })">
      {{ t('success.submit-button') }}
    </TheButton>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/atoms/TheButton/index.vue';
import TheBuildingTypeSelector from '@/components/organisms/TheBuildingTypeSelector/index.vue';
import TheObjectTypeSelector from '@/components/organisms/TheObjectTypeSelector/index.vue';
import TheRegionSelector from '@/components/organisms/TheRegionSelector/index.vue';
import { ICreateObjectModel } from '@/repositories/RequestsRepository.d';
import RequestsService from '@/services/RequestsService';
import TheInput from '@molecules/TheInput/index.vue';
import locales from '@templates/TheCreateObjectForm/locales.json';
import { Form } from 'vee-validate';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';

const emits = defineEmits(['setShowRedirectStatus']);
const { t } = useI18n({
  messages: locales,
});
const requestsService = new RequestsService();
const isSuccessSendForm = ref<boolean>(false);
const isLoadingForm = ref<boolean>(false);
const schema = Yup.object().shape({
  // type: Yup.string().required('')
  street: Yup.string().required('fields.street.required'),
  buildingNumber: Yup.string().required('fields.buildingNumber.required'),
  cityId: Yup.number().required('fields.cityId.required'),
  floor: Yup.number(),
  apartmentNumber: Yup.string(),
  area: Yup.number().required('fields.area.required'),
  objectType: Yup.string(),
  buildingType: Yup.string(),
});

async function onSubmit(values: ICreateObjectModel) {
  try {
    isLoadingForm.value = true;
    const newValues: any = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined) newValues[key] = value;
    });
    await requestsService.sendCreateObject(newValues);
    isSuccessSendForm.value = true;
    emits('setShowRedirectStatus', false);
  } finally {
    isLoadingForm.value = false;
  }
}
</script>

<script lang="ts">
export default {
  name: 'TheCreateObjectForm',
};
</script>

<style scoped lang="scss">
#create-object-form-wrapper {
  padding: 30px 100px;

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 16px;
  }

  .description {
    width: 478px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161e57;
    margin-bottom: 24px;
  }

  .create-object-form {
    width: 435px;
  }

  .address-block,
  .object-block {
    margin-bottom: 58px;

    h4 {
      font-family: 'Circe Th', sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: #161e57;
      margin-bottom: 16px;
    }

    .region-selector-wrapper,
    .input-wrapper,
    .object-type-selector-wrapper,
    .building-type-selector-wrapper {
      margin-bottom: 30px;
    }
  }

  .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .back-link {
    display: block;
    width: 435px;
    padding-top: 24px;

    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8091dc;
  }
}

#create-object-success-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    max-width: 379px;
  }

  h3 {
    font-family: 'Circe Th', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    color: #161e57;
    margin-bottom: 16px;
    width: 100%;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #161e57;
    margin-bottom: 66px;
  }
}

.form-errors {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ea5058;
  margin-top: 8px;
}
</style>
